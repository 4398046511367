import { marked } from "marked";
import DOMPurify from "dompurify";
import React from "react";

type Props = {
  tag: string;
  background: string;
  text: string;
  style: any;
};

const TaggedText = React.forwardRef(
  ({ tag, text, background, style }: Props, ref: any) => {
    const parsedText = DOMPurify.sanitize(marked(text), { ADD_ATTR: ["target"] });

    return (
      <div
        ref={ref}
        className="d-flex flex-column section position-relative pt-10 mt-3"
        style={{ ...style, backgroundColor: `#${background}` }}
      >
        <div className="tagged-text-tag align-self-center border-gray-300 px-5 py-1 ">
          {tag}
        </div>
        <div className="mt-1" dangerouslySetInnerHTML={{ __html: parsedText }}></div>
      </div>
    );
  }
);

export default TaggedText;
