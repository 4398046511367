import { child, get, onChildAdded, remove, set } from "firebase/database";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDatabase } from "../../db/useDatabase";

type Highlight = {
  timestamp: number;
  x: number;
  y: number;
};

const MAX_AGE = 4500;
export const ENABLED_COMPONENTS = ["artwork.map", "artwork.image"];

export const useHighlighter = () => {
  const [isHighlighterOn, setIsHighlighterOn] = useState<boolean>(false);
  const [isHighlighterEnabled, setIsHighlighterEnabled] =
    useState<boolean>(false);
  const highlighterDb = useDatabase("/highlighter");
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const highlightsRef = useRef<any>();

  highlightsRef.current = highlights;

  const toggleHighlighter = () => {
    setIsHighlighterOn(!isHighlighterOn);
  };

  useEffect(() => {
    if (!isHighlighterEnabled) {
      setIsHighlighterOn(false);
    }
  }, [isHighlighterEnabled]);

  const placeHighlight = useCallback(
    (relPos: { x: number; y: number }) => {
      if (!highlighterDb) {
        return;
      }

      const tsp = Date.now();
      set(child(highlighterDb, tsp.toString()), {
        timestamp: tsp,
        ...relPos,
      });

      get(highlighterDb).then((snap) => {
        const data: Record<string, Highlight> = snap.val();

        if (!data) {
          return;
        }
        Object.values(data).forEach((hl) => {
          if (Date.now() - hl.timestamp > 2 * MAX_AGE) {
            remove(child(highlighterDb, hl.timestamp.toString()));
          }
        });
      });
    },
    [highlighterDb]
  );

  useEffect(() => {
    if (!highlighterDb) {
      return;
    }

    return onChildAdded(highlighterDb, (snap) => {
      const data: Highlight = snap.val();

      if (!data || !highlightsRef.current) {
        return;
      }

      const key = data.timestamp;
      if (Date.now() - data.timestamp < MAX_AGE) {
        setHighlights([...highlightsRef.current, data]);

        setTimeout(() => {
          setHighlights(
            highlightsRef.current.filter((h: Highlight) => h.timestamp !== key)
          );
        }, MAX_AGE);
      }
    });
  }, [highlighterDb]);

  return {
    placeHighlight,
    isHighlighterOn,
    toggleHighlighter,
    isHighlighterEnabled,
    setIsHighlighterOn,
    setHighlights,
    setIsHighlighterEnabled,
    highlights,
  };
};
