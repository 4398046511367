import Konva from "konva";

import { Piece } from "./Piece";
import { Vector2 } from "./Vector2";

export abstract class PieceManager {
  initialPieces!: Record<string, any>;

  pieces!: Record<string, Piece>;

  layer!: Konva.Layer;

  abstract resetPositions(): void;

  abstract cleanUp(): void;

  findDropPosition(key: string, p: Konva.Vector2d): Vector2 {
    return this.generatePosition(
      key,
      new Vector2(p.x, p.y),
      Object.values(this.pieces)
    );
  }

  generatePosition = (key: string, cp: Vector2, pieces: Piece[]): Vector2 => {
    for (let p of pieces) {
      if (key === p.key) {
        continue;
      }
      const pos = p.component.position();
      if (cp.nearlyEquals(new Vector2(pos.x, pos.y), 20)) {
        return this.generatePosition(
          key,
          new Vector2(cp.x + 20, cp.y + 20),
          pieces
        );
      }
    }
    return cp;
  };
}
