import { useCallback, useEffect, useRef, useState } from "react";
import { child, get, onValue, remove, set, update } from "firebase/database";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {
  selectActiveKeys,
  selectModules,
  selectPages,
  selectTocModules,
  setActiveKeys,
  setLiveChapterData,
} from "./courseSlice";
import { useDatabase } from "../../db/useDatabase";
import { selectSessionId, setAvailableModules } from "../../app/appSlice";
import { useConfirmationModal } from "../confirmation-modal/useConfirmationModal";
import { isProduction } from "../../app/stage";
import { cmsApiUrl } from "../../app/configuration";

export enum PageTransition {
  Prev,
  Next,
}

export const usePagination = () => {
  const dispatch = useAppDispatch();

  const tocModules = useAppSelector(selectTocModules);
  const modules = useAppSelector(selectModules);
  const pages = useAppSelector(selectPages);
  const sessionId = useAppSelector(selectSessionId);
  const { moduleKey, chapterKey, pageKey, step } =
    useAppSelector(selectActiveKeys);
  const { confirm } = useConfirmationModal();

  const [pageTransition, setPageTransition] = useState(PageTransition.Next);
  const activeKeysDb = useDatabase("activeKeys");

  const tocModulesRef = useRef<any[]>();
  const pageKeyRef = useRef<any>();
  const chaptersDb = useDatabase("chapters");
  const sessionDb = useDatabase("/");

  pageKeyRef.current = pageKey;
  tocModulesRef.current = tocModules;

  useEffect(() => {
    if (!isProduction()) {
      const handler = (ev: any) => {
        if (ev.ctrlKey && ev.shiftKey && ev.key === "C") {
          window!
            .open(
              `${cmsApiUrl}/admin/content-manager/collectionType/api::page.page?page=1&pageSize=50&sort=key:ASC&plugins[i18n][locale]=en&filters[$and][0][key][$eq]=${pageKeyRef.current}`,
              "_blank"
            )!
            .focus();
        }
      };

      document.addEventListener("keydown", handler);
      return () => {
        document.removeEventListener("keydown", handler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!sessionDb) {
      return;
    }
    get(sessionDb).then((snap) => {
      const val = snap.val();
      if (val?.modules) {
        dispatch(setAvailableModules(val?.modules));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionDb]);

  useEffect(() => {
    if (!chaptersDb || !sessionId) {
      return;
    }

    return onValue(chaptersDb, (snap) => {
      const data = snap.val();
      // If empty value, initialize starting values
      if (data === null) {
        get(sessionDb).then((snap) => {
          const sessionData = snap.val();
          if (sessionData?.sessionNumber) {
            get(chaptersDb).then((snap) => {
              const chaptersData = snap.val();
              if (!chaptersData) {
                if (tocModulesRef.current) {
                  set(
                    chaptersDb,
                    tocModulesRef.current?.reduce((accModules, m, mi) => {
                      accModules = {
                        ...accModules,
                        ...m.attributes.chapters.data.reduce(
                          (accChapters: any, c: any, ci: number) => {
                            accChapters[c.attributes.key] = {
                              // TODO: REMOVE
                              isLocked: false,
                              isCompleted: false,
                            };
                            return accChapters;
                          },
                          {} as Record<string, any>
                        ),
                      };
                      return accModules;
                    }, {} as Record<string, any>)
                  );
                }
              }
            });
          }
        });

        return;
      }
      dispatch(setLiveChapterData(data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chaptersDb, sessionId]);

  useEffect(() => {
    if (!activeKeysDb || !sessionId || !sessionDb) {
      return;
    }
    return onValue(activeKeysDb, (snapshot) => {
      const data = snapshot.val();
      if (data === null) {
        get(sessionDb).then((snap) => {
          const sessionData = snap.val();
          if (sessionData?.sessionNumber) {
            get(activeKeysDb).then((snap) => {
              const activeKeysData = snap.val();
              if (!activeKeysData) {
                if (tocModulesRef.current) {
                  set(activeKeysDb, {
                    moduleKey: tocModulesRef.current[0].attributes.key,
                  });
                } else {
                  dispatch(setActiveKeys(activeKeysData));
                }
              }
            });
          }
        });
      } else {
        dispatch(setActiveKeys(data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeysDb, sessionDb, sessionId]);

  const startChapter = useCallback(
    (moduleKey: string, chapterKey: string) => {
      if (!activeKeysDb || !chaptersDb) {
        return;
      }
      let pageKey;

      pageKey = tocModulesRef.current
        ?.find((m) => m.attributes.key === moduleKey)
        ?.attributes.chapters.data.find(
          (c: any) => c.attributes.key === chapterKey
        )?.attributes.activePage;

      if (!pageKey) {
        pageKey = modules
          ?.find((m) => m.attributes.key === moduleKey)
          ?.attributes.chapters.data.find(
            (c) => c.attributes.key === chapterKey
          )
          ?.attributes.pages.data.slice()
          .sort((a, b) => a.attributes.order - b.attributes.order)[0]
          .attributes.key;
      }
      update(activeKeysDb, {
        moduleKey,
        chapterKey,
        pageKey,
        step: 0,
      });
      update(child(chaptersDb, chapterKey), {
        activePage: pageKey,
      });
    },
    [activeKeysDb, chaptersDb, modules]
  );

  const completeChapter = useCallback(() => {
    if (!chaptersDb || !chapterKey || !tocModules) {
      return;
    }
    // console.log("Someone called complete chapter");
    const modules = tocModules;
    const moduleIndex = modules.findIndex(
      (m) => m.attributes.key === moduleKey
    );
    const module = modules[moduleIndex];
    const chapterIndex = module.attributes.chapters.data.findIndex(
      (c) => c.attributes.key === chapterKey
    );

    if (chapterIndex < 0 || chapterIndex === undefined) {
      return;
    }
    update(child(chaptersDb, chapterKey), {
      isCompleted: true,
      activePage: null,
    });
  }, [chapterKey, chaptersDb, moduleKey, tocModules]);

  const setNextPage = useCallback(() => {
    if (!pages || !activeKeysDb || pageKey === undefined || !chapterKey) {
      return;
    }
    const cpi = pages.findIndex((p) => p.attributes.key === pageKey);
    setPageTransition(PageTransition.Next);
    if (cpi === pages.length - 1) {
      completeChapter();
      update(activeKeysDb, {
        pageKey: null,
        chapterKey: null,
      });
    } else {
      const newPageKey = pages[cpi + 1].attributes.key;
      update(activeKeysDb, {
        pageKey: newPageKey,
        step: 0,
      });
      update(child(chaptersDb, chapterKey), {
        activePage: newPageKey,
      });
    }
  }, [pages, activeKeysDb, pageKey, chapterKey, chaptersDb, completeChapter]);

  const setPrevPage = useCallback(() => {
    if (
      !pages ||
      !activeKeysDb ||
      pageKey === undefined ||
      !chapterKey ||
      !chaptersDb
    ) {
      return;
    }
    const cpi = pages.findIndex((p) => p.attributes.key === pageKey);
    if (cpi > 0) {
      setPageTransition(PageTransition.Prev);
      update(activeKeysDb, {
        pageKey: pages[cpi - 1].attributes.key,
        step: 0,
      });
      update(child(chaptersDb, chapterKey), {
        activePage: pageKey,
      });
    }
  }, [pages, activeKeysDb, pageKey, chapterKey, chaptersDb]);

  const setNextStep = () => {
    if (!pages || !activeKeysDb || pageKey === undefined) {
      return;
    }
    if (step === undefined) {
      return;
    }
    update(activeKeysDb, {
      step: step + 1,
    });
  };

  const setPrevStep = () => {
    if (!pages || !activeKeysDb || pageKey === undefined) {
      return;
    }
    if (step === 0 || step === undefined) {
      return;
    }
    update(activeKeysDb, {
      step: step - 1,
    });
  };

  const setStep = (newStep: number) => {
    if (!pages || !activeKeysDb || pageKey === undefined) {
      return;
    }
    if (newStep < 0 || newStep === undefined) {
      return;
    }
    update(activeKeysDb, {
      step: newStep,
    });
  };

  const backToMenu = () => {
    if (!activeKeysDb) {
      return;
    }
    set(activeKeysDb, {
      moduleKey,
    });
  };

  const resetProgress = () => {
    confirm("Course.ConfirmationModal.Message.ResetProgress").then((val) => {
      if (val) {
        remove(child(sessionDb, "activeKeys"));
        remove(child(sessionDb, "chapters"));
        remove(child(sessionDb, "puzzles"));
        remove(child(sessionDb, "spreadsheets"));
        remove(child(sessionDb, "picpickers"));
        remove(child(sessionDb, "tables"));
      }
    });
  };

  return {
    setPrevPage,
    setNextPage,
    setNextStep,
    setPrevStep,
    setStep,
    startChapter,
    completeChapter,
    backToMenu,
    resetProgress,
    pageTransition,
  };
};
