import Konva from "konva";
import { IFrame } from "konva/lib/types";
import { PuzzleManager } from "./PuzzleManager";
import { DatabaseReference } from "firebase/database";

const ComponentMap: Record<string, any> = {
  rect: Konva.Rect,
  circle: Konva.Circle,
  line: Konva.Line,
  image: Konva.Image,
};

const sharedComponentProps = {
  draggable: false,
  shadowColor: "black",
  shadowOpacity: 0.12,
  shadowOffset: { x: 8, y: 13 },
  shadowEnabled: false,
  strokeWidth: 10,
};

const wiggleAngle = 3;
const wiggleSpeed = 3;

type Props = {
  pieceData: Record<string, any>;
  layer: Konva.Layer;
  activeLayer: Konva.Layer;
  puzzleManager: PuzzleManager;
  database?: DatabaseReference;
  componentProps?: Record<string, any>;
};

export abstract class Piece {
  key: string;

  component: Konva.Rect | Konva.Image;

  layer: Konva.Layer;

  activeLayer: Konva.Layer;

  wiggleAnimation: Konva.Animation;

  wiggleDirection = wiggleSpeed;

  puzzleManager: PuzzleManager;

  data: any;

  constructor({
    pieceData,
    layer,
    activeLayer,
    puzzleManager,
    componentProps,
  }: Props) {
    this.key = pieceData.key;
    this.puzzleManager = puzzleManager;
    this.data = pieceData;

    if (pieceData.component === "image") {
      this.component = new Konva.Image({
        ...sharedComponentProps,
        ...componentProps,
        ...(pieceData as any),
      });
      var img = new Image();
      img.onload = () => {
        (this.component as Konva.Image).image(img);
      };
      img.src = pieceData.imageSrc;
    } else {
      this.component = new ComponentMap[pieceData.component]({
        ...sharedComponentProps,
        ...componentProps,
        ...pieceData,
      });
    }

    this.layer = layer;
    this.activeLayer = activeLayer;

    const pieceContext = this;
    this.component.on("dragstart", this.onDragStart.bind(pieceContext));
    this.component.on("dragmove", this.onDragMove.bind(pieceContext));
    this.component.on("dragend", this.onDragEnd.bind(pieceContext));

    this.component.on("mouseenter", this.onMouseEnter.bind(pieceContext));
    this.component.on("mouseleave", this.onMouseLeave.bind(pieceContext));

    this.wiggleAnimation = new Konva.Animation(
      this.wiggle.bind(pieceContext) as any,
      this.activeLayer
    );
    // this.component.dragBoundFunc(function () {
    //   const pos: Konva.Vector2d = this.getAbsolutePosition();
    //   return { x: pos.x, y: pos.y } as Konva.Vector2d;
    // });
    layer.add(this.component);
  }

  abstract onMouseEnter(): void;

  abstract onMouseLeave(): void;

  abstract onDragStart(e: any): void;

  abstract onDragMove(e: any): void;

  abstract onDragEnd(e: any): void;

  dropPiece() {
    this.component.moveTo(this.layer);
    this.wiggleAnimation.stop();
    this.component.rotation(0);
    this.component.shadowEnabled(false);
  }

  wiggle(frame: IFrame) {
    const r = this.component.rotation();
    if (r > wiggleAngle) {
      this.wiggleDirection = -wiggleSpeed;
    } else if (r < -wiggleAngle) {
      this.wiggleDirection = wiggleSpeed;
    }
    this.component.rotate(this.wiggleDirection * (frame!.timeDiff / 1000));
  }

  abstract remove(): void;
}
