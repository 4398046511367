import { Piece } from "../Piece";
import { Slot } from "../Slot";
import { CheckStrategy } from "./CheckStrategy";

export class IsCorrectStrategy implements CheckStrategy {
  checkSlot(slot: Slot): boolean {
    if (slot?.data?.isCorrect) {
      return true;
    }
    return false;
  }

  checkPiece(piece: Piece): boolean | undefined {
    return undefined;
  }
}
