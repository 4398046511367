import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

type Props = {
  name: string;
  chapterKey: string;
  moduleKey: string;
  firstPageKey: string;
  description: string;
  length: number;
  isCompleted: boolean;
  color: string;
  beginChapter: (moduleKey: string, key: string) => void;
  isFacilitator: boolean;
};

const Chapter = ({
  moduleKey,
  attributes,
  beginChapter,
  isActive,
  isFacilitator,
}: any) => {
  const { name, key, description, length, isCompleted, isLocked, color } =
    attributes;

  const icon = isCompleted ? (
    <FontAwesomeIcon className="fa-xl text-success" icon={faCircleCheck} />
  ) : (
    <FontAwesomeIcon className="fa-xl text-gray-400" icon={faCircle} />
  );

  const btnClass = isActive
    ? "btn btn-sm btn-primary fs-7 px-4 py-2 fw-bolder btn-hover-scale"
    : "btn btn-sm btn-secondary fs-7 px-4 py-2 fw-bold btn-hover-scale";
  const btnText = attributes.activePage
    ? "Resume"
    : isCompleted
    ? "Redo"
    : "Start";

  return (
    <div className="d-flex flex-row-fluid flex-center border-bottom pb-3 pt-3">
      <div
        className="ms-2 me-3 align-self-stretch"
        style={{
          background: color,
          borderRadius: "3px",
          minWidth: "4px",
          minHeight: "39px",
        }}
      ></div>
      <div className="pe-3">{icon}</div>
      <div className="flex-grow-1">
        {/* Name and Length */}
        <div className="d-flex flex-row">
          <div className={`fw-bolder ${isLocked && "text-gray-600"}`}>{name}</div>
          {/* <div className="ms-1 text-gray-600">{`- ${length} min`}</div> */}
        </div>
        {/* Description */}
        <div className={`${isLocked && "text-gray-600"} fs-7`}>{description}</div>
      </div>
      {!isLocked && isFacilitator && (
        <div className="ms-1 me-2">
          <button
            className={btnClass}
            onClick={() => beginChapter(moduleKey, key)}
          >
            {btnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default Chapter;
