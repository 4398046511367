import { child, DataSnapshot, onValue, set, update } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { Point } from "react-spreadsheet";
import { useDatabase } from "../../../../db/useDatabase";

const addCheckClassesToCell = (
  cell: any,
  correctCell: any,
  isChecking: boolean
) => {
  const newCell = { ...cell };
  if (newCell.className?.includes("spreadsheet-cell-editable")) {
    if (isChecking) {
      if (correctCell.correctValues?.includes(newCell.value)) {
        newCell.className = newCell.className.replaceAll(
          "spreadsheet-cell-editable-invalid",
          ""
        );
        newCell.className = newCell.className.concat(
          " spreadsheet-cell-editable-correct"
        );
      } else {
        newCell.className = newCell.className.replaceAll(
          "spreadsheet-cell-editable-correct",
          ""
        );
        newCell.className = newCell.className.concat(
          " spreadsheet-cell-editable-invalid"
        );
      }
    } else {
      newCell.className = newCell.className.replaceAll(
        "spreadsheet-cell-editable-invalid",
        ""
      );
      newCell.className = newCell.className.replaceAll(
        "spreadsheet-cell-editable-correct",
        ""
      );
    }
  }
  return newCell;
};

export const useSpreadsheetData = (
  pageKey: string,
  initialData: any
  // isFacilitator: boolean,
  // correctData: any
) => {
  const spshDb = useDatabase(`/spreadsheets/${pageKey}/data`);
  const isCheckingDb = useDatabase(`/spreadsheets/${pageKey}/isChecking`);
  const [spshData, setSpshdata] = useState<any[][]>(initialData);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [editingValue, setEditingValue] = useState<any | undefined>();
  const [active, setActive] = useState<Point | undefined>();

  const editingValueRef = useRef<any>();
  editingValueRef.current = editingValue;

  const activeRef = useRef<any>();
  activeRef.current = active;

  const setIsCheckingRemote = (val: boolean) => {
    set(isCheckingDb, val).then(() => {
      setSpreadsheetDataRemote(spshData, val);
    });
  };

  const solvePuzzle = () => {
    const data = [];
    for (let i = 0; i < spshData.length; i++) {
      const row = [];
      for (let j = 0; j < spshData[i].length; j++) {
        let cell = { ...spshData[i][j] };
        if (initialData[i][j].correctValues) {
          cell.value = initialData[i][j].correctValues[0];
        }
        row.push(cell);
      }
      data.push(row);
    }
    setSpreadsheetDataRemote(data);
  };

  const setCellDataRemote = (newData: any) => {
    if (!newData || !newData.id) {
      return;
    }
    let r, c;
    for (let i = 0; i < spshData.length; i++) {
      for (let j = 0; j < spshData[i].length; j++) {
        if (spshData[i][j].id === newData.id) {
          r = i;
          c = j;
          break;
        }
      }
      if (r !== undefined) break;
    }
    if (r === undefined || c === undefined) {
      setSpshdata([...spshData]);
      return;
    }
    const cell = addCheckClassesToCell(
      {
        ...newData,
        value:
          newData.value && typeof newData.value === "string"
            ? newData.value.trim()
            : "",
      },
      initialData[r][c],
      isChecking
    );
    update(child(spshDb, `${r}/${c}`), cell);
  };

  const setSpreadsheetDataRemote = (
    newData: any[][],
    forceIsChecking: boolean | undefined = undefined
  ) => {
    const nd: any[][] = [];

    for (let i = 0; i < newData.length; i++) {
      const nr = [];
      const row = newData[i];
      if (i >= initialData.length) {
        continue;
      }
      for (let j = 0; j < row.length; j++) {
        let cell = { ...row[j] };
        if (cell.readOnly) {
          cell = {
            ...initialData[i][j],
            iteration: (row[j].iteration ?? 0) + 1,
          };
        }
        if (cell.value === undefined) {
          cell.value = "";
        }
        const c = addCheckClassesToCell(
          cell,
          initialData[i][j],
          (forceIsChecking !== undefined && forceIsChecking) ||
            (isChecking && forceIsChecking === undefined)
        );
        nr.push(c);
      }
      nd.push(nr);
    }

    set(spshDb, nd);
  };

  useEffect(() => {
    if (!spshDb) {
      return;
    }
    return onValue(spshDb, (snap: DataSnapshot) => {
      const data = snap.val();
      if (!data) {
        set(spshDb, initialData);
      } else {
        const ev = editingValueRef.current;
        const a = activeRef.current;
        if (ev && a) {
          if (data[a.row][a.column] === spshData[a.row][a.column]) {
            data[a.row][a.column].value = ev.value;
          }
        }
        setSpshdata(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spshDb]);

  useEffect(() => {
    if (!isCheckingDb) {
      return;
    }
    return onValue(isCheckingDb, (snap: DataSnapshot) => {
      const data = snap.val();
      if (!data) {
        setIsChecking(false);
      } else {
        setIsChecking(true);
      }
    });
  }, [isCheckingDb]);

  return {
    data: spshData,
    setSpreadsheetDataRemote,
    setCellDataRemote,
    isChecking,
    setSpshdata,
    setIsCheckingRemote,
    solvePuzzle,
    setEditingValue,
    active,
    setActive,
  };
};
