import {
  faFileExcel,
  faFilePdf,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { app } from "../../app/configuration";

export type ResourceFile = {
  icon: IconDefinition;
  name: string;
  url: string;
  needsModule: string[];
  needsFacilitator: boolean;
};

const AOS_FILES: ResourceFile[] = [
  {
    icon: faFilePdf,
    name: "Participant Workbook",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/01_The_Artof_Selling_Participant_Work_Book_49fb4170e9.pdf?updated_at=2024-02-26T14:03:42.796Z",
    needsModule: [],
    needsFacilitator: false,
  },
  {
    icon: faFileExcel,
    name: "Empathy Map",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/02_Empathy_Map_Sales_U_Template_c1253431f7.xlsx?updated_at=2024-02-26T14:03:42.395Z",
    needsModule: [],
    needsFacilitator: false,
  },
  {
    icon: faFileExcel,
    name: "Network Tracker",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/03_Network_tracker_Sales_U_Template_755ee4da45.xlsx?updated_at=2024-02-26T14:03:42.398Z",
    needsModule: [],
    needsFacilitator: false,
  },
  {
    icon: faFilePdf,
    name: "Strong Relationships - Job Aid",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/04_The_Artof_Selling_Strong_Relationships_Sales_U_Job_Aid_f79f00e715.pdf?updated_at=2024-02-26T14:03:42.708Z",
    needsModule: [],
    needsFacilitator: false,
  },
  {
    icon: faFilePdf,
    name: "Memorable Selling - Job Aid",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/05_The_Artof_Selling_Memorable_Selling_Sales_U_Job_Aid_92fc8799d3.pdf?updated_at=2024-02-26T14:03:42.396Z",
    needsModule: [],
    needsFacilitator: false,
  },
  {
    icon: faFilePdf,
    name: "Earning Trust Daily - Job Aid",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/06_The_Artof_Selling_Earning_Trust_Sales_U_Job_Aid_996bf8ee3c.pdf?updated_at=2024-02-26T14:03:42.709Z",
    needsModule: [],
    needsFacilitator: false,
  },
];

const RMF_FILES: ResourceFile[] = [
  {
    icon: faFilePdf,
    name: "Main Guide",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Main_Guide_55310ea0aa.pdf?updated_at=2023-07-25T14:27:38.377Z",
    needsModule: [],
    needsFacilitator: true,
  },
  {
    icon: faFilePdf,
    name: "Coaching Guide",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Coaching_Guide_67ee390baf.pdf?updated_at=2022-11-09T08:53:40.399Z",
    needsModule: [],
    needsFacilitator: true,
  },
  {
    icon: faFilePdf,
    name: "Job Aid Fundamentals",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Joib_Aid_Fundamentals_7ecebeba22.pdf?updated_at=2023-06-30T15:59:05.980Z",
    needsModule: ["M1", "M1-SL"],
    needsFacilitator: false,
  },
  {
    icon: faFilePdf,
    name: "Job Aid Retailers vs. Manuf.",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Job_Aid_Retailers_vs_Manufacturers_fb8a28e5cd.pdf?updated_at=2023-08-24T09:26:39.245Z",
    needsModule: ["M1", "M1-SL"],
    needsFacilitator: false,
  },
  {
    icon: faFileExcel,
    name: "Retailers vs. Manuf. P&Ls",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Retailers_vs_Manufacturers_P_and_Ls_c81eeade80.xlsx?updated_at=2023-08-21T17:20:25.880Z",
    needsModule: ["M1", "M1-SL"],
    needsFacilitator: false,
  },
  {
    icon: faFilePdf,
    name: "Job Aid Digital",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Job_Aid_Digital_4e6a478153.pdf?updated_at=2022-11-01T10:49:11.543Z",
    needsModule: ["M2", "M2-SL"],
    needsFacilitator: false,
  },
  {
    icon: faFilePdf,
    name: "Job Aid Simulation",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Job_Aid_Simulation_fffc798b4c.pdf?updated_at=2023-07-25T14:27:37.117Z",
    needsModule: ["M3"],
    needsFacilitator: false,
  },

  {
    icon: faFilePdf,
    name: "RMF Workbook",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Workbook_aa43e905c5.pdf?updated_at=2023-07-25T14:27:38.190Z",
    needsModule: [],
    needsFacilitator: false,
  },
  {
    icon: faFileExcel,
    name: "Simulation Excel",
    url: "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/RMF_Simulation_Excel_59a787d321.xlsx?updated_at=2023-06-30T15:59:05.272Z",
    needsModule: ["M3"],
    needsFacilitator: false,
  },
];

export const RESOURCE_FILES = app === "rmf" ? RMF_FILES : AOS_FILES;
