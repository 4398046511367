import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  Activity,
  AuthOptionsResponseDto,
  SessionResponseDto,
  StatusResponseDto,
  UpdateActivityRequestDto,
} from "./types";

const url: Record<string, string> = {
  local: "http://localhost:3001",
  development: "https://pg.api.nersio.dev",
  production: "https://pg.api.nersio.com",
};

export const zetaApi = createApi({
  reducerPath: "zetaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: url[process.env.REACT_APP_ENV ?? "local"],
    credentials: "include",
  }),
  endpoints: (builder) => ({
    // Auth
    authStatus: builder.query<StatusResponseDto, void>({
      query: () => ({
        url: `auth/status`,
        method: "GET",
      }),
    }),
    authOptions: builder.query<AuthOptionsResponseDto, void>({
      query: () => ({
        url: `auth/options`,
        method: "GET",
      }),
    }),
    authSession: builder.query<SessionResponseDto, void>({
      query: () => ({
        url: `auth/session`,
        method: "GET",
      }),
      transformResponse: (response: SessionResponseDto) => {
        return response;
      },
    }),

    // Activities
    activity: builder.query<Activity, string>({
      query: (id: string) => `activities/${id}`,
    }),
    updateActivity: builder.mutation<Activity, UpdateActivityRequestDto>({
      query: (body: UpdateActivityRequestDto & { id: string }) => ({
        url: `activities/${body.id}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useAuthStatusQuery,
  useAuthOptionsQuery,
  useAuthSessionQuery,
  useActivityQuery,
  useUpdateActivityMutation,
} = zetaApi;
