import Konva from "konva";
import * as Firebase from "firebase/database";

import { MPPiece } from "./MPPiece";
import { PuzzleManager } from "./PuzzleManager";
import { Slot } from "./Slot";
import { SPPiece } from "./SPPiece";
import { SPPieceManager } from "./SPPieceManager";
import { Vector2 } from "./Vector2";

const triggerDistance = 80;
const fillColor = "#D4F9C5";

export class SlotManager {
  layer: Konva.Layer;

  slots: Record<string, Slot>;

  puzzleManager: PuzzleManager;

  filledSlot: Slot | undefined;

  constructor({ slotsData, layer, puzzleManager }: any) {
    this.layer = layer;

    this.slots = slotsData.reduce((sts: Record<string, Slot>, sd: any) => {
      sts[sd.key] = new Slot({ slotData: sd, layer });
      return sts;
    }, {});

    this.puzzleManager = puzzleManager;
  }

  getNearestSlot(p: Konva.Vector2d) {
    const slts = Object.values(this.slots);
    const pcs = Object.values(this.puzzleManager.pieceManager.pieces);

    let slot: Slot | undefined = undefined;
    let shortestDistance = triggerDistance;

    for (let i = 0; i < slts.length; i++) {
      const slt = slts[i];
      const sltPos = new Vector2(slt.component.x(), slt.component.y());
      const distToSlot = new Vector2(p.x, p.y).distance(sltPos);
      if (distToSlot < shortestDistance) {
        const pcOnSlot = pcs.find((pc) => {
          const pcPos = pc.component.position();
          return new Vector2(pcPos.x, pcPos.y).nearlyEquals(sltPos, 5);
        });
        if (!pcOnSlot) {
          shortestDistance = distToSlot;
          slot = slt;
        }
      }
    }

    if (slot) {
      this.resetFilledSlot();
      slot.component.fill(fillColor);
      this.filledSlot = slot;
    } else {
      if (this.filledSlot) {
        this.resetFilledSlot();
      }
    }
    return slot;
  }

  resetFilledSlot() {
    if (this.filledSlot) {
      this.filledSlot.component.fill("transparent");
      this.filledSlot = undefined;
    }
  }

  triggerSolve() {
    if (!this.puzzleManager.isSinglePlayer) {
      this.solvePuzzle();
    } else {
      if (this.puzzleManager.pieceManager instanceof SPPieceManager) {
        Firebase.set(this.puzzleManager?.pieceManager?.solvedAtDb, Date.now());
      }
    }
  }

  solvePuzzle() {
    const slts = Object.values(this.slots);
    const pcs = Object.values(this.puzzleManager.pieceManager.pieces);
    const solved: string[] = [];

    slts.forEach((s) => {
      const p = pcs.find(
        (p) =>
          s.data?.matchingPieces?.includes(p.key) && !solved.includes(p.key)
      );
      if (!p) {
        return;
      }
      solved.push(p.key);
      if (p instanceof MPPiece) {
        p.updateRemote({
          isDragging: false,
          x: s.component.position().x,
          y: s.component.position().y,
        });
      }
      if (p instanceof SPPiece) {
        p.component.position(s.component.position());
      }
    });
  }
}
