import * as Firebase from "firebase/database";
import Konva from "konva";
import { FirebaseStore } from "../../../../../db/FirebaseStore";
import { Cursor } from "./Cursor";

import reduxStore from "../../../../../app/store";
import { Vector2 } from "./Vector2";

const updateRate = 100;

export class CursorManager {
  database: Firebase.DatabaseReference;

  userDatabase: Firebase.DatabaseReference;

  cursors: Record<string, Cursor>;

  layer: Konva.Layer;

  userId: string;

  name: string;

  color: string;

  userCursorPosition = Vector2.zero;

  lastUserCursorSync = Date.now();

  constructor({ puzzleKey, layer }: any) {
    this.userId = reduxStore.getState().app.userId!;
    this.name = reduxStore.getState().app.name!;
    this.color = reduxStore.getState().app.color;

    this.database = Firebase.child(
      FirebaseStore.getPuzzleRef(puzzleKey),
      "cursors"
    );
    this.userDatabase = Firebase.child(this.database, this.userId);

    this.layer = layer;
    this.cursors = {};
    this.initializeListener();

    const context = this;
    this.layer.getStage().on("mousemove", this.onUserCursorMove.bind(context));
  }

  onUserCursorMove() {
    const position = this.layer.getStage().getRelativePointerPosition();
    if (Date.now() - this.lastUserCursorSync > updateRate) {
      this.lastUserCursorSync = Date.now();
      Firebase.update(this.userDatabase, {
        key: this.userId,
        name: this.name,
        color: this.color,
        timestamp: Date.now(),
        x: position.x,
        y: position.y,
      });
    }
    // console.log("Yo!");
  }

  private initializeListener() {
    Firebase.onValue(this.database, (snapshot) => {
      const data = snapshot.val();

      if (!data) {
        return;
      }

      Object.values(data).forEach((c: any) => {
        if (c.key === this.userId) {
          return;
        }
        if (c.timestamp && Date.now() - c.timestamp > 60000) {
          Firebase.remove(Firebase.child(this.database, c.key));
        }
        if (c.key in this.cursors) {
          this.cursors[c.key].update(c);
        } else {
          this.cursors[c.key] = new Cursor({
            key: c.key,
            name: c.name,
            layer: this.layer,
            color: c.color,
          });
        }
      });

      Object.values(this.cursors).forEach((c) => {
        try {
          if (!(c.key in data)) {
            this.cursors[c.key].remove();
            delete this.cursors[c.key];
          }
        } catch (e) {}
      });
    });
  }

  cleanUp() {
    Firebase.remove(this.database);
    Firebase.off(this.database);
  }
}
