import Konva from "konva";

const ComponentMap: Record<string, any> = {
  rect: Konva.Rect,
  circle: Konva.Circle,
  line: Konva.Line,
};

const sharedComponentProps = {
  draggable: false,
  strokeWidth: 10,
  opacity: 0.5,
};

export class Slot {
  component: Konva.Rect | Konva.Line | Konva.Circle; 

  data: any;

  constructor({ slotData, layer }: any) {
    this.component = this.createSlot(slotData, layer);

    this.data = slotData;
  }

  private createSlot(slotData: any, layer: Konva.Layer) {
    const c = new ComponentMap[slotData.component]({
      ...sharedComponentProps,
      ...slotData,
    });
    layer.add(c);
    return c;
  }
}
