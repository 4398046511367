import { FormattedMessage } from "react-intl";

import { Modal } from "react-bootstrap";
import CopyButton from "./CopyButton";

export const InvitationModal = ({
  show,
  onHide,
  sessionNumber,
  facilitatorNumber,
}: any) => {
  const partMsg = `To join the interactive Art of Selling Application:\n\n1. Enter the website https://aos.nersio.com?sessionNumber=${sessionNumber} from your browser (preferably Google Chrome).\n2. When prompted to Sign in, enter the following credentials:\n\t\tUsername: aos\n\t\tPassword: 1loveAOS\n3. Enter your name in the first input field, per instructions on the screen.\n4. Wait in the virtual lobby for a facilitator to let you know when to join.`;
  const facMsg = `To join the interactive Art of Selling Application:\n\n1. Enter the website https://aos.nersio.com?sessionNumber=${facilitatorNumber} from your browser (preferably Google Chrome).\n2. When prompted to Sign in, enter the following credentials:\n\t\tUsername: aos\n\t\tPassword: 1loveAOS\n3. Enter your name in the first input field, per instructions on the screen.\n4. When ready, click the Start button to start the session and allow the participants to join, or click Join if the session was already started previously.`;

  return (
    <Modal show={show} onHide={onHide}>
      <form autoComplete="off">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="Management.InvitationModal.Title" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-5">
            You can click the button next to the invitation messages to copy
            them to your clipboard and use them in an invitation e-mail or
            similar.
          </div>
          {/* Copy Message To Participants  */}
          <div className="d-flex flex-row mb-6">
            <div className="d-flex flex-column flex-grow-1 me-3">
              <FormattedMessage
                tagName="h6"
                id="Management.InvitationModal.Message.Participants.Title"
              />
              <textarea
                readOnly
                value={partMsg}
                className="bg-gray-100 rounded resize-none border-0 p-5 h-300px"
              ></textarea>
            </div>
            <div className="d-flex align-self-stretch flex-center">
              <CopyButton text={partMsg} />
            </div>
          </div>

          {/* Copy Message To Facilitators  */}
          <div className="d-flex flex-row">
            <div className="d-flex flex-column flex-grow-1 me-3">
              <FormattedMessage
                tagName="h6"
                id="Management.InvitationModal.Message.Facilitators.Title"
              />
              <textarea
                readOnly
                value={facMsg}
                className="bg-gray-100 rounded resize-none border-0 p-5 h-350px "
              ></textarea>
            </div>
            <div className="d-flex align-self-stretch flex-center">
              <CopyButton text={facMsg} />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm fs-7 btn-secondary align-self-end py-2 fw-bolder"
            onClick={onHide}
          >
            <FormattedMessage id="General.Close" />
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
