import { PageAttributes } from "../../../app/types";
import * as _ from "lodash";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Section from "./Section";
import { Section as SectionType } from "../../../app/types";
// import { useTrail, config, useTransition, animated } from "react-spring";
import { PageTransition } from "../../services/usePagination";
import { useAppSelector } from "../../../app/hooks";
import {
  selectHandbookContentHeight,
  selectIsFacilitator,
} from "../../../app/appSlice";
import { useScaledTextClasses } from "../../services/useScaledTextClasses";
import { FormattedMessage } from "react-intl";
import { useEffect, useMemo, useRef, useState } from "react";
import { selectActiveKeys } from "../../services/courseSlice";

import ControlButtons from "./ControlButtons";
import { app } from "../../../app/configuration";

type Props = {
  attributes: PageAttributes;
  setPrevPage: () => void;
  setNextPage: () => void;
  isFirstPage: boolean;
  isLastPage: boolean;
  pageTransition: PageTransition;
};

export enum View {
  Facilitator,
  Participant,
}

export enum StepTransition {
  Previous,
  Next,
}

export const Page = ({
  attributes,
  pageTransition,
  isFirstPage,
  isLastPage,
  setPrevPage,
  setNextPage,
  setPrevStep,
  setNextStep,
  setStep,
}: any) => {
  const handbookContentHeight = useAppSelector(selectHandbookContentHeight);
  const isFacilitator = useAppSelector(selectIsFacilitator);
  const { step } = useAppSelector(selectActiveKeys);
  const { classes } = useScaledTextClasses();

  const { sections, hasAsyncStepControl } = attributes as PageAttributes;
  const [stepTransition, setStepTransition] = useState<StepTransition>(
    StepTransition.Next
  );

  const groupedSections = useMemo(() => {
    return Array.from(
      sections
        .slice()
        .sort((a, b) => a.step - b.step)
        .filter((a) => !(!isFacilitator && a.isFacilitatorGuide))
        .reduce((acc: Map<number, SectionType[]>, ss: SectionType) => {
          let g = acc.get(ss.step);
          if (!g) {
            g = [];
          }
          g.push(ss);
          acc.set(ss.step, g);
          return acc;
        }, new Map())
        .values()
    );
  }, [sections, isFacilitator]);

  const activeSectionRef = useRef<any>();

  useEffect(() => {
    if (!activeSectionRef.current) {
      return;
    }
    if (hasAsyncStepControl) {
      return;
    }

    const block =
      activeSectionRef.current.clientHeight <
      activeSectionRef.current.parentElement.clientHeight
        ? "center"
        : "start";

    activeSectionRef.current.scrollIntoView({
      behavior: "smooth",
      block,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSectionRef.current, step]);

  const lastStep =
    groupedSections[groupedSections.length - 1] &&
    groupedSections[groupedSections.length - 1].length > 0 &&
    groupedSections[groupedSections.length - 1][0].step;
  const isLastStep = step === lastStep || hasAsyncStepControl;

  const controlButtons = (
    <ControlButtons
      step={step}
      setNextStep={setNextStep}
      setNextPage={setNextPage}
      setPrevStep={setPrevStep}
      setPrevPage={setPrevPage}
      isLastStep={isLastStep}
      isLastPage={isLastPage}
      isFirstPage={isFirstPage}
      setStepTransition={setStepTransition}
      stepTransition={stepTransition}
      hasAsyncStepControl={hasAsyncStepControl}
    />
  );

  const sectionsRender = groupedSections.map((group, index) => {
    const groupStep = group[0].step;
    const isActive =
      isFacilitator && !hasAsyncStepControl
        ? groupStep === step
        : groupStep <= step;
    const reffed = groupStep === step;
    const withControls = isFacilitator && (isActive || hasAsyncStepControl);

    return (
      <div
        key={index}
        className="pt-2"
        ref={reffed ? activeSectionRef : undefined}
      >
        {group.map((s, i) => {
          return (
            <Section
              key={i}
              isActive={isActive}
              isFacilitator={isFacilitator}
              section={s}
              setStep={setStep}
              step={step}
              hasAsyncStepControl={hasAsyncStepControl}
            />
          );
        })}
        {withControls && controlButtons}
        {isFacilitator && groupStep < lastStep && (
          <div className="separator separator-content mb-4 mt-8">
            {groupStep < step ? (
              <i className="fas fa-regular fa-circle-check"></i>
            ) : (
              <i className="fas fa-regular fa-circle"></i>
            )}
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      <div
        className={`position-relative ${classes}`}
        style={{ height: `${handbookContentHeight}px`, overflow: "auto" }}
        // onScroll={handleScroll}
      >
        {groupedSections.length > 0 ? sectionsRender : controlButtons}

        {app === "rmf" && (
          <div className="fs-8 text-danger disclaimer">
            <FormattedMessage id={"General.Disclaimer"} />
          </div>
        )}
        <div className="pb-20"></div>
      </div>
    </>
  );
};
