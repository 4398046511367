import { app } from "../app/configuration";

const aosUrl: Record<string, any> = {
  local: "https://pg-aos-delta-dev.europe-west1.firebasedatabase.app/",
  development: "https://pg-aos-delta-dev.europe-west1.firebasedatabase.app/",
  production: "https://pg-aos-delta-prod.europe-west1.firebasedatabase.app/",
};

const aosConfig = {
  apiKey: "AIzaSyDbAFUlUVEJvtkE3f661Y2jKWqgEXz3RrY",
  authDomain: "pg-aos-delta.firebaseapp.com",
  projectId: "pg-aos-delta",
  storageBucket: "pg-aos-delta.appspot.com",
  messagingSenderId: "371218524341",
  appId: "1:371218524341:web:3db4f4112f698394a4b6fc",
  databaseURL: aosUrl[process.env.REACT_APP_ENV ?? "local"],
};

const rmfUrl: Record<string, any> = {
  local: "https://rmf-eu-dev.europe-west1.firebasedatabase.app/",
  development: "https://rmf-eu-dev.europe-west1.firebasedatabase.app/",
  production: "https://rmf-eu.europe-west1.firebasedatabase.app/",
};

const rmfConfig = {
  apiKey: "AIzaSyBV9NaIZokhmDTUteejGNzlZDnqrNMH2YM",
  authDomain: "pg-rmf-3d751.firebaseapp.com",
  projectId: "pg-rmf-3d751",
  storageBucket: "pg-rmf-3d751.appspot.com",
  messagingSenderId: "788576069205",
  appId: "1:788576069205:web:88d7fcdbce33ebf97216dc",
  databaseURL: rmfUrl[process.env.REACT_APP_ENV ?? "local"],
};

export const firebaseConfig = app === "rmf" ? rmfConfig : aosConfig;
