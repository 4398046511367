import { Dimensions } from "../services/useSceneTransform";
import { Piece } from "./Piece";

export class SPPiece extends Piece {
  constructor(props: any) {
    super({ ...props, componentProps: { draggable: true } });
  }

  onMouseEnter() {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "grab";
    }
  }

  onMouseLeave() {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "default";
    }
  }

  onDragStart(e: any) {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "grabbing";
    }

    this.component.shadowEnabled(true);
    this.component.moveTo(this.activeLayer);
    this.wiggleAnimation.start();
  }

  onDragMove(e: any) {
    const p = e.target.position();

    this.puzzleManager.slotManager.getNearestSlot(p);
  }

  onDragEnd(e: any) {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "grab";
    }

    this.dropPiece();
    let p = e.target.position();
    const slt = this.puzzleManager.slotManager.getNearestSlot(p);

    if (slt) {
      p = slt.component.position();
      this.puzzleManager.slotManager.resetFilledSlot();
    }

    p = this.puzzleManager.pieceManager.findDropPosition(this.key, p);

    if (p.x) {
      if (p.x < 0) {
        p.x = 1;
      }
      if (p.x > Dimensions.WIDTH) {
        p.x = Dimensions.WIDTH;
      }
    }
    if (p.y) {
      if (p.y < 0) {
        p.y = 1;
      }
      if (p.y > Dimensions.HEIGHT) {
        p.y = Dimensions.HEIGHT;
      }
    }

    this.component.position({
      x: p.x,
      y: p.y,
    });
  }

  remove() {
    try {
      // console.log("REMOVING");
      this.component.remove();
    } catch (e) {}
  }
}
