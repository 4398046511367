import * as Firebase from "firebase/database";
import Konva from "konva";

import { FirebaseStore } from "../../../../../db/FirebaseStore";
import { Flag } from "./Flag";
import reduxStore from "../../../../../app/store";
import { Vector2 } from "./Vector2";

export class FlagManager {
  database: Firebase.DatabaseReference;

  flags: Flag[] = [];

  layer: Konva.Layer;

  userId: string;

  userName: string;

  userColor: string;

  constructor({ puzzleKey, layer, backgroundLayer }: any) {
    this.userId = reduxStore.getState().app.userId!;
    this.userName = reduxStore.getState().app.name!;
    this.userColor = reduxStore.getState().app.color;

    this.database = Firebase.child(
      FirebaseStore.getPuzzleRef(puzzleKey),
      "flags"
    );
    this.layer = layer;

    const stage = this.layer.getStage();
    stage.on("click", (e) => {
      const pos = e.currentTarget.getStage()?.getRelativePointerPosition();
      if (pos) {
        this.setFlagRemote(new Vector2(pos.x, pos.y));
      }
    });

    this.initializeListener();
  }

  resetPositions() {
    Firebase.remove(this.database);
  }

  destroyFlag() {
    Firebase.remove(Firebase.child(this.database, this.userId));
  }

  setFlagRemote(pos: Vector2) {
    if (this.userId && this.userName && pos.x && pos.y) {
      Firebase.set(Firebase.child(this.database, this.userId), {
        color: this.userColor,
        x: pos.x,
        y: pos.y,
        key: this.userId,
        name: this.userName,
        timestamp: Date.now(),
      });
    }
  }

  private initializeListener() {
    Firebase.onValue(this.database, (snapshot) => {
      const data = snapshot.val();

      if (!data) {
        this.flags.forEach((f) => f.dispose());
        // Firebase.set(this.database, {});
        return;
      }

      const flgs = Object.values(data).sort((a: any, b: any) => {
        return a.timestamp - b.timestamp;
      });

      this.flags.forEach((f) => f.dispose());
      this.flags = flgs.map((f: any) => {
        return new Flag({
          ...f,
          flagManager: this,
        });
      });
    });
  }

  cleanUp() {
    Firebase.off(this.database);
  }
}
