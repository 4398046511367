import * as Firebase from "firebase/database";

import Konva from "konva";
import { Vector2 } from "./Vector2";
import reduxStore from "../../../../../app/store";
import { IFrame } from "konva/lib/types";
import { PuzzleManager } from "./PuzzleManager";
import { Dimensions } from "../services/useSceneTransform";
import { StickyManager } from "./StickyManager";

// const velocity = 500;
const updateRate = 100;
const velocity = 70;
const positionBuffer = 10;
const wiggleAngle = 3;
const wiggleSpeed = 3;

export class Sticky {
  key: string;

  draggingUser: String | null = null;

  owner: String | null = null;

  component: Konva.Group;

  positions: Vector2[] = [];

  lastUpdatedPosition = Vector2.zero();

  layer: Konva.Layer;

  activeLayer: Konva.Layer;

  moveAnimation: Konva.Animation | undefined;

  textNode: Konva.Text | undefined;

  backgroundRect: Konva.Rect | undefined;

  likes: string[] = [];

  likeBtn: Konva.Group | undefined;

  likesGroup: Konva.Group | undefined;

  // wiggleAnimation: Konva.Animation;

  wiggleDirection = wiggleSpeed;

  database: Firebase.DatabaseReference;

  lastSynced = Date.now();

  userId: string | null;

  stickyManager: StickyManager;

  data: any;

  isSinglePlayer: boolean;

  isFacilitator: boolean;

  get isOwnedDragging() {
    return this.userId && this.userId === this.draggingUser;
  }

  get isOwned() {
    return this.userId && this.userId === this.owner;
  }

  private createLike(x: number, y: number) {
    return new Konva.Text({
      x,
      y,
      fontSize: 25,
      scale: { x: 1.2, y: 1.2 },
      rotation: 5,
      offset: { x: 15, y: 15 },
      fontFamily: "FontAwesome",
      text: "\uf164",
      stroke: "white",
      fill: "#403d38",
      strokeWidth: 1,
    });
  }

  private createComponent({ x, y, text, color }: any) {
    const stickyWidth = 200;
    const margin = 20;
    const btnSize = 30;
    const textWidth = stickyWidth - 2 * margin;

    const isDraggable =
      !this.isSinglePlayer ||
      (this.isSinglePlayer && (this.isOwned || this.isFacilitator));
    const sticky = new Konva.Group({
      x,
      y,
      draggable: isDraggable,
    });

    const textNode = new Konva.Text({
      text,
      x: margin,
      y: 3 * margin,
      fontSize: 18,
      fill: "#2F344A",
      fontFamily: "Poppins",
      width: textWidth,
    });
    this.textNode = textNode;

    const createBtn = ({ x, icon }: any) => {
      const bgrp = new Konva.Group({
        x,
        y: 0,
      });
      bgrp.add(
        new Konva.Circle({
          radius: btnSize / 2,
          fill: "white",
        })
      );
      bgrp.add(
        new Konva.Text({
          x: -btnSize / 2,
          y: -btnSize / 2,
          height: btnSize,
          width: btnSize,
          fontSize: 15,
          verticalAlign: "middle",
          fontFamily: "FontAwesome",
          text: icon,
          fill: "#004004",
          align: "center",
        })
      );
      return bgrp;
    };

    const bgRect = new Konva.Rect({
      x: 0,
      y: 0,
      width: stickyWidth,
      height: textNode.height() + 4 * margin,
      fill: color,
      cornerRadius: [10, 10, 10, 10],
    });
    this.backgroundRect = bgRect;

    sticky.offsetX(bgRect.width() / 2);
    sticky.offsetY(bgRect.height() / 2);

    const likeBtn = createBtn({
      x: stickyWidth - (this.isFacilitator ? 3 * btnSize + 4 : btnSize),
      icon: "\uf164",
    });
    this.likeBtn = likeBtn;

    const editBtn = createBtn({
      x: stickyWidth - 2 * btnSize - 2,
      icon: "\uf304",
    });

    const deleteBtn = createBtn({
      x: stickyWidth - btnSize,
      icon: "\u0058",
    });

    const context = this;
    deleteBtn.on("click", this.delete.bind(context));
    deleteBtn.on("mouseenter", this.onMouseEnter.bind(context, "pointer"));
    deleteBtn.on("mouseleave", this.onMouseLeave.bind(context));

    editBtn.on("click", this.edit.bind(context));
    editBtn.on("mouseenter", this.onMouseEnter.bind(context, "pointer"));
    editBtn.on("mouseleave", this.onMouseLeave.bind(context));

    likeBtn.on("click", this.like.bind(context));
    likeBtn.on("mouseenter", this.onMouseEnter.bind(context, "pointer"));
    likeBtn.on("mouseleave", this.onMouseLeave.bind(context));
    // sticky.on("mouseenter", this.onMouseEnter.bind(context, "grab"));
    // sticky.on("mouseleave", this.onMouseLeave.bind(context));

    const btnGroup = new Konva.Group({ y: btnSize });
    const grip = new Konva.Text({
      x: btnSize / 2,
      y: -btnSize / 2,
      height: btnSize,
      width: btnSize,
      fontSize: 30,
      verticalAlign: "middle",
      fontFamily: "FontAwesome",
      text: "\uf58d",
      fill: "#152f004d",
      align: "center",
    });

    if (this.isSinglePlayer && (this.isFacilitator || this.isOwned)) {
      btnGroup.add(grip);
    } else if (!this.isSinglePlayer) {
      btnGroup.add(grip);
    }

    if (this.isFacilitator) {
      btnGroup.add(editBtn);
      btnGroup.add(deleteBtn);
      if (!this.isOwned) {
        btnGroup.add(likeBtn);
      }
    } else if (this.isOwned) {
      btnGroup.add(editBtn);
      btnGroup.add(deleteBtn);
    } else {
      btnGroup.add(likeBtn);
    }

    const likes = new Konva.Group({
      y: bgRect.height(),
    });
    this.likesGroup = likes;
    sticky.add(bgRect);
    sticky.add(textNode);
    sticky.add(btnGroup);
    sticky.add(likes);

    setTimeout(() => {
      sticky.draw();
    }, 500);

    setTimeout(() => {
      sticky.draw();
    }, 1000);

    return sticky;
  }

  constructor({ stickyData, database, stickyManager }: any) {
    this.database = database;
    this.key = stickyData.key;
    this.userId = reduxStore.getState().app.userId;
    this.owner = stickyData.owner;
    this.stickyManager = stickyManager;
    this.data = stickyData;
    this.layer = this.stickyManager.stickiesManager.stickyLayer;
    this.activeLayer = this.stickyManager.stickiesManager.activeLayer;
    this.isSinglePlayer =
      this.stickyManager.stickiesManager?.config?.isSinglePlayer || false;
    this.isFacilitator =
      this.stickyManager.stickiesManager?.config?.isFacilitator || false;

    this.component = this.createComponent(stickyData);
    this.updateLikes(stickyData.likes);

    const pieceContext = this;
    this.component.on("dragstart", this.onDragStart.bind(pieceContext));
    this.component.on("dragmove", this.onDragMove.bind(pieceContext));
    this.component.on("dragend", this.onDragEnd.bind(pieceContext));

    this.moveAnimation = new Konva.Animation(
      this.move.bind(pieceContext) as any,
      this.layer
    );

    // this.wiggleAnimation = new Konva.Animation(
    //   this.wiggle.bind(pieceContext) as any,
    //   this.activeLayer
    // );
    // this.component.dragBoundFunc(function () {
    //   const pos: Konva.Vector2d = this.getAbsolutePosition();
    //   return { x: pos.x, y: pos.y } as Konva.Vector2d;
    // });
    this.layer.add(this.component);
  }

  updateRemote(data: Record<string, any>) {
    if (data?.x) {
      if (data?.x < 0) {
        data.x = 1;
      }
      if (data?.x > Dimensions.WIDTH) {
        data.x = Dimensions.WIDTH;
      }
    }
    if (data?.y) {
      if (data?.y < 0) {
        data.y = 1;
      }
      if (data?.y > Dimensions.HEIGHT) {
        data.y = Dimensions.HEIGHT;
      }
    }

    Firebase.update(this.database, data);
  }

  updateText(text: string) {
    if (this.textNode?.text() !== text) {
      this.textNode?.text(text);
      this.backgroundRect?.height((this.textNode?.height() ?? 20) + 4 * 20);
      this.likesGroup?.y(this.backgroundRect?.height() ?? 0);
      this.component.offsetX((this.backgroundRect?.width() ?? 0) / 2);
      this.component.offsetY((this.backgroundRect?.height() ?? 0) / 2);
    }
  }

  updateLikes(newLikes: Record<string, string> | undefined) {
    if (!this.userId) {
      return;
    }

    if (!newLikes) {
      newLikes = {};
    }

    const [circle, text] = this.likeBtn?.getChildren() as any;
    if (newLikes[this.userId]) {
      circle.fill("#5f91f3");
      text.fill("white");
    } else {
      circle.fill("white");
      text.fill("#004004");
    }
    this.likesGroup?.removeChildren();
    this.likes = Object.values(newLikes);
    this.likes.forEach((_, i) => {
      const row = Math.floor(i / 5);
      this.likesGroup?.add(
        this.createLike(35 + row * 15 + (i % 5) * 35, row * 20)
      );
    });
  }

  update(data: Record<string, any>) {
    this.draggingUser = data.isDragging;
    this.updateText(data.text);
    this.updateLikes(data.likes);

    this.backgroundRect?.fill(data.color);

    if (this.isOwnedDragging && data.isDragging !== this.userId) {
      this.dropPiece();
    }

    if (this.isOwnedDragging) {
      return;
    }
    const pos = new Vector2(data.x, data.y);
    if (pos.equals(this.lastUpdatedPosition)) {
      return;
    }
    // console.log("Adding position, is owned?", this.isOwnedDragging);

    if (this.positions.length >= positionBuffer) {
      this.positions.shift();
    }
    // this.component.shadowEnabled(false);
    this.lastUpdatedPosition = pos;
    this.positions.push(pos);
    this.component.draggable(false);
    if (data.zIndex) {
      this.component.zIndex(data.zIndex);
    }
    this.moveAnimation?.start();
  }

  onMouseEnter(cursorType: string) {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = cursorType;
    }
  }

  onMouseLeave() {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "default";
    }
  }

  onDragStart(e: any) {
    if (this.isSinglePlayer && !this.isFacilitator && !this.isOwned) {
      return;
    }

    // console.log("Drag start, user:", this.draggingUser, this.positions);
    if (this.draggingUser) {
      return;
    }

    if (this.positions.length > 0) {
      return;
    }

    // const stg = this.component.getStage();
    // if (stg) {
    //   stg.container().style.cursor = "grabbing";
    // }

    this.lastUpdatedPosition = Vector2.zero();
    // this.component.shadowEnabled(true);
    this.component.moveTo(this.activeLayer);
    // this.wiggleAnimation.start();
    this.positions = [];
    this.updateRemote({ isDragging: this.userId || false, zIndex: Date.now() });
  }

  onDragMove(e: any) {
    // console.log(e);
    // console.log("onDragMove");
    // e.evt.preventDefault();
    if (!this.isOwnedDragging) {
      return;
    }
    const p = e.target.position();

    this.stickyManager.stickiesManager.cursorManager?.onUserCursorMove();
    // this.puzzleManager.slotManager.getNearestSlot(p);

    if (Date.now() - this.lastSynced > updateRate) {
      this.lastSynced = Date.now();
      this.updateRemote({ x: Math.floor(p.x), y: Math.floor(p.y) });
    }
  }

  onDragEnd(e: any) {
    // console.log("onDragEnd");

    if (!this.isOwnedDragging) {
      return;
    }

    // const stg = this.component.getStage();
    // if (stg) {
    //   stg.container().style.cursor = "grab";
    // }

    this.dropPiece();
    let p = e.target.position();

    this.updateRemote({
      isDragging: false,
      x: Math.floor(p.x),
      y: Math.floor(p.y),
      zIndex: Date.now(),
    });
  }

  dropPiece() {
    this.component.moveTo(this.layer);
    // this.wiggleAnimation.stop();
    this.component.rotation(0);
    // this.component.shadowEnabled(false);
  }

  like() {
    if (!this.userId) {
      return;
    }
    if (!this.isOwned) {
      if (this.likes.includes(this.userId)) {
        this.stickyManager.removeLike({
          database: this.database,
          userId: this.userId,
        });
      } else {
        this.stickyManager.addLike({
          database: this.database,
          userId: this.userId,
        });
      }
    }
  }

  delete() {
    this.stickyManager.deleteNote(this.database);
    this.stickyManager.stickiesManager.stage.container().style.cursor =
      "default";
  }

  edit() {
    this.stickyManager.openEditNoteModal(
      this.key,
      this.textNode?.text(),
      this.backgroundRect?.fill()
    );
  }

  move(frame: IFrame) {
    // this.animation?.stop();
    // console.log("Animating");
    // var dist = velocity * (frame!.timeDiff / 1000);
    // this.component.move({ x: dist, y: 0 });
    // console.log(this.positions);
    if (this.isOwnedDragging) {
      // console.log("Item is owned");
      this.moveAnimation!.stop();
    }
    if (this.positions.length === 0) {
      // console.log(this);
      // console.log("No more positions to iterate");
      this.moveAnimation!.stop();
      if (
        !this.isSinglePlayer ||
        (this.isSinglePlayer && (this.isFacilitator || this.isOwned))
      ) {
        this.component.draggable(true);
      }
      return;
    }
    const newPosition = this.positions[0];
    const currentPosition = new Vector2(this.component.x(), this.component.y());
    if (newPosition.nearlyEquals(currentPosition, 2)) {
      // console.log("Reached destination");
      this.positions.shift();
      return;
    }

    const totalDistance = this.positions.reduce((td, p, i) => {
      if (i === 0) {
        if (this.positions.length < 2) {
          return p.distance(currentPosition);
        }
        return td;
      }
      if (i === 1) {
        return td + p.distance(currentPosition);
      }
      return td + p.distance(this.positions[i - 1]);
    }, 0);
    const distanceVector = newPosition.subtract(currentPosition);
    const distance = distanceVector.magnitude();

    let multiplier = velocity * Math.max(2, (totalDistance * 2.5) / velocity);
    // }
    const movement = distanceVector
      .normalize()
      .scale(multiplier * (frame!.timeDiff / 1000));
    const mmag = movement.magnitude();
    if (distance <= mmag) {
      this.component.move({ x: distanceVector.x, y: distanceVector.y });
      return;
    }
    // console.log(movement);
    this.component.move({ x: movement.x, y: movement.y });
  }

  wiggle(frame: IFrame) {
    const r = this.component.rotation();
    if (r > wiggleAngle) {
      this.wiggleDirection = -wiggleSpeed;
    } else if (r < -wiggleAngle) {
      this.wiggleDirection = wiggleSpeed;
    }
    this.component.rotate(this.wiggleDirection * (frame!.timeDiff / 1000));
  }

  remove() {
    try {
      this.positions = [];
      this.moveAnimation?.stop();
      this.moveAnimation?.func(undefined);
      this.component.remove();
    } catch (e) {}
  }
}
