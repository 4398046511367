import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";
import { USER_COLOR } from "./colors";
import { SessionType } from "./types";

interface AppState {
  name: string | null;
  userId: string | null;
  sessionId: string | null;
  sessionName: string | null;
  modules: string[];
  color: string;
  isStarted: boolean | null;
  isFacilitator: boolean;
  textScale: number;
  navbarHeight: number;
  contentHeight: number;
  handbookContentHeight: number;
  handbookWidth: number;
  handbookWidthPct: number;
  canvasWidth: number;
  isConfirmationDialogOpen: boolean;
  confimationDialogMessage: string;
  type: SessionType | null;
}

const handbookRatio = window.innerWidth <= 1600 ? 0.3 : 0.25;
const handbookWidth = Math.floor(window.innerWidth * handbookRatio);
const textScale = window.innerWidth > 1280 ? 0 : -1;

// const initialState: AppState = {
//   name: "Bruno",
//   userId: "1123123" + Math.floor(Math.random() * 10),
//   sessionId: "123-123",
//   sessionName: "Predefined name",
//   isStarted: true,
//   isFacilitator: true,
//   textScale: textScale,
//   navbarHeight: 0,
//   contentHeight: 0,
//   handbookContentHeight: 0,
//   handbookWidth: handbookWidth,
//   canvasWidth: window.innerWidth - handbookWidth - 21,
// };

const initialState: AppState = {
  name: null,
  userId: null,
  sessionId: null,
  sessionName: null,
  modules: [],
  color: USER_COLOR,
  isStarted: null,
  isFacilitator: false,
  textScale: textScale,
  navbarHeight: 0,
  contentHeight: 0,
  handbookContentHeight: 0,
  handbookWidth: handbookWidth,
  handbookWidthPct: handbookRatio,
  isConfirmationDialogOpen: false,
  confimationDialogMessage: "",
  canvasWidth: window.innerWidth - handbookWidth - 23,
  type: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string | null>) => {
      state.sessionId = action.payload;
    },
    setSessionName: (state, action: PayloadAction<string>) => {
      state.sessionName = action.payload;
    },
    setAvailableModules: (state, action: PayloadAction<string[]>) => {
      state.modules = action.payload;
    },
    setIsStarted: (state, action: PayloadAction<boolean>) => {
      state.isStarted = action.payload;
    },
    setIsFacilitator: (state, action: PayloadAction<boolean>) => {
      state.isFacilitator = action.payload;
    },
    setTextScale: (state, action: PayloadAction<number>) => {
      state.textScale = action.payload;
    },
    setNavbarHeight: (state, action: PayloadAction<number>) => {
      state.navbarHeight = action.payload;
      state.contentHeight = window.innerHeight - action.payload;
    },
    setContentHeight: (state, action: PayloadAction<number>) => {
      state.contentHeight = action.payload;
    },
    setHandbookContentHeight: (state, action: PayloadAction<number>) => {
      state.handbookContentHeight = action.payload;
    },
    setHandbookWidth: (state, action: PayloadAction<number>) => {
      state.handbookWidth = action.payload;
      state.canvasWidth = window.innerWidth - action.payload - 23;
    },
    setHandbookWidthPct: (state, action: PayloadAction<number>) => {
      state.handbookWidthPct = action.payload;
    },
    setCanvasWidth: (state, action: PayloadAction<number>) => {
      state.canvasWidth = action.payload;
    },
    setType: (state, action: PayloadAction<SessionType>) => {
      state.type = action.payload;
      if (action.payload === SessionType.SelfLed) {
        state.isFacilitator = true;
      }
    },
    setIsConfirmationModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isConfirmationDialogOpen = action.payload;
    },
    setConfirmationModalMessage: (state, action: PayloadAction<string>) => {
      state.confimationDialogMessage = action.payload;
    },
    resetState: (state, action: PayloadAction<void>) => {
      state.name = initialState.name;
      state.userId = initialState.userId;
      state.sessionId = initialState.sessionId;
      state.sessionName = initialState.sessionName;
      state.isStarted = initialState.isStarted;
      state.type = initialState.type;
    },
  },
});

export const {
  setName,
  setUserId,
  setSessionId,
  setSessionName,
  setTextScale,
  setNavbarHeight,
  setContentHeight,
  setCanvasWidth,
  setHandbookContentHeight,
  setHandbookWidth,
  setHandbookWidthPct,
  setIsFacilitator,
  setIsStarted,
  setAvailableModules,
  setType,
  setIsConfirmationModalOpen,
  setConfirmationModalMessage,
  resetState,
} = appSlice.actions;
export const selectName = (state: RootState): string | null => state.app.name;
export const selectColor = (state: RootState): string => state.app.color;
export const selectUserId = (state: RootState): string | null =>
  state.app.userId;
export const selectSessionId = (state: RootState): string | null =>
  state.app.sessionId;
export const selectSessionName = (state: RootState): string | null =>
  state.app.sessionName;
export const selectAvailableModules = (state: RootState): string[] =>
  state.app.modules;
export const selectTextScale = (state: RootState): number =>
  state.app.textScale;
export const selectNavbarHeight = (state: RootState): number =>
  state.app.navbarHeight;
export const selectContentHeight = (state: RootState): number =>
  state.app.contentHeight;
export const selectHandbookContentHeight = (state: RootState): number =>
  state.app.handbookContentHeight;
export const selectHandbookWidth = (state: RootState): number =>
  state.app.handbookWidth;
export const selectHandbookWidthPct = (state: RootState): number =>
  state.app.handbookWidthPct;
export const selectCanvasWidth = (state: RootState): number =>
  state.app.canvasWidth;
export const selectIsStarted = (state: RootState): boolean | null =>
  state.app.isStarted;
export const selectIsFacilitator = (state: RootState): boolean =>
  state.app.isFacilitator;
export const selectType = (state: RootState): SessionType | null =>
  state.app.type;
export const selectIsConfirmationModalOpen = (state: RootState): boolean =>
  state.app.isConfirmationDialogOpen;
export const selectConfirmationModalMessage = (state: RootState): string =>
  state.app.confimationDialogMessage;

export const selectShouldLogin = (state: RootState): boolean =>
  state.app.name === null ||
  state.app.userId === null ||
  state.app.sessionId === null ||
  state.app.sessionName === null ||
  !state.app.isStarted;

export default appSlice.reducer;
