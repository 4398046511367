import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModuleAttributes } from "../../../app/types";
import Chapter from "./Chapter";
import { faAngry } from "@fortawesome/free-regular-svg-icons";
import { useAppSelector } from "../../../app/hooks";
import { selectNextTodoChapter } from "../../services/courseSlice";
import { selectIsFacilitator } from "../../../app/appSlice";

type Props = {
  attributes: ModuleAttributes;
  isLocked?: boolean;
  beginChapter: (moduleKey: string, chapterKey: string) => void;
};

const Module = ({ attributes, isLocked, beginChapter }: Props) => {
  const {
    name,
    key,
    chapters: { data: chapters },
  } = attributes;
  const nextTodoChapter = useAppSelector(selectNextTodoChapter);
  const isFacilitator = useAppSelector(selectIsFacilitator);

  const header = (
    <div className="d-flex border-bottom align-items-center h-50px text-gray-700 text-uppercase ps-5 fw-bolder fs-7">
      {name}
    </div>
  );

  if (isLocked === true || !chapters) {
    return (
      <div className="">
        {header}
        <div className="d-flex flex-row align-items-center border-bottom h-70px ps-5">
          <div className="pe-3">
            <FontAwesomeIcon className="fa-xl text-gray-400" icon={faAngry} />
          </div>
          <div className="text-gray-700">This module is locked</div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column-fluid flex-center">
      <div className="flex-grow-1">
        {header}
        {chapters.map((c) => {
          return (
            <Chapter
              key={c.attributes.key}
              attributes={c.attributes}
              moduleKey={key}
              beginChapter={beginChapter}
              isFacilitator={isFacilitator}
              isActive={nextTodoChapter?.attributes.key === c.attributes.key}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Module;
