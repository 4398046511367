import {
  faClockRotateLeft,
  faHighlighter,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FsLightbox from "fslightbox-react";
import { useState } from "react";
import { CanvasPicPicker } from "../../../../app/types";
import reduxStore from "../../../../app/store";
import "./pic-picker.scss";
import { usePicPickerData } from "./usePicPickerData";
import CanvasControls from "../controls/CanvasControls";

const PicPicker = ({
  pickerKey,
  pics,
  correctName,
  isFacilitator,
  availableHeight,
  availableWidth,
}: CanvasPicPicker & {
  isFacilitator: boolean;
  availableHeight: number;
  availableWidth: number;
}) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const userId = reduxStore.getState().app.userId!;
  const { ppData, pickPic, clearPicks, isChecking, setIsCheckingRemote } =
    usePicPickerData(pickerKey, userId);

  const openLightboxOnSlide = (number: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  const slideUrls = pics.data.map((p) => p.attributes.url);
  const captions = pics.data.map((p) => p.attributes.caption);
  return (
    <div
      className="d-flex flex-center position-relative px-6"
      style={{
        height: `${availableHeight}px`,
        width: `${availableWidth}px`,
      }}
    >
      {isFacilitator && (
        <CanvasControls
          restartButtonAction={clearPicks}
          mainButtonAction={() => {
            setIsCheckingRemote(!isChecking);
          }}
          mainButtonText={isChecking ? "Stop Check" : "Check"}
          mainButtonIcon={isChecking ? undefined : faHighlighter}
        />
      )}
      <div className="d-flex flex-row flex-center ">
        {pics.data.map((p, i) => {
          const isCorrect = p.attributes.name === correctName;
          const votes =
            ppData && ppData[p.attributes.name]
              ? Object.values(ppData[p.attributes.name]).length
              : 0;
          const selected =
            ppData && ppData[p.attributes.name]
              ? ppData[p.attributes.name][userId] !== undefined
              : false;
          return (
            <div
              key={p.id}
              className={`border rounded m-5 p-5 ${
                isChecking &&
                (isCorrect ? "pic-picker-correct" : "pic-picker-incorrect")
              }`}
            >
              <div className="fs-5 fw-bold mb-3">{p.attributes.caption}</div>
              <div
                className="mb-4 mw-300px mh-200px overflow-hidden cursor-pointer bg-white"
                onClick={() => openLightboxOnSlide(i + 1)}
              >
                <img className="w-100" src={p.attributes.url} alt="" />
              </div>
              <div className="fs-5 d-flex flex-row flex-center justify-content-between">
                <div>
                  <span className="fw-bold">{votes}</span> VOTE
                  {votes !== 1 && "S"}
                </div>
                <button
                  onClick={() => pickPic(p.attributes.name)}
                  className={`btn p-0 w-35px d-flex flex-center cursor-pointer h-35px border rounded-circle pic-picker-upvote-btn ${
                    selected && "pic-picker-upvote-btn-selected"
                  }`}
                >
                  <FontAwesomeIcon size="lg" icon={faThumbsUp} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={slideUrls}
        type="image"
        // @ts-ignore
        zoomIncrement={1.5}
        captions={captions}
        slide={lightboxController.slide}
      />
    </div>
  );
};

export default PicPicker;
