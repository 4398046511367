import { useCallback, useEffect } from "react";
import {
  selectHandbookWidthPct,
  selectNavbarHeight,
  setCanvasWidth,
  setContentHeight,
  setHandbookWidth,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

export const useCourseResize = () => {
  const handbookWidthPct = useAppSelector(selectHandbookWidthPct);
  const navbarHeight = useAppSelector(selectNavbarHeight);
  const dispatch = useAppDispatch();

  const handleResize = useCallback(() => {
    const handbookWidth = Math.floor(window.innerWidth * handbookWidthPct);
    dispatch(setHandbookWidth(handbookWidth));
    dispatch(setCanvasWidth(window.innerWidth - handbookWidth - 23));
    dispatch(setContentHeight(window.innerHeight - navbarHeight));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handbookWidthPct, navbarHeight]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
};
