import { pgSalesBanner } from "../assets";

const Banner = () => {
  return (
    <img
      className="login-banner"
      src={pgSalesBanner}
      alt="Logo"
    />
  );
};

export default Banner;
