type Props = {
  source: any;
  availableWidth: number;
  availableHeight: number;
};

const Html = ({ source, ...props }: Props) => {
  return (
    <div
      style={{
        maxWidth: props.availableWidth * 0.7,
        maxHeight: props.availableHeight * 0.8,
      }}
      dangerouslySetInnerHTML={{ __html: source }}
    ></div>
  );
};

export default Html;
