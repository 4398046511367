import { useEffect } from "react";

import { selectTextScale, setTextScale } from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const SCALE_KEY = "text-scale";

const scaleToClass: Record<string, string[]> = {
  "-2": ["fs-8 hfs-8"],
  "-1": ["fs-7 hfs-7"],
  0: [""],
  1: ["fs-6 hfs-6"],
  2: ["fs-5 hfs-5"],
  3: ["fs-4 hfs-4"],
  4: ["fs-3 hfs-3"],
};

export const useScaledTextClasses = () => {
  const textScale = useAppSelector(selectTextScale);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const prevScale = window.localStorage.getItem(SCALE_KEY);
    if (prevScale) {
      dispatch(setTextScale(parseInt(prevScale)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUpscaleable = textScale < 4;
  const isDownscaleable = textScale > -2;

  const upscale = () => {
    if (isUpscaleable) {
      window.localStorage.setItem(SCALE_KEY, String(textScale + 1));
      dispatch(setTextScale(textScale + 1));
    }
  };

  const downscale = () => {
    if (isDownscaleable) {
      window.localStorage.setItem(SCALE_KEY, String(textScale - 1));
      dispatch(setTextScale(textScale - 1));
    }
  };

  return {
    classes: scaleToClass[textScale].join(" "),
    upscale,
    downscale,
    isUpscaleable,
    isDownscaleable,
    textScale,
  };
};
