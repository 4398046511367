import Konva from "konva";
import * as Firebase from "firebase/database";

import { FirebaseStore } from "../../../../../db/FirebaseStore";

import { Piece } from "./Piece";
import { PieceManager } from "./PieceManager";
import { SPPiece } from "./SPPiece";
import { Vector2 } from "./Vector2";
import { onValue } from "firebase/database";
import { PuzzleManager } from "./PuzzleManager";

export class SPPieceManager extends PieceManager {
  solvedAtDb: Firebase.DatabaseReference;

  restartedAtDb: Firebase.DatabaseReference;

  puzzleManager: PuzzleManager;

  constructor({
    puzzleKey,
    piecesData,
    layer,
    activeLayer,
    puzzleManager,
  }: any) {
    super();
    this.layer = layer;
    this.puzzleManager = puzzleManager;

    this.solvedAtDb = Firebase.child(
      FirebaseStore.getPuzzleRef(puzzleKey),
      "solvedAt"
    );

    this.restartedAtDb = Firebase.child(
      FirebaseStore.getPuzzleRef(puzzleKey),
      "restartedAt"
    );

    onValue(this.solvedAtDb, (snap) => {
      const val = snap.val();
      if (val > Date.now() - 5000) {
        this.puzzleManager.slotManager.solvePuzzle();
      }
    });

    onValue(this.restartedAtDb, (snap) => {
      const val = snap.val();
      if (val > Date.now() - 5000) {
        Object.values(this.pieces).forEach((p: SPPiece) => {
          p.component.position({
            x: this.initialPieces[p.key].x,
            y: this.initialPieces[p.key].y,
          });
        });
      }
    });

    this.pieces = piecesData?.reduce(
      (pcs: Record<string, Piece>, p: Record<string, any>) => {
        pcs[p.key] = new SPPiece({
          pieceData: p,
          layer,
          activeLayer,
          puzzleManager,
        });
        return pcs;
      },
      {}
    );

    this.initialPieces = piecesData?.reduce((acc: any, piece: any) => {
      acc[piece.key] = {
        x: piece.x,
        y: piece.y,
        key: piece.key,
        zIndex: 1000,
        isDragging: false,
      };
      return acc;
    }, {} as Record<string, any>);
  }

  resetPositions() {
    Firebase.set(this.restartedAtDb, Date.now());
  }

  cleanUp() {
    if (this.pieces) {
      Object.values(this.pieces).forEach((p) => p.remove());
    }
  }
}
