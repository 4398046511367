import {
  faClockRotateLeft,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { selectUserId } from "../../../../app/appSlice";
import { useAppSelector } from "../../../../app/hooks";
import { CanvasTables, TablesRow } from "../../../../app/types";
import "./tables.scss";
import { useVotingData } from "./useVotingData";

type Props = CanvasTables & {
  isFacilitator: boolean;
  availableWidth: number;
  availableHeight: number;
};

const Table = ({
  data,
  mouseOverRow,
  activeRow,
  rowVoting,
  vote,
  votingData,
  userId,
}: {
  data: TablesRow[];
  mouseOverRow: any;
  activeRow: any;
  rowVoting: boolean;
  vote: any;
  votingData: any;
  userId: string;
}) => {
  return (
    <table className="tables-table">
      <tbody>
        {data.map((row, i) => {
          // const isCorrect = p.attributes.name === correctName;
          const votes =
            votingData && votingData[row.id]
              ? Object.values(votingData[row.id]).length
              : 0;
          const selected =
            votingData && votingData[row.id]
              ? votingData[row.id][userId] !== undefined
              : false;

          return (
            <tr
              key={i}
              className={`${activeRow === row.id && "tables-table-row-active"}`}
              onMouseOver={rowVoting ? undefined : mouseOverRow(row.id)}
            >
              {row.cells.map((cell, j) => (
                <td key={j} className={`tables-table-cell ${cell.class}`}>
                  {cell.value}
                </td>
              ))}

              {rowVoting &&
                (i === 0 ? (
                  <td className="tables-table-cell tables-table-header border-end">
                    Votes
                  </td>
                ) : (
                  <td>
                    <div className="fs-5 pt-1 px-3 d-flex flex-row flex-center">
                        <span className="fw-bold w-20px">{votes}</span>
                      <button
                        onClick={() => vote(row.id)}
                        className={`btn p-0 w-25px d-flex flex-center cursor-pointer h-25px border rounded-circle pic-picker-upvote-btn ${
                          selected && "pic-picker-upvote-btn-selected"
                        }`}
                      >
                        <FontAwesomeIcon icon={faThumbsUp} />
                      </button>
                    </div>
                  </td>
                ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Tables = ({
  data,
  rowVoting,
  tablesKey = "TEST",
  isFacilitator,
  availableHeight,
  availableWidth,
}: Props) => {
  const [activeRow, setActiveRow] = useState<string>("");
  const userId = useAppSelector(selectUserId)!;

  const { vote, votingData, clearVotes } = useVotingData(tablesKey, userId);

  const mouseOverRow = (id: string) => () => {
    if (!id) {
      return;
    }
    setActiveRow(id);
  };
  return (
    <div
      className="d-flex flex-center position-relative"
      style={{
        height: `${availableHeight}px`,
        width: `${availableWidth}px`,
      }}
    >
      {isFacilitator && rowVoting && (
        <div
          className="tables-ui d-flex flex-row"
          style={{
            opacity: window.innerHeight < 700 ? 0.3 : 1,
          }}
        >
          <button
            className="btn btn-sm btn-tables"
            onClick={() => {
              clearVotes();
            }}
          >
            <FontAwesomeIcon size="lg" icon={faClockRotateLeft} />
            <span className="ms-1">Restart</span>
          </button>
        </div>
      )}
      <div className="d-flex flex-row flex-center">
        {data.map((table, i) => {
          return (
            <div className="me-4" key={i}>
              <Table
                data={table}
                rowVoting={rowVoting}
                vote={vote}
                votingData={votingData}
                mouseOverRow={mouseOverRow}
                activeRow={activeRow}
                userId={userId}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tables;
