import { useMemo } from "react";
import { ChapterAttributes, Page } from "../../app/types";

const inactiveColor = "#dad3c3"; // gray-300

export const useProgressCrumbs = (
  chapter: ChapterAttributes | undefined,
  pages: Page[] | undefined,
  pageKey: string | undefined
) => {
  return useMemo(() => {
    if (!chapter || !pageKey || !pages) {
      return null;
    }
    const color = chapter.color;
    const res = pages.map((p) => ({
      color: p.attributes.key === pageKey ? color : inactiveColor,
      name: p.attributes.name,
    }));
    return res;
  }, [chapter, pageKey, pages]);
};
