import { useAppSelector } from "../../app/hooks";
import { selectPage } from "../services/courseSlice";
import Header from "./page/Header";
import { Page } from "./page/Page";

const Handbook = ({
  chapterName,
  setPrevPage,
  setNextPage,
  setPrevStep,
  setNextStep,
  setStep,
  pageTransition,
  progressCrumbs,
  chapterColor,
  backToMenu,
  resetProgress,
}: any) => {
  const { page, isFirstPage, isLastPage } = useAppSelector(selectPage);

  if (!page) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Header
        title={chapterName}
        subtitle={page.name}
        color={chapterColor}
        progressCrumbs={progressCrumbs}
        setPrevPage={setPrevPage}
        setNextPage={setNextPage}
        isLastPage={isLastPage}
        isFirstPage={isFirstPage}
        backToMenu={backToMenu}
        resetProgress={resetProgress}
      />
      <Page
        key={page.key}
        attributes={page}
        isLastPage={isLastPage}
        isFirstPage={isFirstPage}
        pageTransition={pageTransition}
        setPrevStep={setPrevStep}
        setPrevPage={setPrevPage}
        setNextStep={setNextStep}
        setNextPage={setNextPage}
        setStep={setStep}
      ></Page>
    </>
  );
};

export default Handbook;
