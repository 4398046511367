import * as Firebase from "@firebase/database";

import { FirebaseStore } from "../../../../../db/FirebaseStore";
import { StickiesManager } from "./StickiesManager";
import { Sticky } from "./Sticky";
import { Vector2 } from "./Vector2";

type StickyData = {
  key: string;
  x: number;
  y: number;
};

const spawnOffset = { x: 70, y: 40 };

const generatePosition = (cp: Vector2, stickies: Sticky[]): Vector2 => {
  for (let s of stickies) {
    const p = s.component.position();
    if (cp.nearlyEquals(new Vector2(p.x, p.y), spawnOffset.x)) {
      return generatePosition(
        new Vector2(cp.x + spawnOffset.x, cp.y + spawnOffset.y),
        stickies
      );
    }
  }
  return cp;
};

export class StickyManager {
  database: Firebase.DatabaseReference;

  stickies: Record<string, Sticky>;

  stickiesManager: StickiesManager;

  openEditNoteModal: any;

  constructor({
    stickiesKey,
    layer,
    activeLayer,
    stickiesManager,
    openEditNoteModal,
  }: any) {
    this.database = Firebase.child(
      FirebaseStore.getPuzzleRef(stickiesKey),
      "pieces"
    );
    this.stickiesManager = stickiesManager;
    this.openEditNoteModal = openEditNoteModal;
    this.stickies = {};
    this.initializeListener();
  }

  resetPositions() {
    Firebase.remove(this.database);
  }

  deleteNote(ref: Firebase.DatabaseReference) {
    Firebase.remove(ref);
  }

  addLike({ database, userId }: any) {
    Firebase.set(Firebase.child(database, `likes/${userId}`), userId);
  }

  removeLike({ database, userId }: any) {
    console.log("addingLike");
    Firebase.remove(Firebase.child(database, `likes/${userId}`));
  }

  addNewNote({ text, color }: any) {
    const newNoteRef = Firebase.push(this.database);

    const np = generatePosition(
      new Vector2(200, 200),
      Object.values(this.stickies)
    );

    Firebase.set(newNoteRef, {
      x: np.x,
      y: np.y,
      text,
      color,
      owner: this.stickiesManager.userId,
      key: newNoteRef.key,
    });
  }

  editNote({ key, text, color }: any) {
    Firebase.update(Firebase.child(this.database, key), {
      text,
      color,
    });
  }

  private initializeListener() {
    Firebase.onValue(this.database, (snapshot) => {
      let data = snapshot.val();
      if (!data) {
        data = {};
      }

      const stks = Object.values(data) as StickyData[];
      stks.forEach((s) => {
        if (!s.key) {
          return;
        }
        if (this.stickies[s.key]) {
          this.stickies[s.key]?.update(s);
          return;
        }
        this.stickies[s.key] = new Sticky({
          stickyData: s,
          stickyManager: this,
          database: Firebase.child(this.database, s.key),
        });
      });

      try {
        Object.values(this.stickies).forEach((s) => {
          if (!data[s.key]) {
            s.remove();
            delete this.stickies[s.key];
          }
        });
      } catch (e) {}
    });
  }

  cleanUp() {
    Firebase.off(this.database);
    if (this.stickies) {
      Object.values(this.stickies).forEach((s) => s.remove());
    }
  }
}
