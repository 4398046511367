import Konva from "konva";

import { BackgroundManger } from "./BackgroundManager";
import { CheckManager } from "./CheckManager";
import { CursorManager } from "./CursorManager";
import { FlagManager } from "./FlagManager";
import { MPPieceManager } from "./MPPieceManager";
import { PieceManager } from "./PieceManager";
import { SlotManager } from "./SlotManager";
import { SPPieceManager } from "./SPPieceManager";

export class PuzzleManager {
  pieceManager: PieceManager;

  cursorManager: CursorManager | undefined;

  backgroundManager: BackgroundManger;

  slotManager: SlotManager;

  checkManager: CheckManager;

  flagManager: FlagManager | undefined;

  stage: Konva.Stage;

  backgroundLayer: Konva.Layer;

  slotsLayer: Konva.Layer;

  piecesLayer: Konva.Layer;

  activeLayer: Konva.Layer;

  cursorLayer: Konva.Layer;

  isSinglePlayer: boolean = false;

  constructor({ puzzleKey, piecesData, slotsData, background, config }: any) {
    this.stage = new Konva.Stage({
      container: puzzleKey,
      width: 1440,
      height: 1024,
    });

    this.backgroundLayer = new Konva.Layer();
    this.slotsLayer = new Konva.Layer();
    this.piecesLayer = new Konva.Layer();
    this.activeLayer = new Konva.Layer();
    this.cursorLayer = new Konva.Layer();

    this.backgroundLayer.listening(false);
    this.slotsLayer.listening(false);
    this.activeLayer.listening(false);
    this.cursorLayer.listening(false);

    this.stage.add(this.backgroundLayer);
    this.stage.add(this.slotsLayer);
    this.stage.add(this.piecesLayer);
    this.stage.add(this.activeLayer);
    this.stage.add(this.cursorLayer);

    this.backgroundManager = new BackgroundManger({
      layer: this.backgroundLayer,
      background,
    });

    this.slotManager = new SlotManager({
      layer: this.slotsLayer,
      slotsData,
      puzzleManager: this,
    });

    this.isSinglePlayer = config.isSinglePlayer || false;
    if (config.isSinglePlayer) {
      this.pieceManager = new SPPieceManager({
        puzzleKey,
        piecesData,
        layer: this.piecesLayer,
        activeLayer: this.activeLayer,
        puzzleManager: this,
      });
    } else {
      this.cursorManager = new CursorManager({
        layer: this.cursorLayer,
        puzzleKey,
        puzzleManager: this,
      });
      this.pieceManager = new MPPieceManager({
        puzzleKey,
        piecesData,
        layer: this.piecesLayer,
        activeLayer: this.activeLayer,
        puzzleManager: this,
      });
    }

    this.checkManager = new CheckManager({
      slotManager: this.slotManager,
      pieceManager: this.pieceManager,
      checkStrategy: config.checkStrategy,
      setIsChecking: config.setIsChecking,
      puzzleKey,
    });

    if (config.isFlaggzle) {
      this.flagManager = new FlagManager({
        puzzleKey,
        layer: this.piecesLayer,
        backgroundLayer: this.backgroundLayer,
      });
    }
    // this.piecesLayer.draw();
  }

  cleanUp() {
    try {
      this.pieceManager.cleanUp();
      this.cursorManager?.cleanUp();
      this.flagManager?.cleanUp();
      this.checkManager.cleanUp();
    } catch (e) {}
  }
}
