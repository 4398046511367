import { FormattedMessage } from "react-intl";
import { appName } from "../../app/configuration";

const Title = () => {
  return (
    <div className="align-self-start d-flex text-black flex-column flex-center mb-5">
      <FormattedMessage id="Login.Title" tagName="h1" values={{ appName }} />
      <div className="align-self-start text-uppercase text-gray-500 fw-bold fs-6">
        Virtual Experience
      </div>
    </div>
  );
};

export default Title;
