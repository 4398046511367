import Konva from "konva";

import { useEffect, useState } from "react";
import { useSceneTransform } from "./services/useSceneTransform";
import { PuzzleManager } from "./components/PuzzleManager";
import "./puzzle.scss";
import {
  faHighlighter,
} from "@fortawesome/free-solid-svg-icons";
import { MatchingKeysStrategy } from "./components/check-strategy/MatchingKeysStrategy";
import { CheckStrategy } from "./components/check-strategy/CheckStrategy";
import { IsCorrectStrategy } from "./components/check-strategy/IsCorrectStrategy";
import { SessionType } from "../../../../app/types";
import CanvasControls from "../controls/CanvasControls";

const CheckStrategyResolver: Record<string, CheckStrategy> = {
  MatchingKeys: new MatchingKeysStrategy(),
};

const Flagzzle = ({
  background,
  puzzleKey,
  pieces: piecesData,
  slots: slotsData,
  availableWidth,
  availableHeight,
  matchingStrategy,
  isFacilitator,
  sessionType,
}: any) => {
  const [puzzleManager, setPuzzleManager] = useState<PuzzleManager | null>(
    null
  );
  const [width, height, scale, offsetX, offsetY] = useSceneTransform(
    availableWidth,
    availableHeight
  );
  const [isChecking, setIsChecking] = useState(false);
  const isSinglePlayer = sessionType === SessionType.Conference;

  useEffect(() => {
    if (puzzleManager) {
      puzzleManager.stage.scaleX(scale);
      puzzleManager.stage.scaleY(scale);
      puzzleManager.stage.width(width);
      puzzleManager.stage.height(height);
      puzzleManager.stage.offsetX(offsetX);
      puzzleManager.stage.offsetY(offsetY);
    }
  }, [width, height, scale, offsetX, offsetY, puzzleManager]);

  useEffect(() => {
    console.log("UseEffect []");
    var puzzleManager = new PuzzleManager({
      puzzleKey,
      background: background.data.attributes,
      piecesData,
      slotsData,
      config: {
        isSinglePlayer: isSinglePlayer,
        showCheckButton: isFacilitator,
        checkStrategy: new IsCorrectStrategy(),
        setIsChecking,
        isFlaggzle: true,
      },
    });
    setPuzzleManager(puzzleManager);

    return () => {
      puzzleManager.cleanUp();
    };
    // stage.piecesLayer.draw()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="puzzle-container">
      {isFacilitator && (
        <CanvasControls
          restartButtonAction={() => {
            puzzleManager?.flagManager?.resetPositions();
          }}
          mainButtonAction={() => {
            puzzleManager?.checkManager.setCheck(!isChecking);
          }}
          mainButtonText={isChecking ? "Stop Check" : "Check"}
          mainButtonIcon={isChecking ? undefined : faHighlighter}
        />
      )}
      <div id={puzzleKey}></div>
    </div>
  );
};

export default Flagzzle;
