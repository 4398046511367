import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const PASSWORD = "1manageAOS";

export const PasswordModal = ({ isOpen, setIsOpen }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [incorrectInput, setIncorrectInput] = useState(false);
  const [input, setInput] = useState("");

  return (
    <Modal show={isOpen}>
      <form autoComplete="off">
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="Management.PasswordModal.Title" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage id="Management.PasswordModal.Message" />

          <div className="d-flex flex-column mt-5">
            {/* <label className="form-label fs-7 fw-bolder text-dark">
                <FormattedMessage id="General.Password" />
              </label> */}
            <input
              className={
                "form-control login-form-inline form-control-solid me-3 fluid text-center"
              }
              type="password"
              autoComplete="off"
              onChange={(e) => {
                setIncorrectInput(false);
                setInput(e.currentTarget.value);
              }}
            />
            <div className="text-danger fs-8 pt-2 w-100 h-20px">
              {incorrectInput && (
                <span role="alert">
                  {
                    <FormattedMessage id="Management.PasswordModal.IncorrectPassword" />
                  }
                </span>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Link to="/">
            <button
              type="button"
              className="btn btn-sm fs-7 btn-secondary align-self-end py-2 fw-bolder"
            >
              <FormattedMessage id="Management.PasswordModal.BackToLobby" />
            </button>
          </Link>
          <button
            type="submit"
            className="btn btn-sm fs-7 btn-primary align-self-end py-2 fw-bolder"
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                if (input === PASSWORD) {
                  setIsLoading(false);
                  setIsOpen(false);
                } else {
                  setIncorrectInput(true);
                  setIsLoading(false);
                }
              }, 500);
            }}
          >
            {isLoading ? (
              <span className="indicator-progress" style={{ display: "block" }}>
                <FormattedMessage id="General.Loading" />
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <FormattedMessage id="General.Continue" />
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
