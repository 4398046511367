export enum Stage {
  Local = 'local',
  Development = 'development',
  Production = 'production',
}

export const resolveStage = (): Stage => {
  if (process.env.REACT_APP_ENV) {
    return process.env.REACT_APP_ENV as Stage;
  }
  return Stage.Local;
};


export const isLocal = () => {
  return resolveStage() === Stage.Local;
};

export const isDevelopment = () => {
  return resolveStage() === Stage.Development;
};

export const isProduction = () => {
  return resolveStage() === Stage.Production;
};
