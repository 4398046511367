import React from "react";

type Props = {
  icon: string;
  title: string;
  length: number;
  style: any;
};

const Title = React.forwardRef(
  ({ style, icon, title, length }: Props, ref: any) => {
    return (
      <div
        ref={ref}
        style={style}
        className="d-flex flex-row align-items-center section"
      >
        <h2>{title}</h2>
        <h3 className="text-gray-600 ms-3 fw-normal">{`- ${length} min`}</h3>
      </div>
    );
  }
);

export default Title;
