import { useEffect, useState } from "react";
import Konva from "konva";

export enum Dimensions {
  WIDTH = 1440,
  HEIGHT = 1024,
  SCALE = 1,
}

export const useSceneTransform = (
  availableWidth: number,
  availableHeight: number
) => {
  const [width, setWidth] = useState(Dimensions.WIDTH);
  const [height, setHeight] = useState(Dimensions.HEIGHT);
  const [scale, setScale] = useState(Dimensions.SCALE);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const ox = (availableWidth - Dimensions.WIDTH * scale) / 2;
    const oy = (availableHeight - Dimensions.HEIGHT * scale) / 2;
    setOffsetX(-ox);
    setOffsetY(-oy);
    Konva.pixelRatio = window.devicePixelRatio / scale;
  }, [availableWidth, availableHeight, scale]);

  useEffect(() => {
    const widthRatio = availableWidth / Dimensions.WIDTH;
    const heightRatio = availableHeight / Dimensions.HEIGHT;

    setWidth(availableWidth);
    setHeight(availableHeight);
    if (widthRatio < heightRatio) {
      if (widthRatio < 1) {
        setScale(widthRatio);
      } else {
        // setWidth(Dimensions.WIDTH);
        // setHeight(Dimensions.HEIGHT);
        setScale(Dimensions.SCALE);
      }
    } else {
      if (heightRatio < 1) {
        // setWidth(availableWidth);
        // setHeight(availableHeight);
        setScale(heightRatio);
      } else {
        // setWidth(Dimensions.WIDTH);
        // setHeight(Dimensions.HEIGHT);
        setScale(Dimensions.SCALE);
      }
    }
  }, [availableWidth, availableHeight]);

  return [width, height, scale, offsetX, offsetY];
};
