import * as Firebase from "firebase/database";

import Konva from "konva";
import { Vector2 } from "./Vector2";
import reduxStore from "../../../../../app/store";
import { IFrame } from "konva/lib/types";
import { Dimensions } from "../services/useSceneTransform";
import { Piece } from "./Piece";

const updateRate = 100;
const velocity = 70;
const positionBuffer = 10;
const wiggleAngle = 3;
const wiggleSpeed = 3;

export class MPPiece extends Piece {
  draggingUser: String | null = null;

  positions: Vector2[] = [];

  lastUpdatedPosition = Vector2.zero();

  moveAnimation: Konva.Animation | undefined;

  wiggleDirection = wiggleSpeed;

  database: Firebase.DatabaseReference;

  lastSynced = Date.now();

  userId: string | null;

  get isOwned() {
    return this.userId && this.userId === this.draggingUser;
  }

  constructor(props: any) {
    super(props);

    this.database = props.database;
    this.userId = reduxStore.getState().app.userId;

    const pieceContext = this;
    this.moveAnimation = new Konva.Animation(
      this.move.bind(pieceContext) as any,
      this.layer
    );
  }

  updateRemote(data: Record<string, any>) {
    if (!this.database) {
      return;
    }

    if (data?.x) {
      if (data?.x < 0) {
        data.x = 1;
      }
      if (data?.x > Dimensions.WIDTH) {
        data.x = Dimensions.WIDTH;
      }
    }
    if (data?.y) {
      if (data?.y < 0) {
        data.y = 1;
      }
      if (data?.y > Dimensions.HEIGHT) {
        data.y = Dimensions.HEIGHT;
      }
    }

    Firebase.update(this.database, data);
  }

  update(data: Record<string, any>) {
    if (this.isOwned && data.isDragging !== this.userId) {
      this.dropPiece();
    }
    this.draggingUser = data.isDragging;

    if (this.isOwned) {
      return;
    }
    const pos = new Vector2(data.x, data.y);
    if (pos.equals(this.lastUpdatedPosition)) {
      return;
    }
    // console.log("Adding position, is owned?", this.isOwned);

    if (this.positions.length >= positionBuffer) {
      this.positions.shift();
    }
    this.component.shadowEnabled(false);
    this.lastUpdatedPosition = pos;
    this.positions.push(pos);
    this.component.draggable(false);
    if (data.zIndex) {
      this.component.zIndex(data.zIndex);
    }
    this.moveAnimation?.start();
  }

  onMouseEnter() {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "grab";
    }
  }

  onMouseLeave() {
    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "default";
    }
  }

  onDragStart(e: any) {
    // console.log(e);
    // console.log("onDragStart");

    // console.log("Drag start, user:", this.draggingUser, this.positions);
    if (this.draggingUser) {
      return;
    }

    if (this.positions.length > 0) {
      return;
    }

    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "grabbing";
    }

    this.lastUpdatedPosition = Vector2.zero();
    this.component.shadowEnabled(true);
    this.component.moveTo(this.activeLayer);
    this.wiggleAnimation.start();
    this.positions = [];
    this.updateRemote({ isDragging: this.userId || false, zIndex: Date.now() });
  }

  onDragMove(e: any) {
    // console.log(e);
    // console.log("onDragMove");
    // e.evt.preventDefault();
    if (!this.isOwned) {
      return;
    }
    const p = e.target.position();

    this.puzzleManager.cursorManager?.onUserCursorMove();
    this.puzzleManager.slotManager.getNearestSlot(p);

    if (Date.now() - this.lastSynced > updateRate) {
      this.lastSynced = Date.now();
      this.updateRemote({ x: Math.floor(p.x), y: Math.floor(p.y) });
    }
  }

  onDragEnd(e: any) {
    // console.log("onDragEnd");

    if (!this.isOwned) {
      return;
    }

    const stg = this.component.getStage();
    if (stg) {
      stg.container().style.cursor = "grab";
    }

    // console.log("Ending drag");
    this.dropPiece();
    let p = e.target.position();
    const slt = this.puzzleManager.slotManager.getNearestSlot(p);

    if (slt) {
      p = slt.component.position();
      this.puzzleManager.slotManager.resetFilledSlot();
    }

    p = this.puzzleManager.pieceManager.findDropPosition(this.key, p);

    this.updateRemote({
      isDragging: false,
      x: Math.floor(p.x),
      y: Math.floor(p.y),
      zIndex: Date.now(),
    });
  }

  dropPiece() {
    this.component.moveTo(this.layer);
    this.wiggleAnimation.stop();
    this.component.rotation(0);
    this.component.shadowEnabled(false);
  }

  move(frame: IFrame) {
    // this.animation?.stop();
    // console.log("Animating");
    // var dist = velocity * (frame!.timeDiff / 1000);
    // this.component.move({ x: dist, y: 0 });
    // console.log(this.positions);
    if (this.isOwned) {
      // console.log("Item is owned");
      this.moveAnimation!.stop();
    }
    if (this.positions.length === 0) {
      // console.log(this);
      // console.log("No more positions to iterate");
      this.moveAnimation!.stop();
      this.component.draggable(true);
      return;
    }
    const newPosition = this.positions[0];
    const currentPosition = new Vector2(this.component.x(), this.component.y());
    if (newPosition.nearlyEquals(currentPosition, 2)) {
      // console.log("Reached destination");
      this.positions.shift();
      return;
    }

    const totalDistance = this.positions.reduce((td, p, i) => {
      if (i === 0) {
        if (this.positions.length < 2) {
          return p.distance(currentPosition);
        }
        return td;
      }
      if (i === 1) {
        return td + p.distance(currentPosition);
      }
      return td + p.distance(this.positions[i - 1]);
    }, 0);
    const distanceVector = newPosition.subtract(currentPosition);
    const distance = distanceVector.magnitude();

    let multiplier = velocity * Math.max(2, (totalDistance * 2.5) / velocity);
    // }
    const movement = distanceVector
      .normalize()
      .scale(multiplier * (frame!.timeDiff / 1000));
    const mmag = movement.magnitude();
    if (distance <= mmag) {
      this.component.move({ x: distanceVector.x, y: distanceVector.y });
      return;
    }
    // console.log(movement);
    this.component.move({ x: movement.x, y: movement.y });
  }

  wiggle(frame: IFrame) {
    const r = this.component.rotation();
    if (r > wiggleAngle) {
      this.wiggleDirection = -wiggleSpeed;
    } else if (r < -wiggleAngle) {
      this.wiggleDirection = wiggleSpeed;
    }
    this.component.rotate(this.wiggleDirection * (frame!.timeDiff / 1000));
  }

  remove() {
    try {
      this.positions = [];
      this.moveAnimation?.stop();
      this.moveAnimation?.func(undefined);
      this.component.remove();
    } catch (e) {}
  }
}
