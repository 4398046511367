import { marked } from "marked";
import DOMPurify from "dompurify";
import React from "react";

type Props = {
  text: string;
  style: any;
};

const Text = React.forwardRef(({ text, style }: Props, ref: any) => {
  const parsedText = DOMPurify.sanitize(marked(text), { ADD_ATTR: ["target"] });

  return (
    <div
      ref={ref}
      style={style}
      className="d-flex flex-row align-items-center section"
    >
      <div dangerouslySetInnerHTML={{ __html: parsedText }}></div>
    </div>
  );
});

export default Text;
