import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { FlagManager } from "./FlagManager";

export class Flag {
  component: Konva.Group;

  layer: Konva.Layer;

  flagManager: FlagManager;

  constructor({ x, y, key, color, flagManager }: any) {
    const isOwned = flagManager.userId === key;

    this.component = this.createFlag(x, y, isOwned, color);

    this.layer = flagManager.layer;

    this.flagManager = flagManager;

    this.layer.add(this.component);
  }

  dispose() {
    this.component.destroy();
  }

  onLabelMouseOver(e: KonvaEventObject<MouseEvent>) {
    const container = e.target.getStage()?.container();
    e.currentTarget.scaleX(1.2);
    e.currentTarget.scaleY(1.2);
    e.currentTarget.offsetY(3);
    if (container) {
      container.style.cursor = "pointer";
    }
  }

  onLabelMouseOut(e: KonvaEventObject<MouseEvent>) {
    const container = e.target.getStage()?.container();
    e.currentTarget.scaleX(1);
    e.currentTarget.scaleY(1);
    e.currentTarget.offsetY(0);
    if (container) {
      container.style.cursor = "default";
    }
  }

  onLabelClick(e: KonvaEventObject<MouseEvent>) {
    e.cancelBubble = true;
    const container = e.target.getStage()?.container();
    if (container) {
      container.style.cursor = "default";
    }
    this.flagManager.destroyFlag();
  }

  private createFlag(x: number, y: number, isOwned: boolean, color: string) {
    const g = new Konva.Group({ x, y, offsetY: 83 });
    const crcl = new Konva.Circle({
      x: 2.5,
      y: 82,
      radius: 7,
      fill: "#F2EFE8",
      scaleY: 0.7,
      stroke: "#C6C6C6",
    });
    const rect = new Konva.Rect({
      x: 0,
      y: 0,
      width: 45,
      height: 28,
      fill: color,
      cornerRadius: [6, 0, 0, 0],
    });

    g.add(crcl);
    g.add(rect);

    if (isOwned) {
      const l = new Konva.Label({
        x: 42,
        y: 0,
      });
      l.on("mouseover", this.onLabelMouseOver);
      l.on("mouseout", this.onLabelMouseOut);
      l.on("click", this.onLabelClick.bind(this));
      const rc1 = new Konva.Rect({
        x: 0,
        y: 0,
        width: 2,
        height: 28,
        fill: "#fdfcfa",
        cornerRadius: [0, 6, 6, 0],
      });
      const rc2 = new Konva.Rect({
        x: 2,
        width: 25,
        height: 28,
        fill: color,
        cornerRadius: [0, 6, 6, 0],
      });
      const t = new Konva.Text({
        text: "x",
        fill: "white",
        fontSize: 16,
        x: 10,
        fontStyle: "bold",
        verticalAlign: "middle",
        height: 28,
      });
      l.add(rc1);
      l.add(rc2);
      l.add(t);
      g.add(l);
    } else {
      const r = new Konva.Rect({
        x: 45,
        width: 25,
        height: 28,
        fill: color,
        cornerRadius: [0, 6, 6, 0],
      });
      g.add(r);
    }

    const rect2 = new Konva.Rect({
      x: 0,
      y: 18,
      width: 5,
      height: 65,
      fill: color,
      cornerRadius: 6,
    });

    g.add(rect2);

    return g;
  }
}
