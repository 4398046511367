import Header from "./MenuHeader";
import Module from "./Module";

import { Module as ModuleType } from "../../../app/types";
import { useAppSelector } from "../../../app/hooks";
import { selectHandbookContentHeight } from "../../../app/appSlice";
import { useScaledTextClasses } from "../../services/useScaledTextClasses";
import { selectActiveKeys } from "../../services/courseSlice";
import { useEffect, useRef } from "react";
import { appName } from "../../../app/configuration";

type Props = {
  modules: ModuleType[];
  beginChapter: (moduleKey: string, chapterKey: string) => void;
  isFacilitator: boolean;
  resetProgress?: any;
};

const Menu = ({
  modules,
  beginChapter,
  isFacilitator,
  resetProgress,
}: Props) => {
  const handbookContentHeight = useAppSelector(selectHandbookContentHeight);
  const { moduleKey } = useAppSelector(selectActiveKeys);
  const { classes } = useScaledTextClasses();
  const activeModuleRef = useRef<any>();

  useEffect(() => {
    if (!activeModuleRef.current) {
      return;
    }
    activeModuleRef.current.scrollIntoView({
      block: "start",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModuleRef.current]);

  return (
    <>
      <Header
        title={appName}
        subtitle="Table of Contents"
        isFacilitator={isFacilitator}
        resetProgress={resetProgress}
      />
      <div
        className={`sidebar ${classes}`}
        style={{ maxHeight: `${handbookContentHeight}px`, overflow: "auto" }}
      >
        {modules.map((m) => {
          return (
            <div
              key={m.id}
              ref={m.attributes.key === moduleKey ? activeModuleRef : undefined}
            >
              <Module
                attributes={m.attributes}
                beginChapter={beginChapter}
              ></Module>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Menu;
