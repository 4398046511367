import { useEffect, useLayoutEffect, useState } from "react";

const margin = { x: 50, y: 60 };

type Dimensions = {
  width: number;
  height: number;
};

export const useTransform = (
  availableWidth: number,
  availableHeight: number,
  tableRef: any
) => {
  const [scale, setScale] = useState(1);
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  useLayoutEffect(() => {
    if (!tableRef.current) {
      return;
    }

    setDimensions({
      width: tableRef.current.scrollWidth,
      height: tableRef.current.scrollHeight,
    });
  }, [tableRef]);

  useEffect(() => {
    if (!dimensions) {
      return;
    }
    const widthRatio = (availableWidth - margin.x) / dimensions.width;
    const heightRatio = (availableHeight - margin.y) / dimensions.height;

    // console.log({
    //   widthRatio,
    //   heightRatio,
    //   dimensions,
    //   availableWidth,
    //   availableHeight,
    // });
    if (widthRatio < heightRatio) {
      if (widthRatio < 1) {
        setScale(widthRatio);
      } else {
        // setScale(1);
      }
    } else {
      if (heightRatio < 1) {
        setScale(heightRatio);
      } else {
        // setScale(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableHeight, availableWidth, dimensions]);

  return { scale };
};
