import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faUsers,
  faTextHeight,
  faCirclePlus,
  faCircleMinus,
  faCaretRight,
  faCrosshairs,
  faChevronDown,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import pgLogo from "./pgLogo.svg";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  selectAvailableModules,
  selectIsFacilitator,
  selectName,
  selectSessionName,
  selectType,
  selectUserId,
  setNavbarHeight,
} from "../../app/appSlice";
import { useScaledTextClasses } from "../services/useScaledTextClasses";
import { FormattedMessage } from "react-intl";

import "./navbar.scss";
import { selectLocale } from "../../intl/intlSlice";
import { useActiveParticipants } from "../services/useActiveParticipants";
import { SessionType } from "../../app/types";
import { useConfirmationModal } from "../confirmation-modal/useConfirmationModal";
import { ResourceFile, RESOURCE_FILES } from "./resources";
import {
  appName,
  courseUrl,
  getActivityUrl,
  zetaUrl,
} from "../../app/configuration";
import { useActivity } from "../../services/useActivity";
import React from "react";

const Navbar = ({
  isHighlighterOn,
  toggleHighlighter,
  isHighlighterEnabled,
}: any) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const sessionName = useAppSelector(selectSessionName);
  const { textScale, upscale, downscale, isUpscaleable, isDownscaleable } =
    useScaledTextClasses();
  const [_locale, localeData] = useAppSelector(selectLocale);
  const { activeParticipants } = useActiveParticipants();
  const isFacilitator = useAppSelector(selectIsFacilitator);
  const sessionType = useAppSelector(selectType);
  const userName = useAppSelector(selectName);
  const userId = useAppSelector(selectUserId);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { confirm } = useConfirmationModal();
  const availableModules = useAppSelector(selectAvailableModules);
  const [resourceFiles, setResourceFiles] = useState<ResourceFile[]>([]);
  const activity = useActivity();

  useEffect(() => {
    setResourceFiles(
      RESOURCE_FILES.filter(
        (f) =>
          f.url.length > 0 &&
          (f.needsModule.length === 0 ||
            f.needsModule.find((am) => availableModules.includes(am))) &&
          (!f.needsFacilitator ||
            (f.needsFacilitator &&
              isFacilitator &&
              sessionType !== SessionType.SelfLed))
      )
    );
  }, [availableModules, isFacilitator, sessionType]);

  const isSelfLed = sessionType === SessionType.SelfLed;

  useLayoutEffect(() => {
    const boundingRect = ref.current?.getBoundingClientRect();
    boundingRect && dispatch(setNavbarHeight(boundingRect.height));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const textScalePct = `${100 + textScale * 25}%`;
  return (
    <div
      ref={ref}
      className="d-flex navbar-bar flex-row w-100 h-40px min-h-40px justify-content-between"
    >
      {/* Left Part  */}
      <div className="d-flex flex-center">
        {/* Logo */}
        <div className="ps-2 pe-4">
          <a href={zetaUrl}>
            <img
              alt="Logo"
              src={pgLogo}
              width="25"
              height="25"
              draggable={false}
            />
          </a>
        </div>
        <div
          className="me-auto d-flex flex-center "
          style={{ color: "#000000ad" }}
        >
          {/* Training Title */}
          <div className=" fs-6 ">
            <a href={courseUrl}>
              <FormattedMessage id="General.ProjectName" values={{ appName }} />
            </a>
          </div>
          {/* Slash */}
          {/* <div className="ps-2 pe-2 fs-5">{"/"}</div> */}
          <div
            className="ps-4 pe-4 fs-5"
            style={{
              color: "gray",
            }}
          >
            <FontAwesomeIcon className="fa-sm" icon={faCaretRight} />
          </div>

          {/* Session Name  */}
          <div className=" fs-6 fw-bold pe-5">
            {/* <FontAwesomeIcon className=" pe-2" icon={faPersonChalkboard} /> */}
            <a href={activity.data ? getActivityUrl(activity.data) : courseUrl}>
              {sessionName}
            </a>
            {activity.data?.status === "completed" && " (Completed)"}
          </div>
        </div>
      </div>

      {/* Middle Part */}
      <div className="d-flex flex-center">
        <Dropdown align="start">
          <Dropdown.Toggle
            type="button"
            className="btn-navbar text-white py-1 px-3 ps-4 me-3 d-flex flex-center"
          >
            <FontAwesomeIcon className="pe-2" icon={faFileArrowDown} />
            <span>Resources</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold"
            style={{
              width: "255px",
            }}
          >
            <div className="menu-item px-3">
              <div className="menu-content fs-7 text-dark fw-bolder px-3 py-4 user-select-none">
                Downloadable Resources
              </div>
            </div>
            <div className="separator mb-3 opacity-75"></div>
            {resourceFiles.map((file) => (
              <div
                key={file.name}
                className="menu-item px-3 d-flex flex-row flex-center"
              >
                <a
                  href={file.url}
                  target="_blank"
                  rel="noreferrer"
                  download={!!file.url}
                  className="my-menu-link fs-7 user-select-none"
                >
                  <FontAwesomeIcon className="fa-lg me-2" icon={file.icon} />
                  {file.name}
                </a>
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {/* Right Part */}
      <div className="d-flex flex-center pe-5">
        {/* Toggle Highlighter */}
        {isFacilitator && !isSelfLed && (
          <OverlayTrigger
            placement={"bottom"}
            overlay={
              <Tooltip id={`tooltip-bottom-pointer`} className="tooltip-dark">
                {isHighlighterEnabled
                  ? "Laser Pointer"
                  : "Not available on this page"}
              </Tooltip>
            }
          >
            <div>
              <button
                className={`btn btn-navbar me-3 btn-active-light-secondary  py-1 px-1  ps-3 d-flex flex-center ${
                  isHighlighterOn && "highlighter-active"
                }`}
                disabled={!isHighlighterEnabled}
                onClick={toggleHighlighter}
              >
                <FontAwesomeIcon className=" pe-2" icon={faCrosshairs} />
                <div className="pe-2 fw-bolder">
                  {isHighlighterOn ? "On" : "Off"}
                </div>
              </button>
            </div>
          </OverlayTrigger>
        )}

        {/* Active Participants */}
        {!isSelfLed && (
          <Dropdown align="end">
            <Dropdown.Toggle
              type="button"
              className="btn btn-navbar me-3 btn-secondary btn-active-light-secondary text-white py-1 px-1  ps-3 d-flex flex-center"
            >
              {/* <div className="ring-container me-6">
              <div className="ringring border-danger bg-danger"></div>
              <div className="circle bg-danger"></div>
            </div> */}
              <FontAwesomeIcon className=" pe-2" icon={faUsers} />
              <div className="pe-2 fw-bolder">{activeParticipants.length}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px">
              <div className="menu-item px-3">
                <div className="menu-content fs-7 text-dark fw-bolder px-3 py-4 user-select-none">
                  Active Participants
                </div>
              </div>
              <div className="separator mb-3 opacity-75"></div>
              <div className="px-3 mh-250px overflow-scroll">
                <div className="menu-item d-flex flex-column flex-center user-select-none">
                  {activeParticipants.map((ap: any) => {
                    const isActive = ap.id === userId;
                    return (
                      <div
                        key={ap.id}
                        style={{ width: "100%", cursor: "default" }}
                        className={`my-menu-link mb-2 fs-7 ${
                          isActive ? "active" : ""
                        }`}
                      >
                        {ap.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {/* Text Size */}
        <Dropdown align="end">
          <Dropdown.Toggle
            type="button"
            className="btn btn-navbar btn-secondary text-white py-1 px-1 ps-4 me-3 d-flex flex-center"
          >
            <FontAwesomeIcon className=" pe-2" icon={faTextHeight} />
            <div className="pe-2">{textScalePct}</div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px">
            <div className="menu-item px-3">
              <div className="menu-content fs-7 text-dark fw-bolder px-3 py-4 user-select-none">
                Text size
              </div>
            </div>
            <div className="separator mb-3 opacity-75"></div>
            <div className="menu-item px-3 d-flex flex-row flex-center">
              <button
                className="btn btn-light-primary text-white p-3 w-50px"
                onClick={downscale}
                disabled={!isDownscaleable}
              >
                <FontAwesomeIcon size="lg" icon={faCircleMinus} />
              </button>
              <div className="fs-7 fw-bolder px-3 flex-grow-1 d-flex flex-center user-select-none">
                {textScalePct}
              </div>
              <button
                className="btn btn-light-primary text-white p-3 w-50px"
                // style={{ color: "#5e92f3" }}
                onClick={upscale}
                disabled={!isUpscaleable}
              >
                <FontAwesomeIcon size="lg" icon={faCirclePlus} />
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        {/* Locale */}
        <Dropdown align="end">
          <Dropdown.Toggle
            type="button"
            className="btn-navbar text-white py-1 px-1 ps-4 me-3 d-flex flex-center"
          >
            <FontAwesomeIcon className=" pe-2" icon={faGlobe} />
            <div className="pe-2">
              <img
                height={11}
                className="flag-icon"
                src={localeData.flag}
                alt=""
              />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px">
            <div className="menu-item px-3">
              <div className="menu-content fs-7 text-dark fw-bolder px-3 py-4 user-select-none">
                Language
              </div>
            </div>
            <div className="separator mb-3 opacity-75"></div>
            <div className="menu-item px-3 d-flex flex-row flex-center">
              <div className="my-menu-link active fs-7 user-select-none">
                <img
                  height={15}
                  className="pe-3"
                  src={localeData.flag}
                  alt=""
                />
                {localeData.name}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        {/* Profile */}
        <Dropdown
          align="end"
          onToggle={(show) => {
            setIsMenuOpen(show);
          }}
        >
          <Dropdown.Toggle
            type="button"
            className={`btn-navbar-logout rotate py-1 px-2 me-3 d-flex flew-row flex-center ${
              isMenuOpen ? "active" : ""
            }`}
          >
            <div className="me-2">{userName}</div>
            <span className="rotate-180">
              <FontAwesomeIcon size="xs" icon={faChevronDown} />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px">
            <div className="menu-item px-3">
              <div className="menu-content fs-7 text-dark fw-bolder px-3 py-4 user-select-none">
                Menu
              </div>
            </div>
            <div className="separator mb-3 opacity-75"></div>
            <div className="menu-item px-3 d-flex flex-row flex-center">
              <div
                className="my-menu-link fs-7 user-select-none"
                onClick={() => {
                  confirm("Course.ConfirmationModal.Message.Exit").then(
                    (val) => {
                      if (val) {
                        window.location.href = activity.data
                          ? getActivityUrl(activity.data)
                          : courseUrl;
                      }
                    }
                  );
                }}
              >
                Exit Session
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default React.memo(Navbar);
