import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const CopyButton = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  return (
    <div
      onClick={() => {
        navigator.clipboard.writeText(text).then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1000);
        });
      }}
      title="Copy to Clipboard"
      className="btn btn-sm btn-light-primary p-2 rounded h-30px w-35px d-flex flex-center"
    >
      <FontAwesomeIcon
        icon={isCopied ? faCheck : faCopy}
        size="lg"
        className="text-white"
      />
    </div>
  );
};

export default CopyButton;
