import {
  faCaretUp,
  faCheck,
  faClockRotateLeft,
  faHighlighter,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./canvas-controls.scss";

type Props = {
  restartButtonAction?: () => void;
  mainButtonIcon?: IconDefinition;
  mainButtonText: string;
  mainButtonAction: () => void;
  solveButtonAction?: () => void;
};

const CanvasControls = ({
  restartButtonAction,
  mainButtonIcon,
  mainButtonAction,
  mainButtonText,
  solveButtonAction,
}: Props) => {
  return (
    <div className="controls-ui d-flex flex-column">
      <div className="controls-ui-tag">
        <FontAwesomeIcon bounce className="controls-arrows mx-5" icon={faCaretUp} />
        <span>Controls</span>
        <FontAwesomeIcon bounce className="controls-arrows mx-5" icon={faCaretUp} />
      </div>
      <div className="d-flex flex-row flex-center h-100 mx-3">
        {restartButtonAction && (
          <button
            className="btn btn-sm btn-canvas-controls"
            onClick={restartButtonAction}
          >
            <FontAwesomeIcon className="" size="lg" icon={faClockRotateLeft} />
            <span>Restart</span>
          </button>
        )}
        <button
          className="btn btn-sm btn-canvas-controls btn-canvas-controls-primary"
          onClick={mainButtonAction}
        >
          {mainButtonIcon && (
            <FontAwesomeIcon className="" size="lg" icon={mainButtonIcon} />
          )}
          <span>{mainButtonText}</span>
        </button>
        {solveButtonAction && (
          <button
            className="btn btn-sm btn-canvas-controls"
            onClick={solveButtonAction}
          >
            <FontAwesomeIcon size="lg" icon={faCheck} />
            <span>Solve</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default CanvasControls;
