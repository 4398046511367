export const COLORS = [
  "#42069D",
  "#CC0018",
  "#CC0062",
  "#CC00AB",
  "#A300CC",
  "#7200CC",
  "#5A00CC",
  "#1000CC",
  "#0052CC",
  "#0083CC",
  "#00C0CC",
  "#00CC9B",
  "#00CC5E",
  "#29CC00",
  "#A3CC00",
  "#CCB800",
  "#CC9300",
  "#CC7A00",
  "#CC6200",
  "#8A8A8A",
  "#575757",
];

export const USER_COLOR = COLORS[Math.floor(Math.random() * COLORS.length)];
