import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { setHandbookContentHeight } from "../../../app/appSlice";
import { Dropdown } from "react-bootstrap";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUpdateActivityMutation } from "../../../app/zetaApi";
import { useActivity } from "../../../services/useActivity";
import { useDatabase } from "../../../db/useDatabase";
import { onValue, set } from "firebase/database";
import { getActivityUrl } from "../../../app/configuration";
import { useConfirmationModal } from "../../confirmation-modal/useConfirmationModal";

type Props = {
  title: string;
  subtitle: string;
  page?: number;
  totalPages?: number;
  goBack?: () => void;
  chaptersCrumbs?: { color: string; name: string }[];
  color?: string;
  isFacilitator?: boolean;
  resetProgress?: any;
};

const Header = ({
  title,
  subtitle,
  color,
  isFacilitator,
  resetProgress,
}: Props) => {
  const dispatch = useAppDispatch();
  const headerRef = useRef<any>();
  const [updateActivity] = useUpdateActivityMutation();
  const activity = useActivity();
  const { confirm } = useConfirmationModal();

  const statusDb = useDatabase("status");

  useEffect(() => {
    if (!statusDb) {
      return;
    }

    return onValue(statusDb, (snap) => {
      const status = snap.val();
      if (!activity.data) {
        return;
      }
      if (status === null) {
        set(statusDb, activity.data.status);
        return;
      }
      if (
        status === "completed" &&
        (!isFacilitator || activity.data.status !== "completed")
      ) {
        window.location.href = getActivityUrl(activity.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDb]);

  const completeActivity = async () => {
    if (!activity.data) return;

    try {
      const res = await updateActivity({
        id: activity.data.id,
        status: "completed",
      }).unwrap();

      set(statusDb, res.status);
    } catch (e) {}
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      const boundingRect = headerRef.current?.getBoundingClientRect();
      boundingRect &&
        dispatch(
          setHandbookContentHeight(
            Math.floor(
              window.innerHeight - (boundingRect.y + boundingRect.height)
            )
          )
        );
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef]);

  return (
    <div ref={headerRef} className="d-flex flex-column sidebar-header">
      <div className="d-flex flex-column-fluid flex-center mt-2 ms-2 mb-2">
        <div className="flex-grow-1">
          <h4 className="fw-bolder">{title}</h4>
          <div className="fw-bold">{subtitle}</div>
        </div>
        {/* Right Section - Controls */}
        {isFacilitator && (
          <Dropdown align="start">
            <Dropdown.Toggle
              type="button"
              className="d-flex flex-center controls-toggle-btn btn-hover-scale"
            >
              <FontAwesomeIcon className="fa-xl" icon={faChevronCircleDown} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="min-w-200px">
              <div className="menu-item px-3 ">
                <div className="menu-content fs-7 text-dark fw-bolder px-3 py-4 user-select-none">
                  Activity Controls
                </div>
              </div>
              <div className="menu-item px-3">
                <button
                  className="my-menu-link fs-7 user-select-none"
                  onClick={() => {
                    confirm(
                      "Course.ConfirmationModal.Message.MarkAsCompleted"
                    ).then((val) => {
                      if (val) {
                        completeActivity();
                      }
                    });
                  }}
                >
                  Mark as Completed
                </button>
              </div>
              <div className="menu-item px-3">
                <button
                  className="my-menu-link fs-7 user-select-none"
                  onClick={resetProgress}
                >
                  Reset All Progress
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default Header;
