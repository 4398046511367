import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AnnotationData, MapData, ModuleData } from "./types";
import { cmsApiToken, cmsApiUrl } from "./configuration";

const getModulesParams = {
  "populate[13]": "chapters.annotationsUnlocked",
  "populate[14]": "canvas.map_data",
  "populate[15]": "canvas.annotations",
  "populate[10]": "chapters.canvas",
  "populate[11]": "chapters.canvas.map_data",
  "populate[12]": "chapters.canvas.annotations",
  "populate[0]": "chapters.pages.facilitatorGuide.image",
  "populate[1]": "chapters.pages.facilitatorGuide",
  "populate[2]": "chapters.pages.sections",
  "populate[3]": "chapters.pages.sections.image",
  "populate[4]": "chapters.pages.canvas",
  "populate[16]": "chapters.pages.canvas.pics",
  "populate[5]": "chapters.pages.canvas.background",
  "populate[6]": "chapters.pages.canvas.image",
  "populate[7]": "chapters.pages.canvas.annotations",
  "populate[8]": "chapters.pages.canvas.map_data",
  "populate[9]": "chapters.pages.canvas.tabs",
};

export const rmfApi = createApi({
  reducerPath: "rmfApi",
  baseQuery: fetchBaseQuery({
    baseUrl: cmsApiUrl,
    prepareHeaders: (headers) => {
      headers.set("authorization", `Bearer ${cmsApiToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getModules: builder.query<ModuleData, void>({
      query: () => {
        return {
          url: "modules",
          params: {
            ...getModulesParams,
            "filters[key][$notContains]": "-SL",
          },
        };
      },
    }),
    getSelfLedModules: builder.query<ModuleData, void>({
      query: () => {
        return {
          url: "modules",
          params: {
            ...getModulesParams,
            "filters[key][$endsWith]": "-SL",
          },
        };
      },
    }),
    getMapData: builder.query<MapData, number>({
      query: (mapDataId: number) => ({
        url: `map-datas/${mapDataId}`,
        params: {
          "populate[0]": "annotations",
          "populate[1]": "annotations.media",
          "populate[2]": "map",
        },
      }),
      transformResponse: (response: any) => {
        const annotations = response.data.attributes.annotations.reduce(
          (md: Record<string, AnnotationData>, a: AnnotationData) => {
            md[a.key] = a;
            return md;
          },
          {}
        );
        return {
          map: response.data.attributes.map,
          annotations,
        };
      },
    }),
  }),
});

export const {
  useGetModulesQuery,
  useGetMapDataQuery,
  useGetSelfLedModulesQuery,
} = rmfApi;
