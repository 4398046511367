import { Piece } from "../Piece";
import { Slot } from "../Slot";
import { CheckStrategy } from "./CheckStrategy";

export class AbsolutePositionStrategy implements CheckStrategy {
  checkSlot(slot: Slot): boolean | undefined {
    return undefined;
  }

  checkPiece(piece: Piece): boolean {
    const { x, y } = piece.component.position();

    if (!piece.data) {
      return false;
    }

    if (
      Array.isArray(piece.data.correctX) &&
      piece.data.correctX.length === 2
    ) {
      if (x < piece.data.correctX[0] || x > piece.data.correctX[1]) {
        return false;
      }
    }

    if (
      Array.isArray(piece.data.correctY) &&
      piece.data.correctY.length === 2
    ) {
      if (y < piece.data.correctY[0] || y > piece.data.correctY[1]) {
        return false;
      }
    }

    return true;
  }
}
