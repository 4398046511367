import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { rmfApi } from "../../app/rmfApi";

import { RootState } from "../../app/store";
import {
  AnnotationSelect,
  Chapter,
  ModuleData,
  SessionType,
} from "../../app/types";
import { selectType } from "../../app/appSlice";

type CourseActiveKeys = {
  moduleKey: string | undefined;
  chapterKey: string | undefined;
  pageKey: string | undefined;
  step: number;
};

type CourseState = {
  activeKeys: CourseActiveKeys;
  liveChapterData: Record<string, Partial<Chapter>>;
};

const initialState: CourseState = {
  activeKeys: {
    moduleKey: undefined,
    chapterKey: undefined,
    pageKey: undefined,
    step: 0,
  },
  liveChapterData: {},
};

export const courseSlice = createSlice({
  name: "course",
  initialState: initialState,
  reducers: {
    setActiveKeys: (
      state,
      action: PayloadAction<Partial<CourseActiveKeys>>
    ) => {
      state.activeKeys = {
        moduleKey: undefined,
        chapterKey: undefined,
        pageKey: undefined,
        step: 0,
        ...action.payload,
      };
    },
    setLiveChapterData: (
      state,
      action: PayloadAction<Record<string, Partial<Chapter>>>
    ) => {
      state.liveChapterData = action.payload;
    },
  },
});

const selectFetchedModulesData = rmfApi.endpoints.getModules.select();
const selectFetchedSelfLedModulesData =
  rmfApi.endpoints.getSelfLedModules.select();

const selectLiveChapterData = (state: RootState) =>
  state.course.liveChapterData;
const selectModuleKey = (state: RootState) => state.course.activeKeys.moduleKey;
const selectChapterKey = (state: RootState) =>
  state.course.activeKeys.chapterKey;
const selectPageKey = (state: RootState) => state.course.activeKeys.pageKey;
const selectStep = (state: RootState) => state.course.activeKeys.step;
const selectAvailableModules = (state: RootState) => state.app.modules;

export const { setActiveKeys, setLiveChapterData } = courseSlice.actions;
export const selectActiveKeys = (state: RootState): CourseActiveKeys =>
  state.course.activeKeys;

export const selectModules = createSelector(
  [
    selectType,
    selectFetchedModulesData,
    selectFetchedSelfLedModulesData,
    selectAvailableModules,
  ],
  (type, modulesData, selfLedModulesData, availableModules) => {
    if (type === null) {
      return;
    }
    const md = type === SessionType.SelfLed ? selfLedModulesData : modulesData;
    return md?.data?.data
      .slice()
      .sort((m, n) => m.attributes.order - n.attributes.order)
      .filter((m) =>
        availableModules ? availableModules.includes(m.attributes.key) : true
      );
  }
);

export const selectModule = createSelector(
  [selectModules, selectModuleKey],
  (modules, moduleKey) => {
    return modules?.find((m) => m.attributes.key === moduleKey);
  }
);

export const selectTocModules = createSelector(
  [selectModules, selectLiveChapterData],
  (modules, liveChapterData) => {
    // console.log("Recalculating TOC modules");
    return (
      modules &&
      modules
        .map((m) => ({
          ...m,
          attributes: {
            ...m.attributes,
            chapters: {
              ...m.attributes.chapters,
              data: m.attributes.chapters.data
                .map(
                  (c) =>
                    ({
                      ...c,
                      attributes: {
                        ...c.attributes,
                        ...liveChapterData[c.attributes.key],
                        pages: {
                          data: [],
                        },
                      },
                    } as Chapter)
                )
                .sort((a, b) => a.attributes.order - b.attributes.order),
            },
          },
        }))
        .sort((a, b) => a.attributes.order - b.attributes.order)
    );
  }
);

export const selectChapters = createSelector(
  [selectModules, selectModuleKey],
  (modules, moduleKey) => {
    // console.log("Recalculating chapters");
    return modules
      ?.find((c) => c.attributes.key === moduleKey)
      ?.attributes.chapters.data.map((c) => ({
        ...c,
        attributes: {
          ...c.attributes,
        },
      }))
      .sort((c, d) => c.attributes.order - d.attributes.order);
  }
);

export const selectChapter = createSelector(
  [selectChapterKey, selectChapters],
  (chapterKey, chapters) => {
    // console.log("Recalculating chapter");
    return chapters?.find((c) => c.attributes.key === chapterKey)?.attributes;
  }
);

export const selectMenuMap = createSelector(
  [selectModules, selectModule, selectLiveChapterData],
  (modules, module, liveChapterData) => {
    if (!modules || !module || !liveChapterData) {
      return undefined;
    }

    const annotations: Record<string, AnnotationSelect> = {};
    modules?.forEach((m) => {
      m.attributes.chapters?.data.forEach((c) => {
        if ((liveChapterData[c.attributes.key] as any)?.isCompleted) {
          if (c.attributes.annotationsUnlocked) {
            c.attributes.annotationsUnlocked.forEach(
              (a) => a.annotation && (annotations[a.annotation] = a)
            );
          }
        }
      });
    });
    const map = module?.attributes.canvas[0];

    if (map) {
      return {
        ...map,
        annotations: Object.values(annotations),
      };
    } else {
      return undefined;
    }
  }
);

export const selectNextTodoChapter = createSelector(
  [selectTocModules, selectLiveChapterData],
  (tocModules, liveChapterData) => {
    // console.log("Recalculating last completed chapter");
    if (!tocModules || !liveChapterData) {
      return;
    }

    for (const m of tocModules) {
      for (const c of m.attributes.chapters.data) {
        if ((liveChapterData[c.attributes.key] as any)?.isCompleted === false) {
          return c;
        }
      }
    }
  }
);

export const selectPages = createSelector([selectChapter], (chapter) => {
  // console.log("Recalculating pages");
  return chapter?.pages.data
    .slice()
    .sort((a, b) => a.attributes.order - b.attributes.order);
});

export const selectPage = createSelector(
  [selectPageKey, selectPages],
  (pageKey, pages) => {
    // console.log("Recalculating page");
    if (!pages) {
      return {
        page: undefined,
        isLastPage: false,
        isFirstPage: false,
      };
    }
    const pi = pages?.findIndex((p) => p.attributes.key === pageKey);
    return {
      page: pages[pi]?.attributes,
      isLastPage: pi === pages.length - 1,
      isFirstPage: pi === 0,
    };
  }
);

export default courseSlice.reducer;
