import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./appSlice";
import intlReducer from "../intl/intlSlice";
import courseReducer from "../course/services/courseSlice";
import { rmfApi } from "./rmfApi";
import { zetaApi } from "./zetaApi";

const store = configureStore({
  reducer: {
    app: appReducer,
    intl: intlReducer,
    course: courseReducer,
    [rmfApi.reducerPath]: rmfApi.reducer,
    [zetaApi.reducerPath]: zetaApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rmfApi.middleware).concat(zetaApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
