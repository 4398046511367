import { FormattedMessage } from "react-intl";

import { Modal } from "react-bootstrap";
import { useConfirmationModal } from "./useConfirmationModal";
import {
  selectConfirmationModalMessage,
  selectIsConfirmationModalOpen,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/hooks";

export const ConfirmationModal = () => {
  const isOpen = useAppSelector(selectIsConfirmationModalOpen);
  const message = useAppSelector(selectConfirmationModalMessage);
  const { onConfirm, onCancel } = useConfirmationModal();

  return (
    <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="Course.ConfirmationModal.Title" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage
            id={message || "Course.ConfirmationModal.Message.Default"}
            defaultMessage="Are you sure?"
          />
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm fs-7 btn-secondary align-self-end py-2 fw-bolder"
            onClick={onCancel}
          >
            <FormattedMessage id="General.Cancel" />
          </button>
          <button
            className="btn btn-sm fs-7 btn-danger align-self-end py-2 fw-bolder"
            onClick={onConfirm}
          >
            <FormattedMessage id="General.Confirm" />
          </button>
        </Modal.Footer>
    </Modal>
  );
};
