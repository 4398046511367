import React from "react";
import { Image as BoostrapImage } from "react-bootstrap";

type ImageProps = {
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  url: string;
  name: string;
  size: number;
};

type Props = {
  image: {
    data: {
      id: string;
      attributes: ImageProps & {
        alternativeText: string;
        caption: string;
        formats: {
          small: ImageProps;
          medium: ImageProps;
          thumbnail: ImageProps;
        };
      };
    };
  };
  width: number;
  height: number;
  style: any;
};

const Image = React.forwardRef(
  ({ image, width, height, style }: Props, ref: any) => {
    return (
      <div ref={ref} className="d-flex flex-center section py-10" style={style}>
        <BoostrapImage
          style={{ maxWidth: "85%" }}
          width={width}
          height={height}
          draggable={false}
          src={image.data.attributes.url}
          alt={image.data.attributes.alternativeText}
        />
      </div>
    );
  }
);

export default Image;
