import { useEffect, useState } from "react";
import { animated, useTransition, config } from "react-spring";
import {
  selectCanvasWidth,
  selectContentHeight,
  selectIsFacilitator,
  selectType,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/hooks";
import { ENABLED_COMPONENTS } from "../services/useHighlighter";

import { type CanvasData } from "../../app/types";
import { selectActiveKeys } from "../services/courseSlice";
import { useScaledTextClasses } from "../services/useScaledTextClasses";
import Html from "./artworks/Html";
import Image from "./artworks/Image";
import Map from "./artworks/map/Map";
import Flagzzle from "./artworks/puzzle/Flagzzle";
import Puzzle from "./artworks/puzzle/Puzzle";
import Tabbed from "./artworks/Tabbed";
import Spreadsheet from "./artworks/spreadsheet/Spreadsheet";
import Stickies from "./artworks/stickies/Stickies";
import PicPicker from "./artworks/pic-picker/PicPicker";
import Tables from "./artworks/tables/Tables";

const artworkToComponent: Record<string, any> = {
  "artwork.puzzle": Puzzle,
  "artwork.image": Image,
  "artwork.map": Map,
  "artwork.tabbed": Tabbed,
  "artwork.html": Html,
  "artwork.flagzzle": Flagzzle,
  "artwork.spreadsheet": Spreadsheet,
  "artwork.stickies": Stickies,
  "artwork.pic-picker": PicPicker,
  "artwork.tables": Tables,
};

type Props = {
  data?: CanvasData | CanvasData[];
  isHighlighterOn: boolean;
  placeHighlight: any;
  highlights: any[];
  setIsHighlighterEnabled: any;
  setHighlights: any;
  setIsHighlighterOn: any;
  pageKey: any;
};

export const Canvas = ({
  data,
  isHighlighterOn,
  placeHighlight,
  highlights,
  setIsHighlighterEnabled,
  setHighlights,
  setIsHighlighterOn,
  pageKey,
}: Props) => {
  const canvasWidth = useAppSelector(selectCanvasWidth);
  const contentHeight = useAppSelector(selectContentHeight);
  const isFacilitator = useAppSelector(selectIsFacilitator);
  const sessionType = useAppSelector(selectType);
  const { classes } = useScaledTextClasses();
  const { step } = useAppSelector(selectActiveKeys);
  const [props, setProps] = useState<any>(null);
  const [lastComponent, setLastComponent] = useState<string>("");

  const transitions = useTransition(props, {
    enter: {
      opacity: 1,
    },
    delay: 100,
    // leave: {
    //   opacity: 1,
    //   transform: "translateX(50%)",
    // },
    from: { opacity: 0 },
    config: config.slow,
  });

  const Component = artworkToComponent[props?.__component];

  useEffect(() => {
    if (props?.__component && ENABLED_COMPONENTS.includes(props?.__component)) {
      setIsHighlighterEnabled(true);
    } else {
      setIsHighlighterEnabled(false);
    }
    setHighlights([]);
    // setIsHighlighterOn(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!data) {
      setProps(null);
      return;
    }
    if (!Array.isArray(data)) {
      setLastComponent(props?.__component);
      setProps(data);
      return;
    }
    let lowestEligibleStep = -1;
    let p;
    for (let i = 0; i < data.length; i++) {
      const c = data[i];
      if (c.step <= step && c.step > lowestEligibleStep) {
        lowestEligibleStep = c.step;
        p = c;
      }
    }
    if (p) {
      setLastComponent(props?.__component);
      setProps(p);
      return;
    }
    if (!p && data.length > 0 && data[0].step === null) {
      setLastComponent(props?.__component);
      setProps(data[0]);
    } else {
      setProps(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, step]);

  useEffect(() => {
    setLastComponent("");
  }, [pageKey]);

  const availableWidth =
    props?.__component === "artwork.map"
      ? canvasWidth
      : Math.min(canvasWidth, 1440);
  const availableHeight = contentHeight;
  // props?.__component === "artwork.map"
  //   ? contentHeight
  //   : Math.min(contentHeight, 1024);

  // const skipAnimation =
  //   props?.__component === "artwork.map" && lastComponent === "artwork.map";

  return (
    <div
      className={`d-flex flex-column-fluid flex-center course-canvas ${classes}`}
      style={{ maxHeight: contentHeight, overflow: "hidden" }}
    >
      {props ? (
        Component && (
          <Component
            {...props}
            sessionType={sessionType}
            isHighlighterOn={isHighlighterOn}
            placeHighlight={placeHighlight}
            highlights={highlights}
            availableWidth={availableWidth}
            availableHeight={availableHeight}
            isFacilitator={isFacilitator}
          />
        )
      ) : (
        <div
          style={{
            backgroundImage: 'url("/rmf-pattern-v2.png")',
            backgroundRepeat: "repeat",
            width: "100%",
            height: "100%",
            opacity: 0.05,
          }}
        />
      )}
    </div>
  );
};
