import Konva from "konva";
import { IFrame } from "konva/lib/types";

import { Vector2 } from "./Vector2";

const velocity = 70;
const positionBuffer = 10;

export class Cursor {
  key: string;

  component: Konva.Group;

  animation: Konva.Animation | undefined;

  positions: Vector2[] = [];

  lastUpdatedPosition = Vector2.zero();

  layer: Konva.Layer;

  constructor({ key, name, layer, color }: any) {
    this.key = key;
    this.component = this.createComponent(name, color);
    this.layer = layer;

    this.layer.add(this.component);

    const cursorContext = this;
    this.animation = new Konva.Animation(
      this.move.bind(cursorContext) as any,
      this.layer
    );
  }

  update(data: Record<string, any>) {
    const pos = new Vector2(data.x, data.y);
    if (pos.equals(this.lastUpdatedPosition)) {
      return;
    }

    if (this.positions.length >= positionBuffer) {
      this.positions.shift();
    }
    this.lastUpdatedPosition = pos;
    this.positions.push(pos);
    this.animation?.start();
  }

  move(frame: IFrame) {
    // this.animation?.stop();
    // console.log("Animating");
    // var dist = velocity * (frame!.timeDiff / 1000);
    // this.component.move({ x: dist, y: 0 });
    // console.log(this.positions);
    if (this.positions.length === 0) {
      // console.log(this);
      // console.log("No more positions to iterate");
      this.animation!.stop();
      this.component.draggable(true);
      return;
    }
    const newPosition = this.positions[0];
    const currentPosition = new Vector2(this.component.x(), this.component.y());
    if (newPosition.nearlyEquals(currentPosition, 2)) {
      // console.log("Reached destination");
      this.positions.shift();
      return;
    }

    const totalDistance = this.positions.reduce((td, p, i) => {
      if (i === 0) {
        if (this.positions.length < 2) {
          return p.distance(currentPosition);
        }
        return td;
      }
      if (i === 1) {
        return td + p.distance(currentPosition);
      }
      return td + p.distance(this.positions[i - 1]);
    }, 0);
    const distanceVector = newPosition.subtract(currentPosition);
    const distance = distanceVector.magnitude();

    // let multiplier = this.positions.length; // Math.max(1, Math.floor(distance / 100));

    // if (this.positions.length < 2) {
    //   multiplier = Math.max(1, Math.floor((distance / velocity)));
    // }

    // console.log(totalDistance);
    // let multiplier = totalDistance; // Math.max(1, Math.floor(distance / 100));
    // multiplier *= Math.max(0.1, (distance * this.positions.length) / velocity);
    // if (this.positions.length < 2) {
    let multiplier = velocity * Math.max(2, (totalDistance * 2.5) / velocity);
    // }
    const movement = distanceVector
      .normalize()
      .scale(multiplier * (frame!.timeDiff / 1000));
    const mmag = movement.magnitude();
    if (distance <= mmag) {
      this.component.move({ x: distanceVector.x, y: distanceVector.y });
      return;
    }
    // console.log(movement);
    this.component.move({ x: movement.x, y: movement.y });
  }

  remove() {
    try {
      this.component.remove();
    } catch (e) {}
  }

  private createComponent(name: string, color: string) {
    const g = new Konva.Group();
    const rp = new Konva.RegularPolygon({
      sides: 3,
      radius: 12,
      fill: color,
      rotation: 85,
    });
    const l = new Konva.Label({
      x: 7,
      y: 7,
    });
    const tag = new Konva.Tag({
      fill: color,
      cornerRadius: 25,
    });
    const text = new Konva.Text({
      text: name!,
      fill: "white",
      fontSize: 16,
      verticalAlign: "middle",
      height: 22,
      padding: 8,
      fontStyle: "bold",
    });
    l.add(tag);
    l.add(text);
    g.add(rp);
    g.add(l);

    return g;
  }
}
