import {
  child,
  DataSnapshot,
  onValue,
  set,
  update,
  remove,
} from "@firebase/database";
import { useEffect, useState } from "react";
import { useDatabase } from "../../../../db/useDatabase";

export const usePicPickerData = (pickerKey: string, userId: string) => {
  const [ppData, setPpData] = useState<Record<string, Record<string, string>>>(
    {}
  );
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const picksDb = useDatabase(`/picpickers/${pickerKey}/picks`);
  const isCheckingDb = useDatabase(`/picpickers/${pickerKey}/isChecking`);

  const pickPic = (picId: string) => {
    if (ppData && ppData[picId] && ppData[picId][userId]) {
      remove(child(picksDb, `${picId}/${userId}`));
    } else {
      Object.entries(ppData).forEach(([key, val]) => {
        if (val[userId]) {
          remove(child(picksDb, `${key}/${userId}`));
        }
      })
      update(child(picksDb, picId), { [userId]: userId });
    }
  };

  const clearPicks = () => {
    remove(picksDb);
  };

  const setIsCheckingRemote = (val: boolean) => {
    set(isCheckingDb, val);
  };

  useEffect(() => {
    if (!picksDb) {
      return;
    }
    return onValue(picksDb, (snap: DataSnapshot) => {
      const data = snap.val();
      if (!data) {
        setPpData({});
      } else {
        setPpData(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picksDb]);

  useEffect(() => {
    if (!isCheckingDb) {
      return;
    }
    return onValue(isCheckingDb, (snap: DataSnapshot) => {
      const data = snap.val();
      if (!data) {
        setIsChecking(false);
      } else {
        setIsChecking(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckingDb]);

  return { ppData, pickPic, clearPicks, isChecking, setIsCheckingRemote };
};
