import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";
import enUSMessages from "./assets/lang/en-US.json";
import enUSFlag from "./assets/img/en-US.png";

type LocaleData = {
  name: string;
  flag: string;
  messages: Record<string, any>;
};

const locales: Record<Locale, LocaleData> = {
  "en-US": {
    name: "English (United States)",
    flag: enUSFlag,
    messages: enUSMessages,
  },
};

type Locale = "en-US";

interface IntlState {
  locale: Locale;
  // messages: Record<string, any>;
}

const initialState: IntlState = {
  locale: "en-US",
};

export const intlSlice = createSlice({
  name: "intl",
  initialState: initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<Locale>) => {
      state.locale = action.payload;
    },
  },
});

export const { setLocale } = intlSlice.actions;
export const selectLocale = (state: RootState): [Locale, LocaleData] => [
  state.intl.locale,
  locales[state.intl.locale],
];

export default intlSlice.reducer;
