import {
  child,
  get,
  orderByChild,
  query,
  remove,
  update,
} from "firebase/database";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { format } from "date-fns";
import BackgroundGraphic from "./Management.png";

import "./management.scss";
import { useDatabase } from "../db/useDatabase";
import { NewSessionModal, SessionData } from "./NewSessionModal";
import { PasswordModal } from "./PasswordModal";
import { Pagination } from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeOpenText,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { SessionType } from "../app/types";
import { InvitationModal } from "./InvitationModal";

const PAGE_SIZE = 10;
const SESSION_EXPIRY = 30 * 24 * 60 * 60 * 1000; // 30 days

const sessionTypeToPrint: Record<string, string> = {
  [SessionType.Conference]: "Conference",
  [SessionType.FacilitatorLed]: "Team",
};

const isSessionData = (sd: any): sd is SessionData => {
  if (sd.sessionName && sd.sessionNumber && sd.sessionNumber.length <= 7) {
    return true;
  }
  return false;
};

const MODULE_TO_NAME: Record<string, string> = {
  M0: "Introduction",
  M1: "Strong Relationship Building",
  M2: "Memorable Selling",
  M3: "Earning Trust Daily",
};

const Management = () => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(true);
  const [isNewSessionModalOpen, setIsNewSessionModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);

  const [sessionToDelete, setSessionToDelete] = useState("");
  const [sessionToInvite, setSessionToInvite] = useState<SessionData>();

  const [filter, setFilter] = useState("");

  const [activePage, setActivePage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const sessionsDb = useDatabase("sessions", true);
  const [sessions, setSessions] = useState<SessionData[][]>([[]]);
  const [fetchedSessions, setFetchedSessions] = useState<SessionData[]>([]);

  const createSession = async (sd: SessionData) => {
    await update(sessionsDb, {
      [sd.sessionNumber]: sd,
    });
    getSessions();
  };

  useEffect(() => {
    getSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessions = () => {
    setIsLoading(true);
    setFilter("");
    get(query(sessionsDb, orderByChild("/createdAt"))).then((snap) => {
      if (!snap.hasChildren()) {
        setIsLoading(false);
        setSessions([[]]);
        return;
      }
      const ssns = [] as SessionData[];
      const sessionsToDelete = [] as string[];

      snap.forEach((s) => {
        const val = s.val();
        if (isSessionData(val)) {
          if (val.type !== SessionType.SelfLed) {
            ssns.unshift({
              sessionName: val.sessionName,
              sessionNumber: val.sessionNumber,
              createdAt: val.createdAt,
              facilitatorNumber: val.facilitatorNumber,
              authorName: val.authorName,
              isStarted: val.isStarted,
              modules: val.modules,
              type: val.type,
            });
          }
          if (val.createdAt < Date.now() - SESSION_EXPIRY) {
            sessionsToDelete.push(val.sessionNumber);
          }
        }
      });

      if (sessionsToDelete.length > 0) {
        Promise.all(
          sessionsToDelete.map((sn) => remove(child(sessionsDb, sn)))
        ).then(getSessions);
        return;
      }

      // setSessions(_.chunk(ssns, PAGE_SIZE));
      setFetchedSessions(ssns);
      // setSessions([[]]);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    setActivePage(0);
    if (!filter) {
      setSessions(_.chunk(fetchedSessions, PAGE_SIZE));
      return;
    }

    const filteredSessions = fetchedSessions.filter(
      (s) =>
        s.sessionName.toLowerCase().includes(filter) ||
        s.authorName.toLowerCase().includes(filter)
    );
    if (filteredSessions.length > 0) {
      setSessions(_.chunk(filteredSessions, PAGE_SIZE));
    } else {
      setSessions([[]]);
    }
  }, [filter, fetchedSessions]);

  if (isPasswordModalOpen) {
    return (
      <PasswordModal
        isOpen={isPasswordModalOpen}
        setIsOpen={setIsPasswordModalOpen}
      />
    );
  }

  const hasNoSessions =
    !sessions ||
    _.isEmpty(sessions) ||
    (sessions?.length === 1 && _.isEmpty(sessions[0]));

  return (
    <>
      {isNewSessionModalOpen && (
        <NewSessionModal
          isOpen={isNewSessionModalOpen}
          setIsOpen={setIsNewSessionModalOpen}
          createSession={createSession}
        />
      )}

      {/* Invitation Modal */}
      <InvitationModal
        show={isInvitationModalOpen}
        facilitatorNumber={sessionToInvite?.facilitatorNumber}
        sessionNumber={sessionToInvite?.sessionNumber}
        onHide={() => {
          setIsInvitationModalOpen(false);
        }}
      />

      {/* Delete Modal  */}
      <Modal
        show={isDeleteModalOpen}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the session{" "}
          <span className="fw-bold">{sessionToDelete}</span>?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            Close
          </button>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              if (!sessionToDelete) {
                setIsDeleteModalOpen(false);
                return;
              }
              remove(child(sessionsDb, sessionToDelete))
                .then(() => {})
                .finally(() => {
                  setIsDeleteModalOpen(false);
                  getSessions();
                });
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* Main Content  */}
      <div className="d-flex flex-column justify-content-start align-items-center w-100">
        <div
          className="d-flex flex-column my-8 min-h-200px w-900px position-relative"
          style={{
            background: "#FDFCFB",
          }}
        >
          <img
            className="img-fluid border rounded user-select-none"
            src={BackgroundGraphic}
            draggable={false}
            alt=""
          />
          <div className="position-absolute mt-10 ms-10">
            <div className="fs-2 fw-bolder text-white">
              Manage existing sessions or create a new
            </div>
            <div className="fs-2 fw-bolder text-white">
              Art of Selling session
            </div>
            <div className="d-flex flex-row">
              <button
                className="btn btn-sm fw-bold btn-management mt-3"
                onClick={() => {
                  setIsNewSessionModalOpen(true);
                }}
              >
                Create
              </button>
              <Link className="ms-3 mt-3" to={"/"}>
                <div
                  className="btn btn-sm fw-bold btn-secondary"
                  // onClick={() => {
                  //   setIsNewSessionModalOpen(true);
                  // }}
                >
                  Back To Lobby
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-column border rounded p-5 w-900px"
          style={{
            background: "#FDFCFB",
          }}
        >
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex flex-column">
              <div className="text-gray-800 fw-bold fs-5 mb-1">Sessions</div>
              {filter && <div>Showing search results for "{filter}"</div>}
            </div>
            <div className="d-flex flex-center">
              <div className="text-gray-600 fs-7 text-uppercase me-3">
                Search
              </div>
              <input
                className="form-control form-control-sm form-control-solid border fluid text-center"
                type="text"
                placeholder="Name or author..."
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value.toLowerCase());
                }}
              />
              {filter && (
                <span
                  className="ms-2 text-gray-500 cursor-pointer"
                  onClick={() => setFilter("")}
                >
                  <FontAwesomeIcon icon={faXmark} size="sm" />
                </span>
              )}
            </div>
          </div>
          {isLoading && (
            <div className="h-200px d-flex flex-center text-gray-500">
              <span className="spinner-border"></span>
            </div>
          )}

          {!isLoading && hasNoSessions && (
            <div className="h-200px d-flex flex-center text-gray-500">
              <span>No sessions found</span>
            </div>
          )}

          {!isLoading && !hasNoSessions && (
            <div className="table-responsive min-h-200px">
              <table className="table gy-2 gx-1 management-table table-row-dashed">
                <thead>
                  <tr className="fw-bold fs-7 text-gray-600">
                    <th>Participant #</th>
                    <th>Facilitator #</th>
                    <th>Session Name</th>
                    <th>Author</th>
                    <th>Size</th>
                    <th>Created At</th>
                    <th>Content</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions[activePage]?.map((s) => {
                    if (!s.createdAt) {
                      return null;
                    }
                    const formattedDate = format(new Date(s.createdAt), "PP");
                    return (
                      <tr key={s.sessionNumber} className="fw-bold">
                        <td className="fw-bolder">{s.sessionNumber}</td>
                        <td className="fw-bolder">{s.facilitatorNumber}</td>
                        <td>{s.sessionName}</td>
                        <td>{s.authorName}</td>
                        <td>{sessionTypeToPrint[s.type] || ""}</td>
                        {/* <td>{"Pending"}</td> */}
                        <td>{formattedDate}</td>
                        <td>
                          <div className="d-flex flex-column align-items-start">
                            {s?.modules?.map((s) => (
                              <span
                                key={s}
                                className="badge badge-light text-gray-600 mb-1"
                              >
                                {MODULE_TO_NAME[s]}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex flex-row">
                            <button
                              onClick={() => {
                                setIsDeleteModalOpen(true);
                                setSessionToDelete(s.sessionNumber);
                              }}
                              className="btn btn-sm btn-danger p-2 me-1 rounded w-35px"
                            >
                              <FontAwesomeIcon icon={faTrash} size="lg" />
                            </button>
                            <button
                              onClick={() => {
                                setIsInvitationModalOpen(true);
                                setSessionToInvite(s);
                              }}
                              className="btn btn-sm btn-light-primary p-2 rounded w-35px"
                            >
                              <FontAwesomeIcon
                                icon={faEnvelopeOpenText}
                                size="lg"
                                className="text-white"
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            activePage={activePage}
            setActivePage={setActivePage}
            numberOfPages={sessions.length}
          />
        </div>

        <div className="min-h-100px w-100"></div>
      </div>
    </>
  );
};

export default Management;
