import { Piece } from "../Piece";
import { Slot } from "../Slot";
import { CheckStrategy } from "./CheckStrategy";
import { Vector2 } from "../Vector2";

export class MatchingKeysStrategy implements CheckStrategy {
  checkSlot(slot: Slot, pieces: Record<string, Piece>): boolean {
    let mpcs = slot.data.matchingPieces;

    if (!mpcs) {
      return false;
    }
    if (!Array.isArray(mpcs)) {
      mpcs = [mpcs];
    }

    for (let i = 0; i < mpcs.length; i++) {
      const p = pieces[mpcs[i]];
      if (!p) {
        continue;
      }
      const pp = new Vector2(
        p.component.position().x,
        p.component.position().y
      );
      const sp = new Vector2(
        slot.component.position().x,
        slot.component.position().y
      );
      if (pp.distance(sp) < 5) {
        return true;
      }
    }
    return false;
  }

  checkPiece(piece: Piece): boolean | undefined {
    return undefined;
  }
}
