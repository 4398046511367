import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { StickyColor } from "./consts";

const NewNoteModal = ({
  text,
  color,
  isOpenNewModal,
  hideNewModal,
  editNote,
  addNewNote,
  editingKey,
}: any) => {
  const [activeText, setActiveText] = useState<string>(text);
  const [selectedColor, setSelectedColor] = useState<StickyColor>(color);

  useEffect(() => {
    setActiveText(text);
  }, [text]);


  useEffect(() => {
    setSelectedColor(color);
  }, [color]);

  const setColor = (color: StickyColor) => () => {
    setSelectedColor(color);
  };

  return (
    <div
      className="modal position-absolute flex-center"
      tabIndex={-1}
      style={{
        display: isOpenNewModal ? "flex" : "none",
        zIndex: 100,
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <FormattedMessage id="Course.Canvas.Stickies.NewNoteModal.Title" />
            </h5>
            <div className="d-flex flex-row">
              <div
                className={`stickies-color-picker stickies-green ${
                  selectedColor === StickyColor.Green &&
                  "stickies-color-picker-selected"
                }`}
                onClick={setColor(StickyColor.Green)}
              ></div>
              <div
                className={`stickies-color-picker stickies-blue ${
                  selectedColor === StickyColor.Blue &&
                  "stickies-color-picker-selected"
                }`}
                onClick={setColor(StickyColor.Blue)}
              ></div>
              <div
                className={`stickies-color-picker stickies-violet ${
                  selectedColor === StickyColor.Violet &&
                  "stickies-color-picker-selected"
                }`}
                onClick={setColor(StickyColor.Violet)}
              ></div>
              <div
                className={`stickies-color-picker stickies-orange ${
                  selectedColor === StickyColor.Orange &&
                  "stickies-color-picker-selected"
                }`}
                onClick={setColor(StickyColor.Orange)}
              ></div>
            </div>
          </div>

          <div
            className="modal-body"
            style={{
              minWidth: "300px",
              background: selectedColor,
            }}
          >
            <textarea
              onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (e.target.value.length > 150) {
                  return;
                }
                setActiveText(e.target.value);
              }}
              value={activeText ?? ""}
              style={{
                backgroundColor: "transparent",
                resize: "none",
                width: "100%",
                border: "none",
                outline: "none",
                minHeight: "7rem",
              }}
            ></textarea>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm fs-7 btn-secondary align-self-end py-2 fw-bolder"
              onClick={() => {
                setActiveText("");
                hideNewModal();
              }}
            >
              <FormattedMessage id="General.Cancel" />
            </button>
            <button
              type="button"
              disabled={
                !activeText ||
                activeText.length === 0 ||
                activeText.length > 150
              }
              className="btn btn-sm fs-7 btn-primary align-self-end py-2 fw-bolder"
              onClick={() => {
                if (editingKey) {
                  editNote(editingKey, activeText, selectedColor);
                } else {
                  addNewNote(activeText, selectedColor);
                }
                setActiveText("");
                hideNewModal();
              }}
            >
              <FormattedMessage id="General.Save" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewNoteModal;
