import { useCallback, useRef } from "react";
import { Media } from "../../../app/types";
import Highlight from "../../../assets/img/highlight.svg";

type Props = {
  image: Media;
  width: number;
  height: number;
  availableWidth: number;
  availableHeight: number;
  isHighlighterOn: any;
  placeHighlight: any;
  highlights: any;
};

const Image = ({
  image,
  availableHeight,
  availableWidth,
  height,
  width,
  isHighlighterOn,
  placeHighlight,
  highlights,
}: Props) => {
  const url = image.data.attributes.url;
  const highlightContainer = useRef<any>();

  const onHighlightCapture = useCallback((event: any) => {
    if (!highlightContainer.current) {
      return;
    }
    const element = highlightContainer.current?.getBoundingClientRect();
    const pos = {
      x: event.clientX - element.left,
      y: event.clientY - element.top,
    };

    const posPct = {
      x: pos.x / element.width,
      y: pos.y / element.height,
    };

    event.stopPropagation();
    event.preventDefault();

    placeHighlight(posPct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const highlighterContainerRect =
    highlightContainer.current?.getBoundingClientRect();

  return (
    <div
      ref={highlightContainer}
      style={{
        position: "relative",
      }}
    >
      {isHighlighterOn && (
        <div
          onClickCapture={onHighlightCapture}
          className="highlighter-capture-container"
        ></div>
      )}
      {highlighterContainerRect &&
        highlights.map((h: any) => {
          return (
            // <div
            //   key={h.timestamp}
            //   className="highlight-effect"
            //   style={{
            //     position: "absolute",
            //     left: h?.x * highlighterContainerRect.width - 75,
            //     top: h?.y * highlighterContainerRect.height - 75,
            //   }}
            // />
            <img
              draggable={false}
              key={h.timestamp}
              alt="Highlight"
              src={Highlight}
              className="highlight-effect-img"
              style={{
                position: "absolute",
                width: "100px",
                height: "100px",
                left: h?.x * highlighterContainerRect.width - 50,
                top: h?.y * highlighterContainerRect.height - 50,
              }}
            />
          );
        })}
      <img
        src={url}
        alt=""
        width={width > 0 ? availableWidth * width : undefined}
        height={height > 0 ? availableHeight * height : undefined}
        draggable={false}
        style={{
          maxWidth: availableWidth,
          maxHeight: availableHeight,
          padding: "1rem",
          objectFit: "contain",
          // width: "auto",
          // height: "auto",
        }}
      />
    </div>
  );
};

export default Image;
