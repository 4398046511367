import { child, get, onValue, remove, set } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { selectName, selectUserId } from "../../app/appSlice";
import { useAppSelector } from "../../app/hooks";
import { useDatabase } from "../../db/useDatabase";

const heartbeatInterval = 60 * 1000; // 60 sec

export const useActiveParticipants = () => {
  const [aps, setAps] = useState<Record<string, Record<string, any>>>({});
  const [heartbeatTimer, setHeartbeatTimer] = useState<number | null>(null);
  const activeParticipantsDb = useDatabase("activeParticipants");
  const userId = useAppSelector(selectUserId);
  const name = useAppSelector(selectName);
  const sessionDb = useDatabase("/");
  const apsRef = useRef<any>();
  const activeParticipantsDbRef = useRef<any>();
  apsRef.current = aps;
  activeParticipantsDbRef.current = activeParticipantsDb;

  const removeUser = () => {
    if (apsRef.current && activeParticipantsDbRef.current) {
      Object.entries(apsRef.current).forEach(([key, ap]: any) => {
        if (!ap?.id || Date.now() - ap?.heartbeat > 2 * heartbeatInterval) {
          remove(child(activeParticipantsDbRef.current, key));
        }
      });
    }

    if (!userId || !heartbeatTimer) {
      return;
    }

    remove(child(activeParticipantsDb, userId));
    window.clearInterval(heartbeatTimer);
  };

  useEffect(() => {
    if (!activeParticipantsDb || !userId) {
      return;
    }

    const sendHeartbeat = () => {
      get(sessionDb).then((snap) => {
        const sessionData = snap.val();
        if (sessionData?.sessionNumber) {
          set(child(activeParticipantsDb, userId), {
            id: userId,
            name,
            heartbeat: Date.now(),
          });
        } else {
          removeUser();
        }
      });
    };

    if (!heartbeatTimer) {
      const ht = window.setInterval(sendHeartbeat, heartbeatInterval);
      setHeartbeatTimer(ht);
      sendHeartbeat();
    }

    const unsub = onValue(activeParticipantsDb, (snap) => {
      const val = snap.val();
      if (val === null) {
        setAps({});
      }
      if (!val) {
        return;
      }
      setAps(val);
    });

    const cleanUp = () => {
      unsub();
      removeUser();
      window.removeEventListener("beforeunload", cleanUp);
    };

    window.addEventListener("beforeunload", cleanUp);
    return cleanUp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeParticipantsDb, userId]);

  return {
    activeParticipants: Object.values(aps).filter(
      (ap: any) => Date.now() - ap.heartbeat < 1.5 * heartbeatInterval
    ),
  };
};
