import { useRef } from "react";
import { AnnotationData } from "../../../../app/types";
import Annotation from "./Annotation";
import { useMapData } from "./services/useMapData";
import Highlight from "../../../../assets/img/highlight.svg";

const Map = ({
  availableWidth,
  availableHeight,
  map_data: mapDataMeta,
  x,
  y,
  width,
  height,
  isFullscreen,
  annotations,
  isHighlighterOn,
  placeHighlight,
  highlights,
}: any) => {
  const mapData = useMapData(mapDataMeta);
  const highlightContainer = useRef<any>();

  if (!mapData) {
    return "Loading";
  }

  const mw = mapData.map.data.attributes.width;
  const mh = mapData.map.data.attributes.height;

  const realWidth = isFullscreen ? mw : Math.min(mw ?? 1e10, width);
  const realHeight = isFullscreen ? mh : Math.min(mh ?? 1e10, height);

  const realX = isFullscreen ? 0 : x;
  const realY = isFullscreen ? 0 : y;

  const scaleX = availableWidth / realWidth;
  const scaleY = availableHeight / realHeight;
  const scale = scaleX > scaleY ? scaleY : scaleX;

  const paddedX = availableWidth - (mw - x) * scale;
  const paddedY = availableHeight - (mh - y) * scale;

  const left = isFullscreen
    ? (availableWidth - realWidth * scale) / 2
    : paddedX > 0
    ? paddedX / 2
    : 0;
  const top = isFullscreen
    ? (availableHeight - realHeight * scale) / 2
    : paddedY > 0
    ? paddedY / 2
    : 0;

  const onHighlightCapture = (event: any) => {
    if (!highlightContainer.current) {
      return;
    }
    const mapElement = highlightContainer.current.getBoundingClientRect();
    const pos = {
      x: (event.clientX - mapElement.left) / scale + realX,
      y: (event.clientY - mapElement.top) / scale + realY,
    };

    event.stopPropagation();
    event.preventDefault();
    placeHighlight(pos);
  };

  return (
    <div
      style={{
        maxWidth: availableWidth,
        maxHeight: availableHeight,
        width: availableWidth,
        height: availableHeight,
        backgroundColor: "#fffaee",
      }}
    >
      <div
        style={{
          width: availableWidth / scale,
          height: availableHeight / scale,
          overflow: "hidden",
          position: "relative",
          top: `${top}px`,
          left: `${left}px`,
          transform: `scale(${scale})`,
          transformOrigin: "top left",
        }}
      >
        {isHighlighterOn && (
          <div
            onClickCapture={onHighlightCapture}
            ref={highlightContainer}
            style={{
              position: "absolute",
              zIndex: 9999,
              border: "5px solid #F64E60",
              cursor: "crosshair",
              borderRadius: "10px",
              width: paddedX > 0 ? mw - realX : availableWidth / scale,
              height: paddedY > 0 ? mh - realY : availableHeight / scale,
              // height: realHeight + paddedY,
            }}
          ></div>
        )}
        {/* <img
          key={123}
          draggable={false}
          alt="Highlight"
          src={Highlight}
          className="highlight-effect"
          style={{
            position: "absolute",
            left: 1500 - realX - 10,
            top: 600 - realY - 10,
          }}
        /> */}
        );
        {highlights.map((h: any) => {
          return (
            <img
              draggable={false}
              key={h.timestamp}
              alt="Highlight"
              src={Highlight}
              className="highlight-effect-img"
              style={{
                position: "absolute",
                width: "100px",
                height: "100px",
                left: h?.x - realX - 50,
                top: h?.y - realY - 50,
              }}
            />
          );
        })}
        <img
          src={mapData.map.data.attributes.url}
          alt=""
          draggable={false}
          style={{
            position: "absolute",
            zIndex: 10,
            left: `-${realX}px`,
            top: `-${realY}px`,
          }}
        />
        {annotations.map((a: any) => {
          const data = mapData.annotations[a.annotation];
          return (
            data && (
              <Annotation
                key={data.id}
                offsetX={realX}
                offsetY={realY}
                data={data}
              />
            )
          );
        })}
        {Object.values(mapData.annotations).map((a) => {
          return (
            a.isPreset && (
              <Annotation
                key={a.key}
                offsetX={realX}
                offsetY={realY}
                data={a}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

export default Map;
