import Banner from "./form/Banner";
import { useSearchParams } from "react-router-dom";
import { useActivityQuery, useAuthSessionQuery } from "../app/zetaApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import {
  setIsFacilitator,
  setIsStarted,
  setName,
  setSessionId,
  setSessionName,
  setType,
  setUserId,
} from "../app/appSlice";
import { useAppDispatch } from "../app/hooks";
import { SessionType } from "../app/types";
import { useDatabase } from "../db/useDatabase";
import { child, get, update } from "firebase/database";
import { SessionData } from "../management/NewSessionModal";
import Title from "./form/Title";
import LoadingSession from "./LoadingSession";
import "./login.scss";
import {
  app,
  courseUrl,
  getActivityUrl,
  getModules,
  loginUrl,
} from "../app/configuration";
import { useActivity } from "../services/useActivity";

const redirect = (url: string) => {
  window.location.href = url;
};

const w = 3840;
const h = 2160;

const Login = () => {
  const dispatch = useAppDispatch();
  const authSession = useAuthSessionQuery();
  const activitySessionsDb = useDatabase("sessions", true);
  const [fakeLoading, setFakeLoading] = useState(true);
  const activity = useActivity();

  const offsetLeft = -(w - window.innerWidth) / 2;
  const offsetTop = -(h - window.innerHeight) / 2;
  const heightRatio = h / window.innerHeight;
  const scale = 1 / (heightRatio * 0.76);

  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (fakeLoading) {
      return;
    }

    if (authSession.error) {
      redirect(loginUrl);
      return;
    }

    if (activity.error) {
      if (
        "status" in activity.error &&
        (activity.error.status === 401 || activity.error.status === 403)
      ) {
        redirect(loginUrl);
      } else {
        redirect(courseUrl);
      }
    }

    if (
      activity.isSuccess &&
      authSession.isSuccess &&
      activity.data &&
      authSession.data
    ) {
      if (!activity.data.users.some((u) => u.id === authSession.data.user.id)) {
        redirect(getActivityUrl(activity.data));
        return;
      }

      const initialize = async () => {
        let activitySession = await get(
          child(activitySessionsDb, activity.data.id)
        );
        if (!activitySession.exists()) {
          await update(activitySessionsDb, {
            [activity.data.id]: {
              sessionNumber: activity.data.id,
              sessionName: activity.data.name,
              authorName:
                activity.data.creator.name ?? activity.data.creator.email,
              type: SessionType.FacilitatorLed,
              modules: getModules(activity.data),
              createdAt: Date.now(),
              isStarted: true,
              facilitatorNumber: activity.data.id,
            },
          });
        }

        activitySession = await get(
          child(activitySessionsDb, activity.data.id)
        );

        const sessionData = activitySession.val() as SessionData;
        if (!sessionData) {
          throw new Error();
        }

        dispatch(
          setName(authSession.data.user.name ?? authSession.data.user.email)
        );
        dispatch(setUserId(authSession.data.user.id));
        dispatch(setSessionId(sessionData.sessionNumber));
        dispatch(setSessionName(sessionData.sessionName));
        dispatch(
          setIsFacilitator(
            activity.data.facilitators.some(
              (f) => f.id === authSession.data.user.id
            )
          )
        );
        dispatch(setType(sessionData.type));
        dispatch(setIsStarted(true));
      };

      initialize().catch((e) => {
        redirect(getActivityUrl(activity.data));
        return;
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakeLoading, activity, authSession]);

  return (
    <div
      className="d-flex position-relative login-container"
      style={{
        background: app === "rmf" ? "#fdf6e3" : "#003ba4",
      }}
    >
      <div className="login-extra-background"></div>
      <div className="login-floater d-flex flex-column bg-white p-7 text-white">
        <Banner />
        <Title />
        <LoadingSession />
      </div>
      <div
        style={{
          left: `${offsetLeft}px`,
          top: `${offsetTop}px`,
          transform: `scale(${scale})`,
        }}
        className="login-background"
      >
        {app === "rmf" && (
          <video
            src={
              "https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/bg_video_4k_c102318844.mp4"
            }
            autoPlay={true}
            loop={true}
            muted={true}
          />
        )}

        {app === "aos" && (
          <img
            src="https://pg-rmf-cms-storage-dev.s3.eu-central-1.amazonaws.com/LOBBY_4_e8a9e43f9b.svg?updated_at=2024-02-26T14:20:51.832Z"
            alt="background"
          />
        )}
      </div>
    </div>
  );
};

export default Login;
