import Konva from "konva";

export class BackgroundManger {
  component: Konva.Group;

  layer: Konva.Layer;

  constructor({ layer, background }: any) {
    this.component = this.createBackground(layer, background);

    this.layer = layer;

    layer.add(this.component);
  }

  private createBackground(layer: Konva.Layer, background: any) {
    const stage = layer.getStage();

    const g = new Konva.Group();

    const color = new Konva.Rect({
      width: stage.width() / stage.scaleX(),
      height: stage.height() / stage.scaleY(),
      offsetX: stage.offsetX(),
      offsetY: stage.offsetY(),
      fill: "#fdfcfa",
    });

    const img = new Konva.Image({
      image: undefined,
    });
    const i = new Image();
    i.onload = () => {
      img.image(i);
    };
    i.src = background.url;

    g.add(color);
    g.add(img);

    return g;
  }
}
