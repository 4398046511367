import TaggedText from "./sections/TaggedText";
import Text from "./sections/Text";
import Title from "./sections/Title";
import Image from "./sections/Image";

import { Section as SectionType } from "../../../app/types";
import { animated } from "react-spring";
import { View } from "./Page";

import React, { useEffect, useRef } from "react";
type Props = {
  section: SectionType;
  setStep: any;
  step: number;
  hasAsyncStepControl: boolean | undefined;
  style?: any;
  isActive: boolean;
  isFacilitator: boolean;
};

const sectionToComponent: Record<string, any> = {
  "section.title": Title,
  "section.text": Text,
  "section.tagged-text": TaggedText,
  "section.image": Image,
};

const Section = React.forwardRef(
  (
    {
      section,
      isActive,
      isFacilitator,
      style,
      step,
      setStep,
      hasAsyncStepControl,
    }: Props,
    ref
  ) => {
    const Component = sectionToComponent[section.__component] ?? null;
    const thisRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (thisRef.current && hasAsyncStepControl) {
        const inputs = thisRef.current.querySelectorAll("input");
        if (inputs.length > 0 && inputs[step]) {
          inputs[step].checked = true;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
      if (thisRef.current && hasAsyncStepControl) {
        thisRef.current.querySelectorAll("input").forEach((input, i) => {
          input.addEventListener("change", (val: any) => {
            if (val && val.target && val.target.value) {
              setStep(parseInt(val.target.value));
            }
          });
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thisRef]);

    if (!isActive && !isFacilitator) {
      return null;
    }

    const opacity = !isActive ? (isFacilitator ? 0.6 : 0.03) : 1;

    return (
      <animated.div style={style} ref={thisRef}>
        <Component
          ref={ref}
          style={{
            opacity,
            backgroundColor: section.isFacilitatorGuide ? "#FEFFDF" : "white",
          }}
          {...section}
        />
      </animated.div>
    );
  }
);

export default Section;
