const LowResLanding = () => {
  return (
    <div className="w-100 h-100 d-flex flex-column flex-center bg-gray-100 p-6">
      <h3 className="mb-5">Not available :(</h3>
      <p className="text-center">
        Unfortunately, the current screen resolution is not supported by this
        application.
        <br />
        Try using a desktop computer.
      </p>
      <p></p>
    </div>
  );
};

export default LowResLanding;
