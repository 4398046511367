type Meta = {
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
};

type SessionModule = {
  id: number;
  isAvailable: boolean;
};

export enum SessionType {
  FacilitatorLed = "facilitator-led",
  SelfLed = "self-led",
  Conference = "conference",
}

export type Session = {
  id: number;
  name: string;
  createdAt: string;
  modules: Array<SessionModule>;
};

export type Section = {
  id: number;
  step: number;
  preview: string;
  isFacilitatorGuide: boolean;
  __component: string;
};

export type CanvasPuzzle = {
  background: Media;
  puzzleKey: string;
  slots: Record<string, any>[];
  pieces: Record<string, any>[];
};

export type CanvasPicPicker = {
  pickerKey: string;
  correctName: string;
  pics: {
    data: MediaAttributes[];
  };
};

export type TablesRow = {
  id: string;
  cells: TablesCell[];
};

export type TablesCell = {
  value: string;
  class: string;
};

export type CanvasTables = {
  data: TablesRow[][];
  rowVoting: boolean;
  tablesKey: string;
};

export type MediaAttributes = {
  id: number;
  attributes: {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: any;
    hash: string;
    ext: string;
    mime: string;
    size: number | null;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: string;
    updatedAt: string;
  };
};

export type Media = {
  data: MediaAttributes;
};

export type CanvasData = {
  __component: string;
  image?: Media;
  puzzle?: CanvasPuzzle;
  step: number;
};

export type PageAttributes = {
  key: string;
  order: number;
  name: string;
  hasAsyncStepControl: boolean | undefined;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  sections: Section[];
  // facilitatorGuide: Section[];
  canvas: CanvasData[];
};

export type Page = {
  id: number;
  attributes: PageAttributes;
};

export type Chapter = {
  id: number;
  attributes: ChapterAttributes;
};

export type ChapterAttributes = {
  key: string;
  order: number;
  name: string;
  description: string;
  color: string;
  length: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  pages: {
    data: Page[];
  };
  annotationsUnlocked: AnnotationSelect[];
};

export type ModuleAttributes = {
  key: string;
  order: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
  chapters: {
    data: Chapter[];
  };
  canvas: CanvasData[];
};

export type Module = {
  id: number;
  attributes: ModuleAttributes;
};

export type ModuleData = {
  data: Module[];
  meta: Meta;
};

export type MapData = {
  map: Media;
  annotations: Record<string, AnnotationData>;
};

export type AnnotationSelect = {
  id: number;
  annotation: string;
};

export type AnnotationData = {
  key: string;
  id: number;
  x: number;
  y: number;
  height: number;
  width: number;
  media: Media;
  isPreset: boolean;
};

// NEW

export interface StatusResponseDto {
  status: "ok" | "not-authenticated";
}

export interface SessionResponseDto {
  user: OrganizationUser;
  organization: ExtendedOrganizationDto;
}

export interface AuthOptionsResponseDto {
  organization: ExtendedOrganizationDto;
  authOptions: {
    otpw: boolean;
  };
  identityProvider?: {
    protocol: "oidc";
  };
}

export interface User {
  id: string;
  name: string | undefined;
  email: string;
  preferences: {
    color: string;
    profilePicture: {
      url: string;
    };
  };
}

export interface ActivityUser extends User {
  role: "participant" | "facilitator" | "viewer";
  status: "pending" | "added" | "declined";
}

export interface OrganizationUser extends User {
  role: "user" | "admin";
}

export interface UsersQuery extends Pick<User, "email" | "name"> {
  search?: string;
}

export interface ActivitySessionUser extends ActivityUser {
  isOnline: boolean;
}

export interface ExtendedOrganizationDto {
  id: string;
  identifier: string;
  applicationUrl: string;
  spec: {
    name: string;
    logo?: MediaSpec;
  };
}

interface MediaMeta {
  name: string;
  width?: number;
  height?: number;
  mime: string;
  url: string;
}

export interface MediaSpec extends MediaMeta {
  alternativeText?: string;
  caption?: string;
  formats?: {
    large: MediaMeta;
    medium: MediaMeta;
    small: MediaMeta;
    thumbnail: MediaMeta;
  };
}

export interface Activity {
  id: string;
  name: string;
  type: "delta-course";
  description: string;
  status: "draft" | "active" | "completed" | "archived" | "ready";
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  completedAt: string;
  activatedAt: string;
  archivedAt: string;
  scheduledAt: string;
  resource: Resource;
  creator: User;
  participants: ActivityUser[];
  facilitators: ActivityUser[];
  viewers: ActivityUser[];
  users: ActivityUser[];
}

export interface BaseActivityUserRequestDto {
  email: string;
  role: 'participant' | 'viewer' | 'facilitator';
}

export interface BaseActivityRequestDto {
  name?: string;
  users?: BaseActivityUserRequestDto[];
}

export interface UpdateActivityRequestDto extends BaseActivityRequestDto {
  id: string;
  description?: string;
  status?: Activity["status"];
  scheduledTime?: string;
}

export interface ResourceSpec {
  id: string;
  identifier: string;
  title: string;
  slug: string;
  icon?: MediaSpec;
}

export interface Resource {
  id: string;
  title: string;
  slug: string;
  type: "course";
  spec: DeltaCourseSpec;
}

export interface CourseSpec extends ResourceSpec {
  description: string;
  icon?: MediaSpec;
  images?: MediaSpec[];
  experiences?: CourseExperienceSpec[];
}

export interface DeltaCourseSpec extends CourseSpec {
  experience: CourseExperienceSpec<"delta-course">;
}

export interface DeltaCourseSpec extends CourseSpec {
  experience: CourseExperienceSpec<"delta-course">;
}

export interface ExtendedDeltaCourseSpec extends DeltaCourseSpec {
  appUrl: string;
}

export type CourseExperienceType = "delta-course";

export interface CourseExperienceSpec<
  T extends CourseExperienceType = CourseExperienceType
> {
  id: string;
  title: string;
  description: string;
  type: T;
  images: MediaSpec[];
  attributes: {
    environment: "online" | "in-person";
    delivery: "facilitator-led" | "self-led";
    format: "desktop" | "mobile, desktop" | "learning-kit";
    duration: {
      min: number;
      max?: number;
    };
  };
}
