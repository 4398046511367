import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { useAppSelector } from "./app/hooks";
import { selectLocale } from "./intl/intlSlice";

import Management from "./management/Management";
import Course from "./course/Course";
import { appName, faviconUrl } from "./app/configuration";
import { useEffect } from "react";

const changeTheme = () => {
  const link = (document.querySelector("link[rel*='icon']") ||
    document.createElement("link")) as HTMLLinkElement;
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = faviconUrl;
  document.getElementsByTagName("head")[0].appendChild(link);
  document.title = appName;
};

function App() {
  const [locale, localeData] = useAppSelector(selectLocale);

  useEffect(() => {
    changeTheme();
  }, []);

  return (
    <IntlProvider
      messages={localeData.messages}
      locale={locale}
      defaultLocale="en-US"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Course />} />
          <Route path="/management" element={<Management />} />
          <Route path="*" element={<Navigate replace to={"/"} />} />
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
