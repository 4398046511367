import { useState } from "react";
import { Nav, Tab } from "react-bootstrap";

type Props = {
  tabs: {
    content: string;
    navigation: string;
  }[];
  availableWidth: number;
  availableHeight: number;
};

const Tabbed = ({ tabs, ...props }: Props) => {
  const [active, setActive] = useState(0);

  return (
    <div
      className="card"
      style={{
        maxWidth: props.availableWidth * 0.7,
        maxHeight: props.availableHeight * 0.8,
      }}
    >
      <Tab.Container id="left-tabs-example" defaultActiveKey={active}>
        <Nav className="nav nav-pills justify-content-center py-4 mb-0 card-header">
          {tabs.map((t, i) => {
            return (
              <Nav.Item
                className="nav-item cursor-pointer d-flex flex-center px-1 my-0"
                key={i}
              >
                <Nav.Link
                  style={{
                    backgroundColor: active === i ? "#5e92f321" : "#F5F8FA",
                    // borderRadius: "0.3rem",
                    fontWeight: active === i ? "bolder" : "500",
                    border: active === i ? "0.2rem solid #5e92f3" : "",
                  }}
                  className="d-flex text-gray-900 flex-column flex-center"
                  eventKey={i}
                  onClick={() => setActive(i)}
                >
                  <div dangerouslySetInnerHTML={{ __html: t.navigation }} />
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
        <Tab.Content className="tab-content card-body">
          {tabs.map((t, i) => {
            return (
              <Tab.Pane className="" key={i} eventKey={i}>
                <div dangerouslySetInnerHTML={{ __html: t.content }} />
              </Tab.Pane>
            );
          })}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Tabbed;
