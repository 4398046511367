import { useFormik } from "formik";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import { useEffect, useState } from "react";
import clsx from "clsx";
import { useDatabase } from "../db/useDatabase";
import { child, DatabaseReference, get } from "firebase/database";
import { Modal } from "react-bootstrap";
import { SessionType } from "../app/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";

export type SessionData = {
  sessionNumber: string;
  sessionName: string;
  facilitatorNumber: string;
  authorName: string;
  createdAt: number;
  isStarted: boolean;
  type: SessionType;
  modules: string[];
};

const schema = Yup.object().shape({
  sessionNumber: Yup.string()
    .matches(/^[0-9]{3}-[0-9]{3}$/, "Session number format is invalid")
    .required("Session number is required"),
  sessionName: Yup.string()
    .min(3, "Please provide at least 3 letters")
    .max(30, "No more than 30 letters are allowed")
    .required("Session name is required"),
  authorName: Yup.string()
    .min(2, "Please provide at least 2 letters")
    .max(15, "No more than 15 letters are allowed")
    .required("Author name is required"),
  type: Yup.string().required("Training type is required."),
  modules: Yup.array()
    .min(1, "At least 1 module must be selected.")
    .required("required"),
});

type FormState = {
  sessionNumber: string;
  sessionName: string;
  authorName: string;
  type: SessionType;
  modules: string[];
};

const doesSessionExist = async (
  sessionsDb: DatabaseReference,
  sessionNumber: string
): Promise<boolean> => {
  return get(child(sessionsDb, sessionNumber)).then((snap) => snap.exists());
};

const generateRandomSessionNumber = async (
  sessionsDb: DatabaseReference
): Promise<string> => {
  const s = String(Math.floor(Math.random() * 999999) + 1).padStart(6, "0");
  const sn = `${s.slice(0, 3)}-${s.slice(3, 6)}`;

  await new Promise((r) => setTimeout(r, 2000)); // 500 ms sleep
  const exists = await doesSessionExist(sessionsDb, sn);
  if (exists) {
    return await generateRandomSessionNumber(sessionsDb);
  } else {
    return sn;
  }
};

export const NewSessionModal = ({ isOpen, setIsOpen, createSession }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sessionNumber, setSessionNumber] = useState("");
  const sessionsDb = useDatabase("sessions", true);
  const [isRandomizing, setIsRandomizing] = useState(false);

  const formik = useFormik<FormState>({
    initialValues: {
      sessionNumber: "",
      sessionName: "",
      authorName: "",
      type: SessionType.FacilitatorLed,
      modules: ["M0", "M1", "M2", "M3"],
    },
    validationSchema: schema,

    onSubmit: (values) => {
      formik.validateForm(values);

      get(child(sessionsDb, values.sessionNumber)).then((snap) => {
        const val = snap.val();
        if (val) {
          setTimeout(() => {
            formik.setFieldError(
              "sessionNumber",
              "Session with this number already exists, please choose another one."
            );
            setIsLoading(false);
          }, 400);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, fp, sp] = sessionNumber.match(/([0-9]{3})-([0-9]{3})/)!;
          const tp = ((Number(fp) * Number(sp)) % 1000)
            .toString()
            .padStart(3, "0");
          const isSelfLed = values.type === SessionType.SelfLed;

          const processModules = (modules: string[]) => {
            const mdls = modules;
            return mdls
              .slice()
              .sort((a, b) => parseInt(a[1]) - parseInt(b[1]))
              .map((m) => (isSelfLed ? `${m}-SL` : m));
          };

          createSession({
            ...values,
            modules: processModules(values.modules),
            createdAt: Date.now(),
            isStarted: isSelfLed ? true : false,
            facilitatorNumber: `${sessionNumber}-${String(tp)}`,
          }).then(() => {
            setTimeout(() => {
              setIsLoading(false);
              setIsOpen(false);
            }, 400);
          });
        }
      });
    },
  });
  useEffect(() => {
    if (
      formik.values.type === SessionType.Conference &&
      formik.values.modules?.includes("M3")
    ) {
      formik.setFieldValue(
        "modules",
        formik.values.modules.filter((m) => m !== "M3")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  const onSessionNumberInputChange = (e: any) => {
    let val: string = e.currentTarget.value;
    if (val.length < 4) {
      if (!/^([0-9]){0,3}$/.test(val)) {
        return;
      }
    }
    if (val.length >= 4 && val.length < 8) {
      if (!/^[0-9]{3}-[0-9]{0,3}$/.test(val)) {
        return;
      }
    }
    if (val.length >= 8) {
      return;
    }
    if (sessionNumber.length < 3 && val.length === 3) {
      val += "-";
    }
    setSessionNumber(val);
    formik.setFieldValue("sessionNumber", val);
  };

  return (
    <Modal
      className=""
      show={isOpen}
      onHide={() => {
        setIsOpen(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="Management.NewSessionModal.Title" />
        </Modal.Title>
      </Modal.Header>

      {/* Modal Body */}
      <Modal.Body
      // style={{
      //   minWidth: "300px",
      //   maxHeight: "500px",
      //   overflowY: "scroll",
      // }}
      >
        <form onSubmit={formik.handleSubmit} className="w-100">
          {/* Session Number Field */}
          <div className="fv-row fv-plugins-icon-container align-self-start w-100">
            <div className="d-flex flex-column">
              <label className="fs-7 fw-bolder text-dark">
                <FormattedMessage id="Management.NewSessionModal.SessionNumber" />
              </label>
              <label className="form-label fs-8 text-gray-500">
                <FormattedMessage
                  id="Management.NewSessionModal.SessionNumber.Description"
                  values={{
                    b: ((chunks: any) => <b>{chunks}</b>) as any,
                  }}
                />
              </label>
            </div>

            <div className="d-flex flex-row">
              <input
                {...formik.getFieldProps("sessionNumber")}
                className={clsx(
                  "form-control form-control-solid me-3 fluid text-center",
                  {
                    "is-invalid": formik.touched && formik.errors.sessionNumber,
                  },
                  {
                    "is-valid":
                      formik.touched.sessionNumber &&
                      !formik.errors.sessionNumber,
                  }
                )}
                value={sessionNumber}
                type="text"
                id="sessionNumber"
                autoComplete="off"
                onChange={onSessionNumberInputChange}
              />
              <button
                className="btn btn-light-primary text-white btn-sm"
                title="Randomize Session Number"
                disabled={isRandomizing}
                onClick={() => {
                  setIsRandomizing(true);
                  generateRandomSessionNumber(sessionsDb).then((sn: string) => {
                    if (sn) {
                      formik.setFieldValue("sessionNumber", sn);
                      setSessionNumber(sn);
                      setIsRandomizing(false);
                    }
                  });
                }}
              >
                <FontAwesomeIcon
                  size="lg"
                  spin={isRandomizing}
                  icon={faRotate}
                />
              </button>
            </div>
            <div className="text-danger fs-8 pt-1 w-100 h-25px">
              {formik.touched.sessionNumber && formik.errors.sessionNumber && (
                <span role="alert">{formik.errors.sessionNumber}</span>
              )}
            </div>
          </div>

          {/* Session Name Field */}
          <div className="fv-row fv-plugins-icon-container align-self-start w-100">
            <div className="d-flex flex-column">
              <label className="fs-7 fw-bolder text-dark">
                <FormattedMessage id="Management.NewSessionModal.SessionName" />
              </label>
              <label className="form-label fs-8 text-gray-500">
                <FormattedMessage id="Management.NewSessionModal.SessionName.Description" />
              </label>
            </div>

            <input
              {...formik.getFieldProps("sessionName")}
              className={clsx(
                "form-control form-control-solid me-3 fluid text-center",
                {
                  "is-invalid":
                    formik.touched.sessionName && formik.errors.sessionName,
                },
                {
                  "is-valid":
                    formik.touched.sessionName && !formik.errors.sessionName,
                }
              )}
              type="search"
              id="sessionName"
              autoComplete="off"
            />
            <div className="text-danger fs-8 pt-1 w-100 h-25px">
              {formik.touched.sessionName && formik.errors.sessionName && (
                <span role="alert">{formik.errors.sessionName}</span>
              )}
            </div>
          </div>

          {/* Author Name Field */}
          <div className="fv-row fv-plugins-icon-container align-self-start w-100">
            <div className="d-flex flex-column">
              <label className="fs-7 fw-bolder text-dark">
                <FormattedMessage id="Management.NewSessionModal.AuthorName" />
              </label>
              <label className="form-label fs-8 text-gray-500">
                <FormattedMessage id="Management.NewSessionModal.AuthorName.Description" />
              </label>
            </div>
            <input
              {...formik.getFieldProps("authorName")}
              className={clsx(
                "form-control form-control-solid me-3 fluid text-center",
                {
                  "is-invalid":
                    formik.touched.authorName && formik.errors.authorName,
                },
                {
                  "is-valid":
                    formik.touched.authorName && !formik.errors.authorName,
                }
              )}
              type="search"
              id="authorName"
              autoComplete="off"
            />
            <div className="text-danger fs-8 pt-1 w-100 h-25px">
              {formik.touched.authorName && formik.errors.authorName && (
                <span role="alert">{formik.errors.authorName}</span>
              )}
            </div>
          </div>

          {/* Training Type */}
          <div className="fv-row fv-plugins-icon-container align-self-start w-100">
            <div className="d-flex flex-column mb-4">
              <label className="fs-7 fw-bolder text-dark ">
                {/* <FormattedMessage id="Management.NewSessionModal.AuthorName" /> */}
                Team Size
              </label>
              <label className="form-label fs-8 text-gray-500">
                {/* <FormattedMessage id="Management.NewSessionModal.AuthorName.Description" /> */}
              </label>
            </div>

            <div className="fv-row fv-plugins-icon-container align-self-start w-100">
              <div className="d-flex flex-row flex-center">
                <div className="form-check form-check-custom form-check-solid me-10">
                  <input
                    {...formik.getFieldProps("type")}
                    className="form-check-input module-check"
                    type="radio"
                    name="type"
                    value={SessionType.FacilitatorLed}
                    checked={formik.values.type === SessionType.FacilitatorLed}
                    id={SessionType.FacilitatorLed}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={SessionType.FacilitatorLed}
                  >
                    Team
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    {...formik.getFieldProps("type")}
                    className="form-check-input module-check"
                    type="radio"
                    name="type"
                    value={SessionType.Conference}
                    checked={formik.values.type === SessionType.Conference}
                    id={SessionType.Conference}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={SessionType.Conference}
                  >
                    Conference
                  </label>
                </div>
              </div>
            </div>
            <div className="text-danger fs-8 pt-1 w-100 h-25px">
              {formik.touched.type && formik.errors.type && (
                <span role="alert">{formik.errors.type}</span>
              )}
            </div>
          </div>

          <div className="fv-row fv-plugins-icon-container align-self-start w-100">
            <div className="d-flex flex-column mb-4">
              <label className="fs-7 fw-bolder text-dark ">
                {/* <FormattedMessage id="Management.NewSessionModal.AuthorName" /> */}
                Enabled Modules
              </label>
              <label className="form-label fs-8 text-gray-500">
                {/* <FormattedMessage id="Management.NewSessionModal.AuthorName.Description" /> */}
                Use checkboxes to select which Art of Selling modules will be
                available to participants in this session.
              </label>
            </div>

            {/* Modules Selection  */}
            <div
              role="group"
              aria-labelledby="checkbox-group"
              className="d-flex flex-column gap-2 justify-content-around"
            >
              <div className="form-check form-check-custom form-check-solid">
                <input
                  {...formik.getFieldProps("modules")}
                  className="form-check-input module-check"
                  type="checkbox"
                  value="M0"
                  name="modules"
                  id="modules-M0"
                  checked={formik.values.modules.includes("M0")}
                />
                <label className="form-check-label" htmlFor="modules-M0">
                  Introduction
                </label>
              </div>

              <div className="form-check form-check-custom form-check-solid">
                <input
                  {...formik.getFieldProps("modules")}
                  className="form-check-input module-check"
                  type="checkbox"
                  value="M1"
                  name="modules"
                  id="modules-M1"
                  checked={formik.values.modules.includes("M1")}
                />
                <label className="form-check-label" htmlFor="modules-M1">
                  Strong Relationship Building
                </label>
              </div>

              <div className="form-check form-check-custom form-check-solid ">
                <input
                  {...formik.getFieldProps("modules")}
                  className="form-check-input module-check"
                  type="checkbox"
                  id="modules-M2"
                  value="M2"
                  name="modules"
                  checked={formik.values.modules.includes("M2")}
                />
                <label className="form-check-label" htmlFor="modules-M2">
                  Memorable Selling
                </label>
              </div>

              <div className="form-check form-check-custom form-check-solid">
                <input
                  {...formik.getFieldProps("modules")}
                  className="form-check-input module-check"
                  type="checkbox"
                  value="M3"
                  id="modules-M3"
                  name="modules"
                  checked={formik.values.modules.includes("M3")}
                  disabled={formik.values.type === SessionType.Conference}
                />
                <label className="form-check-label" htmlFor="modules-M3">
                  Earning Trust Daily
                </label>
              </div>
            </div>
            <div className="text-danger fs-8 pt-3 w-100 h-25px">
              {formik.touched.modules && formik.errors.modules && (
                <span role="alert">{formik.errors.modules}</span>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-sm fs-7 btn-secondary align-self-end py-2 fw-bolder"
          onClick={() => {
            setIsOpen(false);
          }}
          disabled={isLoading}
        >
          <FormattedMessage id="General.Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-sm fs-7 btn-primary align-self-end py-2 fw-bolder"
          disabled={
            isRandomizing || isLoading || !(formik.dirty && formik.isValid)
          }
          onClick={() => {
            setIsLoading(true);
            formik.submitForm();
          }}
        >
          {isLoading ? (
            <span className="indicator-progress" style={{ display: "block" }}>
              <FormattedMessage id="General.Loading" />
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <FormattedMessage id="General.Continue" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
