import {
  child,
  DataSnapshot,
  onValue,
  update,
  remove,
} from "@firebase/database";
import { useEffect, useState } from "react";
import { useDatabase } from "../../../../db/useDatabase";

export const useVotingData = (tablesKey: string, userId: string) => {
  const [votingData, setVotingData] = useState<
    Record<string, Record<string, string>>
  >({});
  const votesDb = useDatabase(`/tables/${tablesKey}/votes`);

  const vote = (rowId: string) => {
    if (votingData && votingData[rowId] && votingData[rowId][userId]) {
      remove(child(votesDb, `${rowId}/${userId}`));
    } else {
      update(child(votesDb, rowId), { [userId]: userId });
    }
  };

  const clearVotes = () => {
    remove(votesDb);
  };

  useEffect(() => {
    if (!votesDb) {
      return;
    }
    return onValue(votesDb, (snap: DataSnapshot) => {
      const data = snap.val();
      if (!data) {
        setVotingData({});
      } else {
        setVotingData(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [votesDb]);

  return { votingData, vote, clearVotes };
};
