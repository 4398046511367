import * as _ from "lodash";

const Page = ({ isActive, i, setActivePage }: any) => {
  return (
    <li className={`page-item previous ${isActive && "active"}`}>
      <button
        className="page-link page-text"
        onClick={() => {
          setActivePage(i);
        }}
      >
        {i + 1}
      </button>
    </li>
  );
};

export const Pagination = ({
  numberOfPages,
  activePage,
  setActivePage,
}: any) => {
  return (
    <ul className="pagination d-flex flex-row justify-space-between">
      <li className={`page-item w-150px d-flex flex-center previous ${activePage === 0 && "disabled"}`}>
        <button
          className="page-link page-text"
          onClick={() => {
            setActivePage(activePage - 1);
          }}
        >
          Previous Page
        </button>
      </li>
      <div className="d-flex flex-row">
        {numberOfPages <= 9 ? (
          _.range(0, numberOfPages).map((i) => (
            <Page
              key={i}
              isActive={activePage === i}
              setActivePage={setActivePage}
              i={i}
            />
          ))
        ) : (
          <>
            <Page
              isActive={activePage === 0}
              setActivePage={setActivePage}
              i={0}
            />
            {activePage < 2 && (
              <>
                <Page
                  isActive={activePage === 1}
                  setActivePage={setActivePage}
                  i={1}
                />
                <Page
                  isActive={activePage === 2}
                  setActivePage={setActivePage}
                  i={2}
                />
              </>
            )}

            {activePage === 2 && (
              <>
                <Page isActive={false} setActivePage={setActivePage} i={1} />
                <Page isActive={true} setActivePage={setActivePage} i={2} />
                <Page isActive={false} setActivePage={setActivePage} i={3} />
              </>
            )}

            {activePage > 2 && (
              <li className="page-item previous">
                <span className="page-link page-text">...</span>
              </li>
            )}

            {activePage >= 3 && activePage <= numberOfPages - 4 && (
              <>
                <Page
                  isActive={false}
                  setActivePage={setActivePage}
                  i={activePage - 1}
                />
                <Page
                  isActive={true}
                  setActivePage={setActivePage}
                  i={activePage}
                />
                <Page
                  isActive={false}
                  setActivePage={setActivePage}
                  i={activePage + 1}
                />
              </>
            )}

            {activePage < numberOfPages - 3 && (
              <li className="page-item previous">
                <span className="page-link page-text">...</span>
              </li>
            )}

            {activePage === numberOfPages - 3 && (
              <>
                <Page
                  isActive={false}
                  setActivePage={setActivePage}
                  i={numberOfPages - 4}
                />
                <Page
                  isActive={true}
                  setActivePage={setActivePage}
                  i={numberOfPages - 3}
                />
                <Page
                  isActive={false}
                  setActivePage={setActivePage}
                  i={numberOfPages - 2}
                />
              </>
            )}

            {activePage > numberOfPages - 3 && (
              <>
                <Page
                  isActive={activePage === numberOfPages - 3}
                  setActivePage={setActivePage}
                  i={numberOfPages - 3}
                />
                <Page
                  isActive={activePage === numberOfPages - 2}
                  setActivePage={setActivePage}
                  i={numberOfPages - 2}
                />
              </>
            )}

            <Page
              isActive={activePage === numberOfPages - 1}
              setActivePage={setActivePage}
              i={numberOfPages - 1}
            />
          </>
        )}
      </div>
      <li
        className={`page-item w-150px d-flex flex-center next ${
          activePage === numberOfPages - 1 && "disabled"
        }`}
      >
        <button
          className="page-link page-text"
          onClick={() => {
            setActivePage(activePage + 1);
          }}
        >
          Next Page
        </button>
      </li>
    </ul>
  );
};
