import * as Firebase from "firebase/database";

import { SlotManager } from "./SlotManager";
import { FirebaseStore } from "../../../../../db/FirebaseStore";
import { PieceManager } from "./PieceManager";
import { CheckStrategy } from "./check-strategy/CheckStrategy";

const CHECK_INTERVAL = 300;

export class CheckManager {
  slotManager: SlotManager;

  pieceManager: PieceManager;

  database: Firebase.DatabaseReference;

  checkStrategy: CheckStrategy;

  isChecking: boolean = false;

  setIsChecking: (isChecking: boolean) => void;

  private checkInterval: number | undefined = undefined;

  constructor({
    slotManager,
    pieceManager,
    checkStrategy,
    puzzleKey,
    setIsChecking,
  }: any) {
    this.slotManager = slotManager;
    this.pieceManager = pieceManager;
    this.checkStrategy = checkStrategy;
    this.setIsChecking = setIsChecking;

    this.database = Firebase.child(
      FirebaseStore.getPuzzleRef(puzzleKey),
      "isChecking"
    );

    this.initializeListener();
  }

  private initializeListener() {
    Firebase.onValue(this.database, (snapshot) => {
      const data = snapshot.val();
      if (data === true) {
        this.setCheckOn();
        this.setIsChecking(true);
      } else {
        this.setCheckOff();
        this.setIsChecking(false);
      }
    });
  }

  setCheck(isChecking: boolean) {
    Firebase.set(this.database, isChecking);
  }

  private setCheckOn() {
    const slots = Object.values(this.slotManager.slots);
    const pieces = Object.values(this.pieceManager.pieces ?? {});

    const checkerFn = () => {
      slots.forEach((s) => {
        const isCheckCorrect = this.checkStrategy.checkSlot(
          s,
          this.pieceManager.pieces
        );
        if (isCheckCorrect === undefined) {
          return;
        }
        if (isCheckCorrect) {
          s.component.stroke("#33b500");
        } else {
          s.component.stroke("red");
        }
        s.component.strokeEnabled(true);
      });
      pieces?.forEach((p) => {
        const isCheckCorrect = this.checkStrategy.checkPiece(p);
        if (isCheckCorrect === undefined) {
          return;
        }
        if (isCheckCorrect) {
          p.component.stroke("#a6d38d");
        } else {
          p.component.stroke("#f0987c");
        }
        p.component.strokeEnabled(true);
      });
    };

    checkerFn();

    this.checkInterval = window.setInterval(checkerFn, CHECK_INTERVAL);
  }

  private setCheckOff() {
    if (this.checkInterval) {
      window.clearInterval(this.checkInterval);
    }
    this.checkInterval = undefined;

    const slots = Object.values(this.slotManager.slots);
    const pieces = Object.values(this.pieceManager?.pieces ?? {});

    slots?.forEach((s) => {
      s.component.strokeEnabled(false);
    });
    pieces?.forEach((p) => {
      p.component.strokeEnabled(false);
    });
  }

  cleanUp() {
    Firebase.off(this.database);
    this.setCheckOff();
  }
}
