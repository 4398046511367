import {
  faCircleDown,
  faCircleRight,
  faCircleUp,
  faCircleLeft,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { config, useTransition, animated } from "react-spring";
import { StepTransition } from "./Page";

const ControlButtons = ({
  step,
  setPrevStep,
  setNextStep,
  setPrevPage,
  setNextPage,
  isFirstPage,
  isLastPage,
  isLastStep,
  stepTransition,
  setStepTransition,
  hasAsyncStepControl,
}: any) => {
  const btnTransitions = useTransition(step, {
    from: {
      opacity: hasAsyncStepControl ? 1 : 0,
      transform: hasAsyncStepControl
        ? "translate3d(0,0,0)"
        : stepTransition === StepTransition.Previous
        ? "translate3d(0,50%,0)"
        : "translate3d(0,-50%,0)",
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    config: config.stiff,
  });

  const isFirstStep = step === 0 || hasAsyncStepControl;

  const cntClass = isLastStep
    ? "d-flex justify-content-end py-3 px-4"
    : "d-flex flex-center py-3 px-4";

  return btnTransitions((styles, item) => (
    <animated.div style={styles as any} className={cntClass}>
      <button
        className="btn btn-sm fs-7 btn-secondary fw-bolder px-4 py-2 me-2 "
        disabled={isFirstPage && isFirstStep}
        onClick={() => {
          if (isFirstStep) {
            setPrevPage();
          } else {
            setPrevStep();
          }
          setStepTransition(StepTransition.Previous);
        }}
      >
        <FontAwesomeIcon
          className="me-1"
          size="lg"
          icon={isFirstStep ? faCircleLeft : faCircleUp}
        />
        <span>{isFirstStep ? "Prev. Page" : "Prev. Step"}</span>
      </button>

      <button
        className="btn btn-sm fs-7 btn-primary fw-bolder px-4 py-2 btn-hover-scale"
        onClick={() => {
          if (isLastStep) {
            setNextPage();
            return;
          }
          setStepTransition(StepTransition.Next);
          setNextStep();
        }}
      >
        <span>
          {isLastStep
            ? isLastPage
              ? "Complete Chapter"
              : "Next Page"
            : "Next Step"}
        </span>
        <FontAwesomeIcon
          size="lg"
          className="ms-1"
          icon={isLastStep ? faCircleRight : faCircleDown}
        />
      </button>
    </animated.div>
  ));
};

export default ControlButtons;
