import Konva from "konva";

import reduxStore from "../../../../../app/store";
import { BackgroundManger } from "./BackgroundManager";
import { CursorManager } from "./CursorManager";
import { PieceManager } from "./PieceManager";
import { StickyManager } from "./StickyManager";

export class StickiesManager {
  stickyManager: StickyManager;

  cursorManager: CursorManager | undefined;

  backgroundManager: BackgroundManger;

  stage: Konva.Stage;

  backgroundLayer: Konva.Layer;

  stickyLayer: Konva.Layer;

  activeLayer: Konva.Layer;

  cursorLayer: Konva.Layer;

  userId: string;

  config: any;

  constructor({ key, piecesData, slotsData, background, config }: any) {
    this.stage = new Konva.Stage({
      container: key,
      width: 1440,
      height: 1024,
    });

    this.userId = reduxStore.getState().app.userId!;
    this.config = config;

    this.backgroundLayer = new Konva.Layer();
    this.stickyLayer = new Konva.Layer();
    this.activeLayer = new Konva.Layer();
    this.cursorLayer = new Konva.Layer();

    this.backgroundLayer.listening(false);
    this.stickyLayer.listening(true);
    this.activeLayer.listening(false);
    this.cursorLayer.listening(false);

    this.stage.add(this.backgroundLayer);
    this.stage.add(this.stickyLayer);
    this.stage.add(this.activeLayer);
    this.stage.add(this.cursorLayer);

    this.backgroundManager = new BackgroundManger({
      layer: this.backgroundLayer,
      background,
    });

    if (!config.isSinglePlayer) {
      this.cursorManager = new CursorManager({
        layer: this.cursorLayer,
        puzzleKey: key,
        puzzleManager: this,
      });
    }

    this.stickyManager = new StickyManager({
      stickiesKey: key,
      // piecesData,
      // layer: this.piecesLayer,
      // activeLayer: this.activeLayer,
      openEditNoteModal: config.openEditNoteModal,
      stickiesManager: this,
    });

    // this.piecesLayer.draw();
  }

  cleanUp() {
    try {
      this.cursorManager?.cleanUp();
    } catch (e) {}
  }
}
