import {
  setConfirmationModalMessage,
  setIsConfirmationModalOpen,
} from "../../app/appSlice";
import { useAppDispatch } from "../../app/hooks";

let resolveCallback: (val: boolean) => void;

export const useConfirmationModal = () => {
  const dispatch = useAppDispatch();

  const onConfirm = () => {
    dispatch(setIsConfirmationModalOpen(false));
    resolveCallback(true);
  };

  const onCancel = () => {
    dispatch(setIsConfirmationModalOpen(false));
    resolveCallback(false);
  };

  const confirm = (message: string): Promise<boolean> => {
    dispatch(setIsConfirmationModalOpen(true));
    dispatch(setConfirmationModalMessage(message));
    return new Promise((resolve, reject) => {
      resolveCallback = resolve;
    });
  };

  return {
    confirm,
    onConfirm,
    onCancel,
  };
};
