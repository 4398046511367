import {
  selectHandbookWidth,
  selectIsFacilitator,
  selectShouldLogin,
  setHandbookWidth,
  setHandbookWidthPct,
} from "../app/appSlice";
import { useAppSelector, useAppDispatch } from "../app/hooks";

import Login from "../login/Login";
import { Canvas } from "./canvas/Canvas";
import {
  selectActiveKeys,
  selectChapter,
  selectMenuMap,
  selectModule,
  selectModules,
  selectPage,
  selectPages,
  selectTocModules,
} from "./services/courseSlice";
import Handbook from "./handbook/Handbook";
import Navbar from "./navbar/Navbar";
import ResizeSlider from "./resize-slider/ResizeSlider";

import "./styles.scss";
import Menu from "./handbook/menu/Menu";
import { usePagination } from "./services/usePagination";
import { useCourseResize } from "./services/useCourseResize";
import { useProgressCrumbs } from "./services/useProgressCrumbs";
import { useGetModulesQuery, useGetSelfLedModulesQuery } from "../app/rmfApi";
import { useHighlighter } from "./services/useHighlighter";
import { ConfirmationModal } from "./confirmation-modal/ConfirmationModal";
import LowResLanding from "./LowResLanding";

const Course = () => {
  useGetModulesQuery();
  useGetSelfLedModulesQuery();
  const dispatch = useAppDispatch();
  const shouldLogin = useAppSelector(selectShouldLogin);
  const { pageKey } = useAppSelector(selectActiveKeys);
  const handbookWidth = useAppSelector(selectHandbookWidth);
  const tocModules = useAppSelector(selectTocModules);
  const module = useAppSelector(selectModule);
  const modules = useAppSelector(selectModules);
  const chapter = useAppSelector(selectChapter);
  const menuMap = useAppSelector(selectMenuMap);
  const pages = useAppSelector(selectPages);
  const { page } = useAppSelector(selectPage);
  const isFacilitator = useAppSelector(selectIsFacilitator);

  const {
    placeHighlight,
    toggleHighlighter,
    isHighlighterOn,
    highlights,
    isHighlighterEnabled,
    setIsHighlighterEnabled,
    setHighlights,
    setIsHighlighterOn,
  } = useHighlighter();

  const {
    setPrevPage,
    setNextPage,
    pageTransition,
    startChapter,
    setNextStep,
    setPrevStep,
    setStep,
    backToMenu,
    resetProgress,
  } = usePagination();
  const progressCrumbs = useProgressCrumbs(chapter, pages, pageKey);
  useCourseResize();

  if (window.innerWidth < 800) {
    return <LowResLanding />;
  }

  if (shouldLogin) {
    return <Login />;
  }

  let content, canvasData;
  if (!modules || !tocModules) {
    content = <div>Loading..</div>;
  } else {
    if (pageKey) {
      content = (
        <Handbook
          pages={pages}
          setPrevPage={setPrevPage}
          setNextPage={setNextPage}
          setNextStep={setNextStep}
          setPrevStep={setPrevStep}
          startChapter={startChapter}
          setStep={setStep}
          pageTransition={pageTransition}
          chapterName={chapter?.name}
          chapterColor={chapter?.color}
          progressCrumbs={progressCrumbs}
          pageKey={pageKey}
          backToMenu={backToMenu}
          resetProgress={resetProgress}
        />
      );
      canvasData = page?.canvas;
    } else {
      content = (
        <Menu
          modules={tocModules}
          beginChapter={startChapter}
          isFacilitator={isFacilitator}
          resetProgress={resetProgress}
        />
      );
      if (menuMap) {
        canvasData = menuMap;
      } else {
        canvasData = module?.attributes.canvas[0];
      }
    }
  }

  return (
    <div className="d-flex flex-column w-100">
      <ConfirmationModal />
      <Navbar
        toggleHighlighter={toggleHighlighter}
        isHighlighterOn={isHighlighterOn}
        isHighlighterEnabled={isHighlighterEnabled}
      />
      <div className="d-flex flex-row-fluid overflow-hidden">
        <div
          className="position-relative left-section sidebar"
          style={{
            minWidth: `${handbookWidth}px`,
            maxWidth: `${handbookWidth}px`,
          }}
        >
          {content}
        </div>
        <ResizeSlider
          setHandbookWidth={(w) => dispatch(setHandbookWidth(w))}
          setHandbookWidthPct={(wpct) => dispatch(setHandbookWidthPct(wpct))}
        />
        <Canvas
          data={canvasData}
          pageKey={pageKey}
          isHighlighterOn={isHighlighterOn}
          highlights={highlights}
          setIsHighlighterEnabled={setIsHighlighterEnabled}
          placeHighlight={placeHighlight}
          setHighlights={setHighlights}
          setIsHighlighterOn={setIsHighlighterOn}
        />
      </div>
    </div>
  );
};

export default Course;
