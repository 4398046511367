import { AnnotationData } from "../../../../app/types";

type Props = {
  data: AnnotationData;
  offsetX: number;
  offsetY: number;
};

const Annotation = ({ data, offsetX, offsetY }: Props) => {
  if (!data) {
    return null;
  }

  const height = data.height ? `${data.height}px` : "auto";
  const width = data.width ? `${data.width}px` : "auto";

  return (
    <img
      src={data.media.data.attributes.url}
      alt=""
      draggable={false}
      style={{
        position: "absolute",
        zIndex: 10,
        height,
        width,
        left: `${-offsetX + data.x}px`,
        top: `${-offsetY + data.y}px`,
      }}
    />
  );
};

export default Annotation;
