const LoadingSession = () => {
  return (
    <div className=" mb-4 text-black fs-6 d-flex gap-8 flex-column">
      <div>Please wait while we load your experience.</div>
      <div className="d-flex flex-center">
        <div className="spinner-border text-gray-600"></div>
      </div>
    </div>
  );
};

export default LoadingSession;
