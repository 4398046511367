import Konva from "konva";

import { useEffect, useState } from "react";
import { useSceneTransform } from "../puzzle/services/useSceneTransform";
import { StickiesManager } from "../puzzle/components/StickiesManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/free-regular-svg-icons";
import NewNoteModal from "./NewNoteModal";
import "./stickies.scss";
import { StickyColor } from "./consts";
import { SessionType } from "../../../../app/types";
import CanvasControls from "../controls/CanvasControls";

const Stickies = ({
  background,
  stickiesKey,
  availableWidth,
  availableHeight,
  isFacilitator,
  sessionType,
}: any) => {
  const [stickiesManager, setStickiesManager] = useState<StickiesManager>();
  const [predefinedText, setPredefinedText] = useState<string | null>("");
  const [predefinedColor, setPredefinedColor] = useState<StickyColor>(
    StickyColor.Green
  );
  const [editingKey, setEditingKey] = useState<string | null>("");
  const [isNewModalShown, setIsNewModalShown] = useState<boolean>(false);
  const [width, height, scale, offsetX, offsetY] = useSceneTransform(
    availableWidth,
    availableHeight
  );
  const isSinglePlayer = sessionType === SessionType.Conference;

  useEffect(() => {
    if (stickiesManager) {
      stickiesManager.stage.scaleX(scale);
      stickiesManager.stage.scaleY(scale);
      stickiesManager.stage.width(width);
      stickiesManager.stage.height(height);
      stickiesManager.stage.offsetX(offsetX);
      stickiesManager.stage.offsetY(offsetY);
    }
  }, [width, height, scale, offsetX, offsetY, stickiesManager]);

  const closeModal = () => {
    setEditingKey(null);
    setPredefinedText(null);
    setIsNewModalShown(false);
  };

  const addNewNote = (text: string, color: StickyColor) => {
    stickiesManager?.stickyManager.addNewNote({ text, color });
  };

  const editNote = (key: string, text: string, color: StickyColor) => {
    stickiesManager?.stickyManager.editNote({ key, text, color });
  };

  const openEditNoteModal = (key: string, text: string, color: StickyColor) => {
    setPredefinedText(text);
    setEditingKey(key);
    setPredefinedColor(color);
    setIsNewModalShown(true);
  };

  useEffect(() => {
    // console.log("UseEffect []");
    var sm = new StickiesManager({
      key: stickiesKey,
      background: background.data.attributes,
      config: {
        openEditNoteModal,
        isFacilitator,
        isSinglePlayer,
      },
    });
    setStickiesManager(sm);

    return () => {
      sm.cleanUp();
    };
    // stage.piecesLayer.draw()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="stickies-container">
      <NewNoteModal
        oteModal
        text={predefinedText}
        color={predefinedColor}
        editingKey={editingKey}
        isOpenNewModal={isNewModalShown}
        hideNewModal={closeModal}
        addNewNote={addNewNote}
        editNote={editNote}
      />

      {isFacilitator && (
        <CanvasControls
          restartButtonAction={() => {
            stickiesManager?.stickyManager.resetPositions();
          }}
          mainButtonAction={() => {
            setIsNewModalShown(true);
          }}
          mainButtonText="New&nbsp;Note"
          mainButtonIcon={faStickyNote}
        />
      )}
      {!isFacilitator && (
        <div
          className="stickies-ui-part d-flex flex-row "
        >
          <button
            className="btn btn-sm stickies-btn-part"
            onClick={() => {
              setIsNewModalShown(true);
            }}
          >
            <FontAwesomeIcon className="me-1" size="lg" icon={faStickyNote} />
            <span>New Note</span>
          </button>
        </div>
      )}

      <div id={stickiesKey}></div>
    </div>
  );
};

export default Stickies;
