import { selectSessionId } from "../app/appSlice";
import { useAppSelector } from "../app/hooks";
import { FirebaseStore } from "./FirebaseStore";

export const useDatabase = (path: string, root: boolean = false) => {
  const refs = FirebaseStore.getRefs();
  const sessionId = useAppSelector(selectSessionId);

  let fullPath;
  if (!root) {
    fullPath = `/sessions/${sessionId}/${path}`;
  } else {
    fullPath = path;
  }

  if (path && !refs[fullPath]) {
    const newRef = FirebaseStore.newRef(fullPath);
    return newRef;
  } else {
    return refs[fullPath];
  }
};
