import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import {
  Database,
  ref,
  DatabaseReference,
  getDatabase,
} from "firebase/database";
import { firebaseConfig } from "./config";

import reduxStore from "../app/store";

export class FirebaseStore {
  private static db: Database;

  private static refs: Record<string, DatabaseReference> = {};
  
  private static initialize() {
    const newApp = initializeApp(firebaseConfig);
    this.db = getDatabase(newApp);
    const auth = getAuth(newApp);
    signInAnonymously(auth)
      .then(() => {})
      .catch((e) => console.error(e));
  }

  static getDb() {
    if (!this.db) {
      FirebaseStore.initialize();
    }
    return this.db;
  }

  static getRefs() {
    if (!this.db) {
      FirebaseStore.initialize();
    }
    return this.refs;
  }

  static getPuzzleRef(puzzleKey: string) {
    if (FirebaseStore.refs[puzzleKey]) {
      return FirebaseStore.refs[puzzleKey];
    }
    const sessionId = reduxStore.getState().app.sessionId;
    const nr = ref(
      FirebaseStore.db,
      `/sessions/${sessionId}/puzzles/${puzzleKey}`
    );
    FirebaseStore.refs[puzzleKey] = nr;
    return nr;
  }

  static newRef(path: string) {
    const nr = ref(FirebaseStore.db, path);
    FirebaseStore.refs[path] = nr;
    return nr;
  }
}
