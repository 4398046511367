import { resolveStage } from "./stage";
import { Activity } from "./types";

export type App = "rmf" | "aos";

// --- TO SWITCH APPS, CHANGE THIS VALUE ---

export const app: App = (
  process.env.APP || window.location.host.includes("rmf") ? "rmf" : "aos"
) as App;

// ----------------------------------------

export const appName = app === "rmf" ? "Retailer Money Flow" : "Art of Selling";
export const faviconUrl =
  app === "rmf" ? "/rmf-favicon.ico" : "/aos-favicon.ico";

export const zetaUrl = {
  local: "http://localhost:3000",
  development: "https://pg.nersio.dev",
  production: "https://pg.nersio.com",
}[resolveStage()] as string;

export const cmsApiUrl = {
  local: "http://localhost:1338/api/",
  development:
    app === "rmf"
      ? "https://rmf.cms.nersio.dev/api"
      : "https://aos.cms.nersio.dev/api",
  production:
    app === "rmf"
      ? "https://rmf.cms.nersio.com/api"
      : "https://aos.cms.nersio.com/api",
}[resolveStage()] as string;

export const cmsApiToken = {
  local:
    app === "rmf"
      ? "7d990299f2a6c15183fbdf2441b4ebdadfb7fa92dc6ef7ebfdd4f3655354adcf27557bf8a77331c05fc84fab06792a313fdcd6ea0c19123a8a40eccac204c36ebfada87807812ba00205fd09c62f4fcdcfb00430a753f272e6895416b38d5795c7e3c758c74410265d904e31312018911ab154febe2c51519ea5de8986b7f2d3"
      : "0daf04c18243dc573a43ed5f009c1e3a0b2967294e5a41293c879e8dfb5bfedd6ddc8fd0d14f99c9786aec4d9cd34cb0b89fc377ee32ab2f0526218f0c1811533938fc02f0f27f0eaa72b200852f17d7b62c2690f5ee69687eb3dfab05a699ccc733e4c119bb57f41c02ea85f0b29ac1f1729363dd27c575b3f1003590cd794f",
  development:
    app === "rmf"
      ? "7d990299f2a6c15183fbdf2441b4ebdadfb7fa92dc6ef7ebfdd4f3655354adcf27557bf8a77331c05fc84fab06792a313fdcd6ea0c19123a8a40eccac204c36ebfada87807812ba00205fd09c62f4fcdcfb00430a753f272e6895416b38d5795c7e3c758c74410265d904e31312018911ab154febe2c51519ea5de8986b7f2d3"
      : "0daf04c18243dc573a43ed5f009c1e3a0b2967294e5a41293c879e8dfb5bfedd6ddc8fd0d14f99c9786aec4d9cd34cb0b89fc377ee32ab2f0526218f0c1811533938fc02f0f27f0eaa72b200852f17d7b62c2690f5ee69687eb3dfab05a699ccc733e4c119bb57f41c02ea85f0b29ac1f1729363dd27c575b3f1003590cd794f",
  production:
    app === "rmf"
      ? "7d990299f2a6c15183fbdf2441b4ebdadfb7fa92dc6ef7ebfdd4f3655354adcf27557bf8a77331c05fc84fab06792a313fdcd6ea0c19123a8a40eccac204c36ebfada87807812ba00205fd09c62f4fcdcfb00430a753f272e6895416b38d5795c7e3c758c74410265d904e31312018911ab154febe2c51519ea5de8986b7f2d3"
      : "0daf04c18243dc573a43ed5f009c1e3a0b2967294e5a41293c879e8dfb5bfedd6ddc8fd0d14f99c9786aec4d9cd34cb0b89fc377ee32ab2f0526218f0c1811533938fc02f0f27f0eaa72b200852f17d7b62c2690f5ee69687eb3dfab05a699ccc733e4c119bb57f41c02ea85f0b29ac1f1729363dd27c575b3f1003590cd794f",
}[process.env.REACT_APP_ENV ?? "local"] as string;

export const courseSlug =
  app === "rmf" ? "retailer-money-flow" : "art-of-selling";

export const courseUrl = `${zetaUrl}/courses/${courseSlug}`;

export const loginUrl = `${zetaUrl}/login?redirectTo=${encodeURIComponent(
  window.location.href
)}`;

export const getActivityUrl = (activity: Activity) => {
  return `${zetaUrl}/courses/${activity.resource.slug}/activities/${activity.id}`;
};

export const getModules = (activity: Activity) => {
  if (app === "rmf") {
    if (
      activity.resource.spec.experience.attributes.delivery ===
      "facilitator-led"
    ) {
      return ["M0", "M1", "M2", "M3"];
    } else {
      return ["M0-SL", "M1-SL", "M2-SL"];
    }
  }

  if (app === "aos") {
    return ["M0", "M1", "M2", "M3"];
  }

  return [];
};
