import { faHighlighter } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import ReactSpreadsheet from "react-spreadsheet";
import CanvasControls from "../controls/CanvasControls";

import "./spreadsheet.scss";
import { useSpreadsheetData } from "./useSpreadsheetData";
import { useTransform } from "./useTransform";

const emptyParser = {
  on: (name: string, cb: any) => {},
  parse: (formula: string) => {
    return { result: formula };
  },
};

const Spreadsheet = ({
  data: initialData,
  isFacilitator,
  availableHeight,
  availableWidth,
  // correctData,
  spreadsheetKey,
  isReadonly,
}: any) => {
  const {
    data,
    setSpreadsheetDataRemote,
    isChecking,
    setIsCheckingRemote,
    solvePuzzle,
    setCellDataRemote,
    setEditingValue,
    active,
    setSpshdata,
    setActive,
  } = useSpreadsheetData(
    spreadsheetKey,
    initialData
    // isFacilitator,
    // correctData
  );

  const [lastUpdate, setLastUpdate] = useState<any>({});
  const tableRef = useRef<any>();
  const { scale } = useTransform(availableWidth, availableHeight, tableRef);

  return (
    <div
      className="d-flex flex-center position-relative"
      style={{
        height: `${availableHeight}px`,
        width: `${availableWidth}px`,
      }}
    >
      {isFacilitator && !isReadonly && (
        <CanvasControls
          restartButtonAction={() => {
            setSpreadsheetDataRemote(initialData);
          }}
          solveButtonAction={solvePuzzle}
          mainButtonAction={() => {
            setIsCheckingRemote(!isChecking);
          }}
          mainButtonIcon={isChecking ? undefined : faHighlighter}
          mainButtonText={isChecking ? "Stop Check" : "Check"}
        />
      )}
      <div ref={tableRef} style={{ transform: `scale(${scale})` }}>
        <ReactSpreadsheet
          formulaParser={emptyParser}
          className="fs-4 text-gray-900"
          data={data}
          onActivate={(active) => {
            setActive(active);
          }}
          onBlur={() => {
            setActive(undefined);
          }}
          onChange={(newData) => {
            if (
              active &&
              newData[active.row] &&
              newData[active.row][active.column] &&
              data[active.row] &&
              data[active.row][active.column] &&
              newData[active.row][active.column].value !==
                data[active.row][active.column].value
            ) {
              setEditingValue(newData[active.row][active.column]);
            } else {
              setEditingValue(undefined);
            }
          }}
          onCellCommit={(prev, next) => {
            const id = next?.id ?? prev?.id;
            const now = Date.now();
            if (id) {
              setLastUpdate({ ...lastUpdate, [id]: now });
              if (lastUpdate[id] && now - lastUpdate[id] < 100) {
                return;
              }
            }
            if (prev === null && lastUpdate[id] && now - lastUpdate[id] < 100) {
              return;
            }
            if (prev?.value !== next?.value) {
              if (!next?.id && prev?.id !== undefined) {
                setCellDataRemote({ ...prev, value: next.value });
              } else {
                setCellDataRemote(next);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default Spreadsheet;
