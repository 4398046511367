import * as Firebase from "firebase/database";

import { FirebaseStore } from "../../../../../db/FirebaseStore";
import { MPPiece } from "./MPPiece";
import { Piece } from "./Piece";
import { PieceManager } from "./PieceManager";

export class MPPieceManager extends PieceManager {
  database: Firebase.DatabaseReference;

  constructor({
    puzzleKey,
    piecesData,
    layer,
    activeLayer,
    puzzleManager,
  }: any) {
    super();
    this.database = Firebase.child(
      FirebaseStore.getPuzzleRef(puzzleKey),
      "pieces"
    );
    this.layer = layer;

    this.pieces = piecesData?.reduce(
      (pcs: Record<string, Piece>, p: Record<string, any>) => {
        pcs[p.key] = new MPPiece({
          pieceData: p,
          layer,
          activeLayer,
          database: Firebase.child(this.database, p.key),
          puzzleManager,
        });
        return pcs;
      },
      {}
    );

    this.initialPieces = piecesData?.reduce((acc: any, piece: any) => {
      acc[piece.key] = {
        x: piece.x,
        y: piece.y,
        key: piece.key,
        zIndex: 1000,
        isDragging: false,
      };
      return acc;
    }, {} as Record<string, any>);

    if (this.pieces) {
      this.initializeListener();
    }
  }

  resetPositions() {
    Firebase.remove(this.database);
  }

  private initializeListener() {
    Firebase.onValue(this.database, (snapshot) => {
      const data = snapshot.val();

      if (!data) {
        Firebase.set(this.database, this.initialPieces);
        return;
      }

      Object.values(data).forEach((p: any) =>
        (this.pieces[p.key] as MPPiece)?.update(p)
      );

      // console.log(data);

      // console.log(this.initialPieces);

      // console.log(data);
    });
  }

  cleanUp() {
    try {
      Firebase.off(this.database);
      if (this.pieces) {
        Object.values(this.pieces).forEach((p) => p.remove());
      }
    } catch (e) {}
  }
}
