export class Vector2 {
  constructor(public x = 0, public y = x) {
    this.x = Number(x);
    this.y = Number(y);
  }

  set(x = 0, y = x) {
    this.x = x;
    this.y = y;
  }

  setVector(vector: Vector2) {
    this.x = vector.x;
    this.y = vector.y;
  }

  clone() {
    return new Vector2(this.x, this.y);
  }

  add(vector: Vector2) {
    return new Vector2(this.x + vector.x, this.y + vector.y);
  }

  subtract(vector: Vector2) {
    return new Vector2(this.x - vector.x, this.y - vector.y);
  }

  scale(scalar: number) {
    return new Vector2(this.x * scalar, this.y * scalar);
  }

  dot(vector: Vector2) {
    return this.x * vector.x + this.y + vector.y;
  }

  moveTowards(vector: Vector2, t: number) {
    t = Math.min(t, 1);
    const diff = vector.subtract(this);
    return this.add(diff.scale(t));
  }

  magnitude() {
    return Math.sqrt(this.magnitudeSqr());
  }

  magnitudeSqr() {
    return this.x * this.x + this.y * this.y;
  }

  distance(vector: Vector2) {
    return Math.sqrt(this.distanceSqr(vector));
  }

  distanceSqr(vector: Vector2) {
    const deltaX = this.x - vector.x;
    const deltaY = this.y - vector.y;
    return deltaX * deltaX + deltaY * deltaY;
  }

  normalize() {
    const mag = this.magnitude();
    const vector = this.clone();
    if (Math.abs(mag) < 1e-9) {
      vector.x = 0;
      vector.y = 0;
    } else {
      vector.x /= mag;
      vector.y /= mag;
    }
    return vector;
  }

  angle() {
    return Math.atan2(this.y, this.x);
  }

  rotate(alpha: number) {
    const cos = Math.cos(alpha);
    const sin = Math.sin(alpha);
    const vector = new Vector2();
    vector.x = this.x * cos - this.y * sin;
    vector.y = this.x * sin + this.y * cos;
    return vector;
  }

  toPrecision(precision: number) {
    const vector = this.clone();
    vector.x = Number(vector.x.toFixed(precision));
    vector.y = Number(vector.y.toFixed(precision));
    return vector;
  }

  equals(vector: Vector2) {
    return this.x === vector.x && this.y === vector.y;
  }

  nearlyEquals(vector: Vector2, mag: number) {
    return this.distance(vector) < mag;
  }

  toString() {
    const vector = this.toPrecision(1);
    return "[" + vector.x + "; " + vector.y + "]";
  }

  absolute() {
    return new Vector2(Math.abs(this.x), Math.abs(this.y));
  }

  invert() {
    return new Vector2(this.y, this.x);
  }

  static zero() {
    return new Vector2(0);
  }

  static one() {
    return new Vector2(1);
  }

  static positiveInfinity() {
    return new Vector2(Infinity);
  }

  static negativeInfinity() {
    return new Vector2(-Infinity);
  }

  static up() {
    return new Vector2(0, 1);
  }

  static down() {
    return new Vector2(0, -1);
  }

  static left() {
    return new Vector2(-1, 0);
  }

  static right() {
    return new Vector2(1, 0);
  }

  static random() {
    return new Vector2(Math.random(), Math.random());
  }
}
