import { useSearchParams } from "react-router-dom";
import { useActivityQuery } from "../app/zetaApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { courseUrl, loginUrl } from "../app/configuration";
import { useEffect } from "react";
import { useDatabase } from "../db/useDatabase";
import { set } from "@firebase/database";
import { get } from "firebase/database";

const redirect = (url: string) => {
  window.location.href = url;
};

export function useActivity() {
  const [searchParams] = useSearchParams();

  const activityId = searchParams.get("a");
  const activity = useActivityQuery(activityId ? activityId : skipToken);
  const statusDb = useDatabase("status");

  useEffect(() => {
    const onActivityUpdate = async () => {
      if (!statusDb || !activity.data) return;

      const statusSnapshot = await get(statusDb);
      const status = statusSnapshot.val();

      if (status !== activity.data.status) {
        set(statusDb, activity.data.status);
      }
    };

    onActivityUpdate().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  if (activity.error) {
    if (
      "status" in activity.error &&
      (activity.error.status === 401 || activity.error.status === 403)
    ) {
      redirect(loginUrl);
    } else {
      redirect(courseUrl);
    }
  }

  if (!activityId) {
    redirect(courseUrl);
  }

  return activity;
}
