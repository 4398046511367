import { MouseEvent, useMemo } from "react";
import {} from "../../app/appSlice";

type Props = {
  setHandbookWidth: (width: number) => void;
  setHandbookWidthPct: (widthPct: number) => void;
};

const MIN_HANDBOOK_WIDTH_PCT = 0.2;
const MAX_HANDBOOK_WIDTH_PCT = 0.45;

const ResizeSlider = ({ setHandbookWidth, setHandbookWidthPct }: Props) => {
  const resize = useMemo(
    () => () => {
      const minWidth = window.innerWidth * MIN_HANDBOOK_WIDTH_PCT;
      const maxWidth = window.innerWidth * MAX_HANDBOOK_WIDTH_PCT;

      function onMouseMove(mouseMoveEvent: MouseEvent<HTMLDivElement>) {
        if (
          mouseMoveEvent.pageX > minWidth &&
          mouseMoveEvent.pageX < maxWidth
        ) {
          setHandbookWidth(mouseMoveEvent.pageX);
          setHandbookWidthPct(mouseMoveEvent.pageX / window.innerWidth);
        }
      }

      const onSelectStart = document.onselectstart;
      const onMouseUp = () => {
        document.body.removeEventListener("mousemove", onMouseMove as any);
        document.body.onselectstart = onSelectStart;
        if (window.getSelection) {
          window.getSelection()?.removeAllRanges();
        }
      };

      if (window.getSelection) {
        window.getSelection()?.removeAllRanges();
      }

      document.body.onselectstart = () => false;
      document.body.addEventListener("mousemove", onMouseMove as any);
      document.body.addEventListener("mouseup", onMouseUp, { once: true });
      document.body.addEventListener("mouseleave", onMouseUp);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className="resize-slider d-flex flex-column flex-center bg-gray-200"
      onMouseDown={resize}
    >
      <div className="resize-slider-circle"></div>
      <div className="resize-slider-circle"></div>
      <div className="resize-slider-circle"></div>
      <div className="resize-slider-circle"></div>
      <div className="resize-slider-circle"></div>

      {/* <img src={slider} alt="" draggable="false" /> */}
    </div>
  );
};

export default ResizeSlider;
