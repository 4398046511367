import React, { useLayoutEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faCircleQuestion,
} from "@fortawesome/free-regular-svg-icons";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectIsFacilitator,
  setHandbookContentHeight,
} from "../../../app/appSlice";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";

type Props = {
  title: string;
  subtitle: string;
  page?: number;
  totalPages?: number;
  goBack?: () => void;
  progressCrumbs?: { color: string; name: string }[];
  color?: string;
  setPrevPage: any;
  setNextPage: any;
  isLastPage: any;
  isFirstPage: any;
  backToMenu: any;
  resetProgress: any;
};

const Header = ({
  title,
  subtitle,
  progressCrumbs,
  color,
  setPrevPage,
  setNextPage,
  isLastPage,
  isFirstPage,
  backToMenu,
  resetProgress,
}: Props) => {
  const dispatch = useAppDispatch();
  const headerRef = useRef<any>();
  const isFacilitator = useAppSelector(selectIsFacilitator);

  useLayoutEffect(() => {
    const handleResize = () => {
      const boundingRect = headerRef.current?.getBoundingClientRect();
      boundingRect &&
        dispatch(
          setHandbookContentHeight(
            Math.floor(
              window.innerHeight - (boundingRect.y + boundingRect.height)
            )
          )
        );
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef]);
  return (
    <div ref={headerRef} className="d-flex flex-column sidebar-header">
      <div className="d-flex flex-column-fluid flex-center mt-2 ms-2 mb-2">
        {/* Left Section - Titles */}
        <div className="flex-grow-1">
          <h4
            className="fw-bolder"
            style={{ color, filter: "brightness(0.65)" }}
          >
            {title}
          </h4>
          <div className="fw-bold">{subtitle}</div>
        </div>
        {/* Right Section - Controls */}
        {isFacilitator && (
          <Dropdown align="start">
            <Dropdown.Toggle
              type={undefined}
              className="d-flex flex-center controls-toggle-btn btn-hover-scale"
            >
              <FontAwesomeIcon className="fa-xl" icon={faChevronCircleDown} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="min-w-200px">
              <div className="menu-item px-3 ">
                <div className="menu-content fs-7 text-dark fw-bolder px-3 py-4 user-select-none">
                  Activity Controls
                </div>
              </div>
              <div className="menu-item px-3 ">
                <button
                  className="my-menu-link fs-7 user-select-none"
                  onClick={backToMenu}
                >
                  Back To Menu
                </button>
              </div>
              <div className="menu-item px-3 ">
                <button
                  disabled={isFirstPage}
                  className="my-menu-link fs-7 user-select-none"
                  onClick={setPrevPage}
                >
                  Previous Page
                </button>
              </div>
              <div className="menu-item px-3">
                <button
                  // disabled={isLastPage}
                  className="my-menu-link fs-7 user-select-none"
                  onClick={setNextPage}
                >
                  Next Page
                </button>
              </div>
              <div className="menu-item px-3">
                <button
                  className="my-menu-link fs-7 user-select-none"
                  onClick={resetProgress}
                >
                  Reset All Progress
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {progressCrumbs && (
        <div className="d-flex flex-row">
          {progressCrumbs.map((p, i) => (
            <OverlayTrigger
              key={i}
              placement={"bottom"}
              overlay={
                <Tooltip id={`tooltip-bottom`} className="tooltip-dark">
                  {p.name}
                </Tooltip>
              }
            >
              <div
                key={i}
                className="header-chapter-crumb"
                style={{ background: p.color }}
              ></div>
            </OverlayTrigger>
          ))}
        </div>
      )}
    </div>
  );
};

export default Header;
